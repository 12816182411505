import axios from "axios";

const controller = "/v1/anketler";

export class AnketService {

    async getAnket() {
        let url = process.env.REACT_APP_URL + controller + "/listAnket";
        let anket = axios.get(url)
            .then((d) => d.data);

        return anket;
    }

    async getAnketById(anketId) {
        let url = process.env.REACT_APP_URL + controller + "/anketById?anketId=" + anketId;
        let anket = axios.get(url)
            .then((d) => d.data);
        return anket;
    }
}
