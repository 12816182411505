import axios from "axios";

const controller = "/v1/tematik-notlar";

export class TematikNotService {


   async getTematikNotById(tematikId) {
        let url = process.env.REACT_APP_URL + controller + "/tematikNotById?tematikId=" + tematikId;
        let tematik = axios.get(url)
            .then((d) => d.data);

        return tematik;
    }

    async getDuyuruById(tematikId) {
        let url = process.env.REACT_APP_URL + controller + "/duyuruById?id=" + tematikId;
        let tematik = axios.get(url)
            .then((d) => d.data);

        return tematik;
    }

    async getDuyurular() {
        let url = process.env.REACT_APP_URL + controller + "/duyurular";
        let tematik = axios.get(url)
            .then((d) => d.data);

        return tematik;
    }

    async getTematikNot() {
        let url = process.env.REACT_APP_URL + controller + "/tematikNot";
        let tematik = axios.get(url)
            .then((d) => d.data);

        return tematik;
    }
}
