import axios from "axios";

const controller = "/v1/yardim";

export class YardimService {
  async getWebYardim() {
    let url = process.env.REACT_APP_URL + controller + "/getWebYardimList/";
    const res = await axios.get(url);
    return res.data;
  }
}
