import axios from 'axios';
import {setAuthorizationToken} from '../helpers/setAuthorizationToken';
import {setLoginUser} from '../helpers/setLoginUser';

const login = (username, password) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_URL + "/hesap/giris", {username, password})
            .then((user) => {
                if (user.status) {
                    localStorage.setItem("jwtToken", user.data.jwt);
                    setAuthorizationToken(user.data.jwt);
                    setLoginUser(user.data);
                }
                resolve(user.data);
            }).catch((error) => {
            console.log(error)
            reject("Beklenmeyen bir hata oluştu.");
        });
    });
}


const createEDevletLoginUrl = (param) => {
    return new Promise((resolve, reject) => {
        let url = param === null ? "/hesap/createEDevletRedirectUrl" : "/hesap/createEDevletRedirectUrl?sorguId="+param;
        axios.get(process.env.REACT_APP_URL + url, {})
            .then((url) => {
                resolve(url);
            }).catch((error) => {
            console.log(error)
            reject("Beklenmeyen bir hata oluştu.");
        });
    });
}

const createHakimEDevletLoginUrl = () => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_URL + "/hesap/createHakimEDevletRedirectUrl", {})
            .then((url) => {
                resolve(url);
            }).catch((error) => {
            console.log(error)
            reject("Beklenmeyen bir hata oluştu.");
        });
    });
}


const logout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.clear();
    setAuthorizationToken(false);
}

export default {login, createEDevletLoginUrl, createHakimEDevletLoginUrl, logout};
