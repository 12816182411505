import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Ripple } from 'primereact/ripple';
import { useState } from "react";
import { useKararArama } from "../../../contexts/KararAramaContext";
import { InputText } from "primereact/inputtext";

export const PageTemplate = (data, dataTableParams, currentPage, setCurrentPage) => {

    const { rows, setRows, loading } = useKararArama();
    const [pageInputTooltip, setPageInputTooltip] = useState('Seçili sayfa numarasına gitmek için \'Enter\' tuşuna basın.');
    const [gosterilenSayfa, setGosterilenSayfa] = useState(1);
    function prevPage() {
        let p = currentPage > 0 ? currentPage - 1 : 0;
        setCurrentPage(p);
    }
    function nextPage() {
        let p = currentPage < dataTableParams.pageLinkSize ? currentPage + 1 : dataTableParams.pageLinkSize;
        setCurrentPage(p);
    }

    const onPageInputChange = (event) => {
        setGosterilenSayfa(event.target.value);
    }


    const onPageInputKeyDown = (event, options) => {
        if (event.key === 'Enter') {
            const page = parseInt(gosterilenSayfa);
            if (page < 0 || page > (dataTableParams.pageLinkSize + 1) || gosterilenSayfa == "") {
                setGosterilenSayfa(1);
                setPageInputTooltip(`Seçtiğiniz değer 1 ile ${dataTableParams.pageLinkSize + 1} arasında olmalıdır.`);
            }
            else {
                const first = currentPage ? (options.rows * (page - 1) + 1) : 0;
                dataTableParams.first = first;
                setCurrentPage(gosterilenSayfa - 1);
                setPageInputTooltip('Seçili sayfa numarasına gitmek için \'Enter\' tuşuna basın.');
            }
        }
    }


    return {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown CurrentPageReport',
        'FirstPageLink': (options) => {
            options.iconClassName = "p-paginator-icon pi pi-angle-double-left";
            options.disabled = currentPage === 0 ? true : false;
            return (
                <Button onClick={() => setCurrentPage(0)} disabled={options.disabled}
                    className="p-paginator-last p-paginator-element p-link" icon={options.iconClassName}
                    style={{ color: 'var(--text-color)', userSelect: 'none', textAlign: 'center' }} />
            );
        },
        'LastPageLink': (options) => {
            options.iconClassName = "p-paginator-icon pi pi-angle-double-right";
            options.disabled = currentPage === dataTableParams.pageLinkSize ? true : false;
            return (
                <Button onClick={() => setCurrentPage(dataTableParams.pageLinkSize)} disabled={options.disabled}
                    className="p-paginator-last p-paginator-element p-link" icon={options.iconClassName}
                    style={{ color: 'var(--text-color)', userSelect: 'none', textAlign: 'center' }} />
            );
        },

        'PrevPageLink': (options) => {
            options.iconClassName = "pi pi-angle-left";
            options.disabled = currentPage === 0 ? true : false;
            return (
                <Button onClick={prevPage} disabled={options.disabled}
                    className="p-paginator-prev p-paginator-element p-link" icon={options.iconClassName}
                    style={{ color: 'var(--text-color)', userSelect: 'none', textAlign: 'center' }} />
            );

        },
        'NextPageLink': (options) => {
            options.iconClassName = "p-paginator-icon pi pi-angle-right";
            options.disabled = currentPage === dataTableParams.pageLinkSize ? true : false;
            return (
                <Button onClick={nextPage} disabled={options.disabled}
                    className="p-paginator-next p-paginator-element p-link" icon={options.iconClassName}
                    style={{ color: 'var(--text-color)', userSelect: 'none', textAlign: 'center' }} />
            );
        },
        'PageLinks': (options) => {
            options.page = dataTableParams.pageable.pageNumber;
            options.currentPage = currentPage;
            const pageCount = dataTableParams.pageLinkSize + 1;
            const pageLinks = [];
            for (let i = (pageCount > 5 && currentPage > 2) ? ((currentPage === dataTableParams.pageLinkSize - 1 || currentPage === dataTableParams.pageLinkSize)
                ? dataTableParams.pageLinkSize - 4 : currentPage - 2) : 0; i < ((pageCount > 5 && currentPage < dataTableParams.pageLinkSize - 2) ?
                    ((currentPage === 0 || currentPage === 1) ? 4 : (currentPage + 2)) : (dataTableParams.pageLinkSize)) + 1; i++) {
                const classNames = ['p-link'];
                classNames.push("p-paginator-page p-paginator-element p-link p-paginator-page-start p-paginator-page-end")
                if (i === currentPage) {
                    classNames.push('p-highlight');
                }
                pageLinks.push(
                    <button key={i} className={classNames.join(' ')} onClick={() => handlePageClick(i)}>
                        {i + 1}
                        <Ripple />
                    </button>
                );
            }

            const handlePageClick = (page) => {
                setCurrentPage(page);
            };

            return (
                <div>
                    {pageLinks}
                </div>
            );
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];
            options.totalRecords = dataTableParams.totalElement;

            return <Dropdown id="rowsPerPageDropdown" value={rows} options={dropdownOptions} onChange={(e) => setRows(e.value)} />;
        },
        'CurrentPageReport': (options) => {
            options.currentPage = currentPage;
            if (data !== undefined) {
                options.totalPages = data.length !== 0 && !loading ? dataTableParams.pageLinkSize : 0;
                options.first = data.length !== 0 && !loading ? dataTableParams.first : dataTableParams.offset;
            }
            options.last = (dataTableParams.last);
            options.totalRecords = dataTableParams.totalElement;

            return (
                    <span className="mx-3 tooltip" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Sayfaya Git <InputText size="2" className="ml-1" value={gosterilenSayfa} 
                            onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange} />
                        <span className="tooltipPaginator">{pageInputTooltip}</span>
                    </span>
            )
        }
    };
};