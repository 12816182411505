import { Button } from "primereact/button";
import { Card } from 'primereact/card';
import { MevzuatService } from "../../../services/MevzuatService";
import React, { useRef, useState } from "react";
import { useKararArama } from "../../../contexts/KararAramaContext";
import { useEffect } from "react";
import { useVeriDoldurma } from "../../../contexts/VeriDoldurmaContext";
import YargitayBilgileri from "./cardBilgileri/YargitayBilgileri";
import MahkemeBilgileri from "./cardBilgileri/MahkemeBilgileri";
import DavaSucBilgileri from "./cardBilgileri/DavaSucBilgileri";
import { useYargitayBilgileri } from "../../../contexts/YargitayBilgileriContext";
import { useMahkemeBilgileri } from "../../../contexts/MahkemeBilgileriContext";
import { useDavaSuc } from "../../../contexts/DavaSucContext";
import { useSorgu } from "../../../contexts/SorguContext";
import { useLoginToken } from "../../../contexts/LoginTokenContext";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { KararAramaService } from "../../../services/KararAramaService";


const KararAramaSorguForm = () => {

    const mevzuatService = new MevzuatService();
    const { showDropdown, setShowDropdown, showGeldigiMahkemeDropdown, setShowGeldigiMahkemeDropdown, mahkemeTuruRef, geldigiMahkemeRef, mahkemeTuruLabel } = useMahkemeBilgileri();
    const { favoriList, kararIndir, filtreTemizle, setIsLoading, expanded, setExpanded, disabledbtnSorgu, setCurrentPage, currentPage, son10Karar,
        dataTableParams, kararSayisiVisible, setSon10Karar, controlField, setAraBtnDisable, detayliAramaBtnRef, araBtnDisable, setSorgulama,
        listKararAramaGridByFiltre, setSonucGizle, setAraBtnDisableMi, araBtnDisableMi, datatable, input } = useKararArama();
    const { kararOrganlariLabel, showKararOrganlariDropdown, setShowKararOrganlariDropdown, kararOrganlariRef } = useYargitayBilgileri();
    const { showDavaTuruDropdown, setShowDavaTuruDropdown, sucTuruLabel, davaTuruLabel, sucTuruRef, davaTuruRef, mevzuatlar, setMevzuatlar, kanunMaddesi } = useDavaSuc();
    const { disabledbtnFavori, sorguMenu, sorguEkle, sorguFavoriKaydet, favorilereEkle, setSorguMenu, yeniSorgu } = useSorgu();
    const { geldigiMahkemeLabel } = useVeriDoldurma();
    const [showSucTuruDropdown, setShowSucTuruDropdown] = useState(false);
    const [displayBilgilendirmeMetni, setDisplayBilgilendirmeMetni] = useState(false);
    const { ilkGiris } = useLoginToken();
    const newDataTableParams = JSON.parse(JSON.stringify(dataTableParams));
    const toast = useRef(null);
    const kararService = new KararAramaService();
    const [totalKararSayisi, setTotalKararSayisi] = useState(0);

    useEffect(() => {
        if (ilkGiris && JSON.stringify(localStorage.bilgilendirmeMetni) !== '"undefined"' && JSON.stringify(localStorage.bilgilendirmeMetni) !== '"null"') {
            setDisplayBilgilendirmeMetni(true);
        }

        kararService.getKararSayisi().
            then((data) => {
                if (data) {
                    setTotalKararSayisi(data);
                } else {
                    setTotalKararSayisi(0);
                }
            })
    }, [ilkGiris])

    useEffect(() => {
        if (araBtnDisableMi) {
            setAraBtnDisable(false);
        }
        setAraBtnDisableMi(false);
    }, [araBtnDisableMi])

    const sorgula = (sonKarar10) => {
        if (datatable.current) {
            datatable.current.resetScroll();
        }
        if (input.current.value.length < 3 && input.current && input.current.value !== "" && !sonKarar10) {
            toast.current.show({ severity: 'error', summary: '', detail: 'Arama alanına en az 3 karakter girerek arama yapınız.', life: 5000 });
            setAraBtnDisableMi(true);
        }
        else {
            setSorgulama(true);
            if (currentPage !== 0 || (sonKarar10 != son10Karar)) {
                setCurrentPage(0);
                setSon10Karar(sonKarar10);
            } else {
                listKararAramaGridByFiltre();
            }
        }
    };
    
    useEffect(() => {
        if (kanunMaddesi.turu != "" && kanunMaddesi.turu != null && mevzuatlar.filter(x => kanunMaddesi.turu == x.mevzuatTurUuid).length == 0) {
            setIsLoading(true);
            mevzuatService.filterMevzuatByTur(kanunMaddesi.turu).
                then((data) => {
                    if (mevzuatlar.length == 0) {
                        setMevzuatlar(data)
                    } else {
                        setMevzuatlar(mevzuatlar.concat(data));
                    }
                    setIsLoading(false);
                })
        } else {
            setIsLoading(false);
        };
    }, [kanunMaddesi.turu]);

    useEffect(() => {
        let timer;
        if (controlField != undefined && controlField == "mahkemeTuru") {
            if (showDropdown) {
                timer = setTimeout(() => {
                    setShowDropdown(false);
                }, 1);
            }
            return () => {
                clearTimeout(timer);
            };
        }
        if (controlField != undefined && controlField == "geldigiMahkeme") {
            if (showGeldigiMahkemeDropdown) {
                timer = setTimeout(() => {
                    setShowGeldigiMahkemeDropdown(false);
                }, 1);
            }
            return () => {
                clearTimeout(timer);
            };
        }
        if (controlField != undefined && controlField == "daire") {
            if (showKararOrganlariDropdown) {
                timer = setTimeout(() => {
                    setShowKararOrganlariDropdown(false);
                }, 1);
            }
            return () => {
                clearTimeout(timer);
            };
        }
        if (controlField != undefined && controlField == "sucTuru") {
            if (showSucTuruDropdown) {
                timer = setTimeout(() => {
                    setShowSucTuruDropdown(false);
                }, 1);
            }
            return () => {
                clearTimeout(timer);
            };
        }
        if (controlField != undefined && controlField == "davaTuru") {
            if (showDavaTuruDropdown) {
                timer = setTimeout(() => {
                    setShowDavaTuruDropdown(false);
                }, 1);
            }
            return () => {
                clearTimeout(timer);
            };
        }
    }, [showDropdown, showGeldigiMahkemeDropdown, showKararOrganlariDropdown, showSucTuruDropdown, showDavaTuruDropdown]);

    useEffect(() => {
        if (controlField != undefined && controlField == "mahkemeTuru") {
            if (mahkemeTuruLabel.length > 0) {
                mahkemeTuruRef.current.props.overlayVisible = true;
            }
        }
        if (controlField != undefined && controlField == "geldigiMahkeme") {
            if (geldigiMahkemeLabel.length > 0) {
                geldigiMahkemeRef.current.props.overlayVisible = true;
            }
        }
        if (controlField != undefined && controlField == "daire") {
            if (kararOrganlariLabel.length > 0) {
                kararOrganlariRef.current.props.overlayVisible = true;
            }
        }
        if (controlField != undefined && controlField == "sucTuru") {
            if (sucTuruLabel.length > 0) {
                sucTuruRef.current.props.overlayVisible = true;
            }
        }
        if (controlField != undefined && controlField == "davaTuru") {
            if (davaTuruLabel.length > 0) {
                davaTuruRef.current.props.overlayVisible = true;
            }
        }
    }, [showDropdown, showGeldigiMahkemeDropdown, showKararOrganlariDropdown, showSucTuruDropdown, showDavaTuruDropdown]);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const renderToggleIcon = () => {
        return expanded ? 'pi pi-angle-up' : 'pi pi-angle-down';
    };


    const footer = () => {
        return (
            <div>
                <Button label="Tamam" className="button-color" onClick={() => setDisplayBilgilendirmeMetni(false)} />

            </div>
        );
    }
    const onHide = () => {
        setDisplayBilgilendirmeMetni(false);
    }

    return (
        <Card style={{ background: "#ebe7e6" }} title={<div className='grid flex' id="icon" onClick={handleToggle}  >
            <Button
                icon={renderToggleIcon()}
                onClick={handleToggle}
                className="p-button-text"
            />
            <span className='ml-2 mt-1'>Detaylı Arama </span>
            <span className={!expanded ? 'visible ml-1 mt-1' : 'hidden'}>(Detaylı Arama Yapmak İçin Tıklayınız)</span>
        </div>} >
            <div className={expanded ? 'visible' : 'hidden'} id="aramaFilter">
                <div className='grid col-12'>
                    <Toast ref={toast} position="top-center" />
                    <YargitayBilgileri />
                    <MahkemeBilgileri />
                    <DavaSucBilgileri />
                </div>
            </div>
            <div className="grid mt-2">
                <div className="col-12 text-right" >
                    {!sorguMenu &&
                        <Button disabled={disabledbtnSorgu} style={{ float: 'left', marginLeft: "1rem" }} label="Sorgu Kaydet" icon="pi pi-save"
                            iconPos="left" className="p-button-success mt-1" onClick={() => sorguEkle()} />
                    }
                    {sorguMenu &&
                        <React.Fragment >
                            <Button label="Yeni Sorgu" icon="pi pi-save"
                                iconPos="left" className="p-button-success karar-button-position mt-1" onClick={() => yeniSorgu()} />
                            <Button disabled={disabledbtnSorgu} label="Güncelle" icon="pi pi-save"
                                iconPos="left" className="button-color karar-button-position mt-1" onClick={() => sorguFavoriKaydet(false)} />
                        </React.Fragment>

                    }
                    <Button label="İndir" icon="pi pi-download" iconPos="left"
                        className='p-button-info karar-button-position mt-1' onClick={kararIndir} disabled={disabledbtnFavori} />
                    <Button disabled={disabledbtnFavori || favoriList} label="Favorilere Ekle"
                        icon="pi pi-star" iconPos="right" className="p-button-warning karar-button-position mt-1"
                        onClick={favorilereEkle} />
                    <div className="tooltip" style={{ float: 'left' }}>
                        <Button className='p-button-warning karar-button-position mt-1 ml-3' label="Son 10 Karar" icon="pi pi-list" iconPos="right" onClick={() => {
                            sorgula(true);
                            setAraBtnDisable(true);
                        }} />
                        <span className="tooltiptextButtons">Sorgunuza Uygun Son 10 Karar</span>
                    </div>
                    {!kararSayisiVisible && <div className="label-container"><b>{totalKararSayisi}</b> karar bulunmaktadır.</div>}
                    {(kararSayisiVisible && newDataTableParams.totalElement > 0) ? <div className="label-container"><b>{newDataTableParams.totalElement}</b> karar bulundu.</div>
                        : (kararSayisiVisible && <div className="label-container">Sorgunuzla ilgili karar bulunamadı. Yeni karar yayımlandığında bilgilendirilmek için sorgunuzu kaydedebilirsiniz.</div>)
                    }
                    <div className={expanded ? 'visible' : 'hidden'}>
                        <Button className="temizle-button mt-1" label="Temizle" icon="pi pi-trash" iconPos="right" onClick={() => { filtreTemizle(); setSorguMenu(false) }} />
                        <Button className="ara-button ml-3 mr-3" disabled={araBtnDisable} ref={detayliAramaBtnRef} label="Ara" icon="pi pi-search" iconPos="right" id="ara"
                            onClick={() => {
                                sorgula(false);
                                setSonucGizle(false);
                                setAraBtnDisable(true);
                            }} />
                    </div>

                    <Dialog header="Bilgilendirme" visible={displayBilgilendirmeMetni} className="bilgilendirme-w" style={{ textAlign: "justify" }} footer={footer} onHide={onHide}>
                        <div className="col-12">
                            {localStorage.bilgilendirmeMetni}
                        </div>
                    </Dialog>
                </div>
            </div>
        </Card>
    )
};

export default KararAramaSorguForm;
