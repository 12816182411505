import React, { createContext, useContext, useMemo, useState } from "react";

const WorkListEntityContext = createContext();

export const WorkListEntityProvider = ({ children }) => {

    const [baslik, setBaslik] = useState('');
    const [rolId, setRolId] = useState('');
    const [kullaniciEkran, setKullaniciEkran] = useState(false);

    const values  = useMemo(() => ({
        baslik, setBaslik, rolId, setRolId, kullaniciEkran, setKullaniciEkran
    }), [
        baslik, setBaslik, rolId, setRolId, kullaniciEkran, setKullaniciEkran
    ]);

    return (
        <WorkListEntityContext.Provider value={values}>
            {children}
        </WorkListEntityContext.Provider>
    )

};

export const useWorkListEntity = () => {
    const context = useContext(WorkListEntityContext);

    if (context === undefined) {
        throw new Error("must be call inside WorkListEntityProvider");
    }

    return context;
};