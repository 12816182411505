export const setLoginUser = user => {
    if (user) {
        localStorage.setItem("girisTipi", user.girisTipi);
        localStorage.setItem("kullanici", user.kullanici);
        localStorage.setItem("kullaniciId", user.kullaniciId);
        localStorage.setItem("onayliMi", user.onayliMi);
        localStorage.setItem("hakimMi", user.hakimMi);
        localStorage.setItem("bilgilendirmeMetni",user.bilgilendirmeMetni);
        localStorage.setItem("uyariMetni",user.uyariMetni);
        if(user.sorguId !==null){
            localStorage.setItem("sorguId",user.sorguId);
        }
        
    }
}