import {SHOW_ALERT, HIDE_ALERT, SHOW_TOAST, HIDE_TOAST} from '../components/shared/Alert';

export function guid() {
    const r = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return r() + r() + r() + '-' + r() + '_' + r() + '-' + r() + '_' + r() + r() + r();
}


const initialState = {
    id: undefined,
    showAlertDialog: false,
    showToast: false,
    level: '',
    title: '',
    text: ''
};

export function alertReducer(state = initialState, action) {
    let id = guid();
    switch (action.type) {
        case SHOW_ALERT:
            return {
                ...state,
                text: action.text,
                level: action.level,
                title: action.title,
                showAlertDialog: true
            };
        case HIDE_ALERT:
            return {
                ...state,
                showAlertDialog: false
            };
        case HIDE_TOAST:
            return {
                ...state,
                showToast: false
            };
        case SHOW_TOAST:
            return {
                ...state,
                id: id,
                showToast: true,
                text: action.text,
                level: action.level
            };
        default:
            return state;
    }
}