import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import KararArama from "../../pages/kararArama";
import Sorgu from "./sorgu";
import { useLoginToken } from "../../contexts/LoginTokenContext";
import { useWorkListEntity } from "../../contexts/WorkListContext";
import EDevletLogin from "../../pages/eDevletLogin";
import Duyuru from "./duyuru";
import Yardim from "../../pages/yardim";
import Profil from "../../pages/kullanici/profil";
import EDevletRedirect from "../../pages/eDevletRedirect";

const Content = () => {
    const [displayProfil, setDisplayProfil] = useState(false);
    const { setKullanici, kullanici, girisTipi, setGirisTipi, onayli, setOnayli, setHakimMi } = useLoginToken();
    const { rolId } = useWorkListEntity();

    useEffect(() => {
        setGirisTipi(localStorage.girisTipi);
        setKullanici(localStorage.kullanici);
        if(localStorage.hakimMi !==undefined && localStorage.hakimMi !=="undefined"){
            setHakimMi(JSON.parse(localStorage.hakimMi));
        }
        if(localStorage.onayliMi !==undefined && localStorage.onayliMi !=="undefined"){
            setOnayli(JSON.parse(localStorage.onayliMi));
            if(!JSON.parse(localStorage.onayliMi)){
                setDisplayProfil(true);
            }
        }
    }, [kullanici, onayli, rolId]);


    return (
        kullanici != null && (<div className="container mt-100 app-container" style={{background:"whitesmoke"}}>
            <div className="col-12">
                <div className="grid flex">
                  <div className="lg:col-2 md:col-6 sm:col-12 mt-2 sol-panel-w">
                            <div className="pl-1" style={{ marginTop: "-10px" }}>
                                {kullanici && (girisTipi != "2" || (girisTipi == "2" && onayli)) &&
                                    <div className="col-12">
                                        <Sorgu />
                                    </div>
                                }
                            </div>
                             <div className="pl-1 pt-2" style={{ marginTop: "-10px"}}>
                                {kullanici && (girisTipi != "2" || (girisTipi == "2" && onayli)) &&
                                    <div className="col-12">
                                            <Duyuru />
                                    </div>
                                }
                            </div>
                    </div> 
                    <div className="lg:col-10 md:col-12 sm:col-12 mt-1" style={{background:"whitesmoke"}}>
                        <Routes>
                            <React.Fragment>
                                <Route
                                    path={`${process.env.PUBLIC_URL}/redirectFromEdevlet`}
                                    element={<EDevletRedirect />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/kararArama`}
                                    element={<KararArama />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/yardim`}
                                    element={<Yardim />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/EDevletLogin`}
                                    element={<EDevletLogin />}
                                />
                            </React.Fragment>
                        </Routes>
                        <Profil profil={false} displayProfil={displayProfil} setDisplayProfil={setDisplayProfil} />
                    </div>
                </div>
            </div>
        </div>)
    );
};


export default Content;
