import { useSearchParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { HesapService } from "../services/HesapService";
import { BlockUI } from 'primereact/blockui';
import { Button } from "primereact/button";
import { setAuthorizationToken } from '../helpers/setAuthorizationToken';
import { setLoginUser } from "../helpers/setLoginUser";

const EDevletLogin = () => {
    const hesapService = new HesapService();
    const toast = useRef(null);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
    let [hasError, setHasError] = useState(false);
    const [message, setMessage] = useState('e-Devlet Kapısı işlemleriniz için lütfen bekleyiniz...');
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const error_description = searchParams.get("error_description");


    useEffect(() => {
        if (code && state) {
            hesapService.getAccessCodeForEDevlet(code, state).then((data) => {
                setShowLoadingSpinner(false);
                if (data.jwt) {
                    localStorage.setItem("jwtToken", data.jwt);
                    setAuthorizationToken(data.jwt);
                    setLoginUser(data);
                    goToLogin();
                }
            }).catch(error => {
                let message = error.message;
                if (error.response != null && error.response.data != null && error.response.data.message != null) {
                    message = error.response.data.message;
                }
                setMessage(message);
                setShowLoadingSpinner(false);
                setHasError(true);
            });
        } else {
            setShowLoadingSpinner(false);
            setHasError(true);
            setMessage('e-Devlet Kapısı işlemleriniz yapılırken bir hata oluştu...');
            if (error_description) {
                setMessage(error_description);
            }
        }
    }, []);

    const goToLogin = () => {
        window.location = '/';
    }

    return (
        <BlockUI blocked={showLoadingSpinner}>
            <div className="p-12">
                <Toast ref={toast} />
                <label
                    style={{ fontSize: "150%" }}>{message}</label>

                {hasError === true &&
                    <div className="p-6" sho>
                        <Button label="Giriş Sayfasına Git" onClick={() => goToLogin()} className="mt-2 w-full" />
                    </div>
                }
            </div>
        </BlockUI>
    );
};
export default EDevletLogin;
