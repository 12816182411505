import React, { createContext, useContext, useMemo, useRef, useState } from "react";
import { useDavaSuc } from "./DavaSucContext";
import { useVeriDoldurma } from "./VeriDoldurmaContext";

const YargitayBilgileriContext = createContext();

export const YargitayBilgileriProvider = ({ children }) => {
    const { setDisabledCeza, setDisabledHukuk } = useDavaSuc();
    const [kararNoBasYili, setKararNoBasYili] = useState('');
    const [kararNoBaslangic, setKararNoBaslangic] = useState('');
    const [kararNoBitYili, setKararNoBitYili] = useState('');
    const [kararNoBitis, setKararNoBitis] = useState('');
    const [esasNoBasYili, setEsasNoBasYili] = useState('');
    const [esasNoBaslangic, setEsasNoBaslangic] = useState('');
    const [esasNoBitYili, setEsasNoBitYili] = useState('');
    const [esasNoBitis, setEsasNoBitis] = useState('');
    const esasNoBasYiliRef = useRef();
    const esasNoBitYiliRef = useRef();
    const esasNoBaslangicRef = useRef();
    const esasNoBitisRef = useRef();
    const kararNoBasYiliRef = useRef();
    const kararNoBaslangicRef = useRef();
    const kararNoBitYiliRef = useRef();
    const kararNoBitisRef = useRef();
    const [kararOrganlariLabel, setKararOrganlariLabel] = useState([]);
    const [yargitayBitKararTarihi, setYargitayBitKararTarihi] = useState(null);
    const [yargitayBasKararTarihi, setYargitayBasKararTarihi] = useState(null);
    const [kararTuruLabel, setKararTuruLabel] = useState([]);
    const [yimKararTuru, setYimKararTurleri] = useState([]);
    const kararOrganlariRef = useRef(null);
    const [showKararOrganlariDropdown, setShowKararOrganlariDropdown] = useState(false);
    const [daire, setDaire] = useState([]);
    const [prevKararOrganlariSelection, setPrevKararOrganlariSelection] = useState([]);
    const { setRepoDaire, repoHamDaire, repoDaire, setRepoYimKararTuru, repoYimKararTuru, repoHamYimKararTuru } = useVeriDoldurma();
    const [prevKararTuruSelection, setPrevKararTuruSelection] = useState([]);
    const today = new Date();
    const [inputStyles, setInputStyles] = useState({
        esasNoBasYili: {},
        esasNoBitYili: {},
        esasNoBaslangic: {},
        esasNoBitis: {},
        kararNoBasYili: {},
        kararNoBaslangic: {},
        kararNoBitYili: {},
        kararNoBitis: {},
    });


    const autoTab = (event) => {
        const currentElement = event.target;
        const currentValue = currentElement.value;
        if (currentValue.length >= 4) {
            const nextFocussable = currentElement.nextSibling;
            if (nextFocussable) {
                nextFocussable.focus();
            }
        }
    };

    const getKararOrganlariLabel = (e) => {
        setPrevKararOrganlariSelection(e.value);
        setRepoDaire((prevOptions) => {
            const newOptions = [...prevOptions];
            e.value.forEach((value, index) => {
                const itemIndex = prevOptions.findIndex((item) => item.value === value);
                const [item] = newOptions.splice(itemIndex, 1);
                newOptions.splice(index, 0, item);
            });
            return newOptions;
        });
        let kararOrganlariLabel = [];
        kararOrganlariLabel = repoDaire.filter(x => e.value.includes(x.value));
        kararOrganlariLabel = kararOrganlariLabel.map(x => <> &ensp;* {x.label}<br /></>);
        setKararOrganlariLabel(kararOrganlariLabel);
    }

    const sortedDaire = (e) => {
        if (e.value.length < prevKararOrganlariSelection.length) {
            setRepoDaire(repoHamDaire);
            getKararOrganlariLabel(e);
        }
        else {
            getKararOrganlariLabel(e);
        }
    }

    const getKararTuruLabel = (e) => {
        setPrevKararTuruSelection(e.value);
        setRepoYimKararTuru((prevOptions) => {
            const newOptions = [...prevOptions];
            e.value.forEach((value, index) => {
                const itemIndex = prevOptions.findIndex((item) => item.value === value);
                const [item] = newOptions.splice(itemIndex, 1);
                newOptions.splice(index, 0, item);
            });
            return newOptions;
        });
        let kararTuruLabel = [];
        kararTuruLabel = repoYimKararTuru.filter(x => e.value.includes(x.value));
        kararTuruLabel = kararTuruLabel.map(x => <> &ensp;* {x.label} <br /></>);
        setKararTuruLabel(kararTuruLabel);
    }

    const sortedKararTurleri = (e) => {
        if (e.value.length < prevKararTuruSelection.length) {
            setRepoYimKararTuru(repoHamYimKararTuru);
            getKararTuruLabel(e);
        }
        else {
            getKararTuruLabel(e);
        }
    }

    const pasifAlanlariBelirle = (daireler) => {
        const secilen = repoDaire.filter(x => daireler.includes(x.value))
        if (secilen.filter(x => x.label.includes("Hukuk")).length > 0 && secilen.filter(x => x.label.includes("Ceza")).length > 0) {
            setDisabledHukuk(false);
            setDisabledCeza(false);
        } else if (secilen.filter(x => x.label.includes("Hukuk")).length > 0) {
            setDisabledHukuk(true);
        } else if (secilen.filter(x => x.label.includes("Ceza")).length > 0) {
            setDisabledCeza(true);
        } else {
            setDisabledHukuk(false);
            setDisabledCeza(false);
        }

    }

    const yargHandleInput = (e, field) => {
        const delay = 300;

        const refMap = {
            esasNoBasYili: esasNoBasYiliRef,
            esasNoBitYili: esasNoBitYiliRef,
            esasNoBaslangic: esasNoBaslangicRef,
            esasNoBitis: esasNoBitisRef,
            kararNoBasYili: kararNoBasYiliRef,
            kararNoBaslangic: kararNoBaslangicRef,
            kararNoBitYili: kararNoBitYiliRef,
            kararNoBitis: kararNoBitisRef
        };

        const targetRef = refMap[field];
        if (targetRef) {
            clearTimeout(targetRef.current.typingTimeout);
            targetRef.current.typingTimeout = setTimeout(() => {
                const updateStyle = { top: '-0.3em' };
                setInputStyles(prevStyles => ({
                    ...prevStyles,
                    [field]: targetRef.current.value ? updateStyle : {}
                }));

                switch (field) {
                    case "esasNoBasYili":
                        setEsasNoBasYili(e);
                        break;
                    case "esasNoBitYili":
                        setEsasNoBitYili(e);
                        break;
                    case "esasNoBaslangic":
                        setEsasNoBaslangic(e);
                        break;
                    case "esasNoBitis":
                        setEsasNoBitis(e);
                        break;
                    case "kararNoBasYili":
                        setKararNoBasYili(e);
                        break;
                    case "kararNoBaslangic":
                        setKararNoBaslangic(e);
                        break;
                    case "kararNoBitYili":
                        setKararNoBitYili(e);
                        break;
                    case "kararNoBitis":
                        setKararNoBitis(e);
                        break;
                    default:
                        break;
                }
            }, delay);
        }
    };

    const yargitayFiltreTemizle = () => {
        setRepoDaire(repoHamDaire);
        setDaire([]);
        setKararOrganlariLabel([]);
        setYimKararTurleri([]);
        setKararTuruLabel([]);
        setKararNoBasYili('');
        setKararNoBaslangic('');
        setKararNoBitYili('');
        setKararNoBitis('');
        setEsasNoBasYili('');
        setEsasNoBaslangic('');
        setEsasNoBitYili('');
        setEsasNoBitis('');
        setInputStyles({});
        if (yargitayBasKararTarihi)
            setYargitayBasKararTarihi(today);
        if (yargitayBitKararTarihi)
            setYargitayBitKararTarihi(today);
        if (esasNoBasYiliRef.current !== undefined)
            esasNoBasYiliRef.current.value = "";
        if (esasNoBitYiliRef.current !== undefined)
            esasNoBitYiliRef.current.value = "";
        if (esasNoBaslangicRef.current !== undefined)
            esasNoBaslangicRef.current.value = "";
        if (esasNoBitisRef.current !== undefined)
            esasNoBitisRef.current.value = "";
        if (kararNoBasYiliRef.current !== undefined)
            kararNoBasYiliRef.current.value = "";
        if (kararNoBaslangicRef.current !== undefined)
            kararNoBaslangicRef.current.value = "";
        if (kararNoBitYiliRef.current !== undefined)
            kararNoBitYiliRef.current.value = "";
        if (kararNoBitisRef.current !== undefined)
            kararNoBitisRef.current.value = "";
    }

    const values = useMemo(() => ({
        kararNoBasYili, setKararNoBasYili, kararNoBaslangic, setKararNoBaslangic, kararNoBitYili, setKararNoBitYili, kararNoBitis, setKararNoBitis,
        esasNoBasYili, setEsasNoBasYili, esasNoBaslangic, setEsasNoBaslangic, esasNoBitYili, setEsasNoBitYili, esasNoBitis, setEsasNoBitis, esasNoBasYiliRef,
        esasNoBitYiliRef, esasNoBaslangicRef, esasNoBitisRef, kararNoBasYiliRef, kararNoBaslangicRef, kararNoBitYiliRef, kararNoBitisRef, kararOrganlariLabel, setKararOrganlariLabel,
        yargitayBitKararTarihi, setYargitayBitKararTarihi, yargitayBasKararTarihi, setYargitayBasKararTarihi, kararTuruLabel, setKararTuruLabel, yimKararTuru, setYimKararTurleri,
        kararOrganlariRef, showKararOrganlariDropdown, setShowKararOrganlariDropdown, daire, setDaire, autoTab, sortedDaire, sortedKararTurleri, yargitayFiltreTemizle,
        pasifAlanlariBelirle, yargHandleInput, inputStyles
    }), [
        kararNoBasYili, setKararNoBasYili, kararNoBaslangic, setKararNoBaslangic, kararNoBitYili, setKararNoBitYili, kararNoBitis, setKararNoBitis,
        esasNoBasYili, setEsasNoBasYili, esasNoBaslangic, setEsasNoBaslangic, esasNoBitYili, setEsasNoBitYili, esasNoBitis, setEsasNoBitis, esasNoBasYiliRef,
        esasNoBitYiliRef, esasNoBaslangicRef, esasNoBitisRef, kararNoBasYiliRef, kararNoBaslangicRef, kararNoBitYiliRef, kararNoBitisRef, kararOrganlariLabel, setKararOrganlariLabel,
        yargitayBitKararTarihi, setYargitayBitKararTarihi, yargitayBasKararTarihi, setYargitayBasKararTarihi, kararTuruLabel, setKararTuruLabel, yimKararTuru, setYimKararTurleri,
        kararOrganlariRef, showKararOrganlariDropdown, setShowKararOrganlariDropdown, daire, setDaire, autoTab, sortedDaire, sortedKararTurleri, yargitayFiltreTemizle,
        pasifAlanlariBelirle, yargHandleInput, inputStyles
    ]);

    return (
        <YargitayBilgileriContext.Provider value={values}>
            {children}
        </YargitayBilgileriContext.Provider>
    )

};

export const useYargitayBilgileri = () => {
    const context = useContext(YargitayBilgileriContext);

    if (context === undefined) {
        throw new Error("must be call inside KararAramaContextProvider");
    }

    return context;
};