import React, {Component} from 'react';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import {connect} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BaseAuxiliary from "./BaseAuxiliary";
import {logLevel, HIDE_ALERT} from './Alert';

class BaseMessageManager extends Component {

    constructor(props) {
        super(props);
        this.notify = this.notify.bind(this);
        this.error = this.error.bind(this);
        this.success = this.success.bind(this);
        this.info = this.info.bind(this);
        this.warn = this.warn.bind(this);
        this.hide = this.hide.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const {text, level, showToast} = newProps;
        if (showToast === true) {
            if (level == logLevel.error) {
                this.error(text);
            } else if (level == logLevel.success) {
                this.success(text);
            } else if (level == logLevel.info) {
                this.info(text);
            } else if (level == logLevel.warn) {
                this.warn(text);
            }
        }
    }

    hide() {
        const {dispatch} = this.props;
        dispatch({
            type: HIDE_ALERT
        });
    }

    notify(text) {
        toast(text);
    }

    error(text) {
        return toast.error(text);
    }

    success(text) {
        return toast.success(text);
    }

    info(text) {
        return toast.info(text);
    }

    warn(text) {
        return toast.warn(text);
    }

    render() {
        const containerStyle = {
            zIndex: 1999
        };
        let alertDialog = null;
        if (this.props.showAlertDialog) {
            alertDialog = (
                <SweetAlert
                    show={this.props.showAlertDialog}
                    title={this.props.title}
                    text={this.props.text}
                    onConfirm={this.hide}
                />
            );
        }
        return (
            <BaseAuxiliary>
                <ToastContainer position="top-right" autoClose={5000} style={containerStyle}/>
                {alertDialog}
            </BaseAuxiliary>
        );
    }
}

const mapStateToProps = state => {
    const {showAlertDialog, showToast, level, title, text, id} = state.alertReducer;
    return {showAlertDialog, showToast, level, title, text, id};
};

export default connect(mapStateToProps)(BaseMessageManager);