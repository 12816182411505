import React, { useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useLoginToken } from '../../contexts/LoginTokenContext';
import { useNavigate } from 'react-router-dom';
import { useYonetimPaneli } from '../../contexts/YonetimPaneliContext';
import { UyeService } from '../../services/UyeService';
import { InputMask } from "primereact/inputmask";
import { useVeriDoldurma } from '../../contexts/VeriDoldurmaContext';


const Profil = (props) => {
    let navigate = useNavigate();
    const uyeService = new UyeService();
    const toast = useRef(null);
    const [kvkk, setKvkkOnay] = useState(false);
    const [displayKvkkMetni, setDisplayKvkkMetni] = useState(false);
    const [kvkkMetni, setKvkkMetni] = useState('');
    const checkboxRef = useRef(null);
    const [hata, setHata] = useState(false);
    const [epostaHata, setEpostaHata] = useState(false);
    const [meslekHata, setMeslekHata] = useState(false);
    const [sehirHata, setSehirHata] = useState(false);
    const [ulkeHata, setUlkeHata] = useState(false);
    const [cinsiyetHata, setCinsiyetHata] = useState(false);
    const [kayit, setKayit] = useState(false);
    const { uyeId, ad, soyad, tckn, eposta, setEposta, telefon, setTelefon, cinsiyet, setCinsiyet, ulke, setUlke, sehir, setSehir, meslek, setMeslek,
        getUyeById, kullaniciKaydet } = useYonetimPaneli();
    const { loadProfilRepos, repoUlke, repoSehir, repoMeslek, repoCinsiyet, getSehirByUlkeId } = useVeriDoldurma();
    const { onayli, setOnayli } = useLoginToken();

    const renderFooter = () => {
        return (
            <div>
                <Button className='button-color' icon="pi pi-save" label="Kaydet" onClick={kaydet} />
            </div>
        );
    }

    const kaydet = () => {
        const hataVar = onBlurCheck();
        if (!kvkk || hataVar) {
            toast.current.show({ severity: 'error', summary: '', detail: 'Form verileri eksik ya da hatalı!', life: 3000 });
        } else {
            kullaniciKaydet(function (resp) {
                if (resp) {
                    toast.current.show({ severity: 'success', summary: '', detail: 'Kaydetme işlemi başarılı.', life: 3000 });
                    renderFooter();
                    setKayit(true);
                    if (!props.profil) {
                        localStorage.setItem("onayliMi", true);
                        setOnayli(true);
                        navigate("../kararArama");
                    }
                }
            })
            props.setDisplayProfil(false);
        }
    }

    const onHideKvkkMetni = () => {
        if (onayli) {
            setDisplayKvkkMetni(false);
        } else {
            toast.current.show({ severity: 'error', summary: '', detail: 'KVKK metnini onaylamadan işleme devam edemezsiniz!', life: 3000 });
        }
    }

    const kvkkMetniFooter = () => {
        return (
            <div>
                <Button label="Onaylıyorum" icon="pi pi-check" onClick={clickOnay} />
            </div>
        );
    }

    const clickOnay = () => {
        setKvkkOnay(true);
        setDisplayKvkkMetni(false);
    }

    const onHide = () => {
        if (kayit || props.profil) {
            props.setDisplayProfil(false);
        } else {
            toast.current.show({ severity: 'error', summary: '', detail: 'Profil bilgilerini tamamlamadan işleme devam edemezsiniz!', life: 3000 });
        }
    }

    useEffect(() => {
        if (props.displayProfil) {
            loadProfilRepos();
            setHata(false);
            setEpostaHata(false);
            setUlkeHata(false);
            setMeslekHata(false);
            setCinsiyetHata(false);
            setSehirHata(false);
            getUyeById(uyeId);

            if (props.profil) {
                setKvkkOnay(true);
            }
        }
    }, [props.displayProfil])


    const onBlurCheck = () => {
        let hataVarMi = false;
        if (eposta === undefined || eposta === "" || eposta === null || (!eposta.includes("@") || !eposta.includes("."))) {
            setEpostaHata(true);
            hataVarMi = true;
        } 
        else {
            setEpostaHata(false);
        } 
        if (ulke === null || ulke.length == 0) {
            setUlkeHata(true);
            hataVarMi = true;
        } 
        else {
            setUlkeHata(false);
        } 
        if (meslek == null || meslek.length == 0) {
            setMeslekHata(true);
            hataVarMi = true;
        } 
        else {
            setMeslekHata(false);
        }
        if (cinsiyet === " " || cinsiyet.length == 0) {
            setCinsiyetHata(true);
            hataVarMi = true;
        } 
        else {
            setCinsiyetHata(false);
        }
        if (sehir === null || sehir.length == 0) {
            setSehirHata(true);
            hataVarMi = true;
        } 
        else {
            setSehirHata(false);
        }
        return hataVarMi;
    }


    return (
        <section>
            <Toast ref={toast} position="top-center" />
            <Dialog id="profil" header={'Profil Bilgileri'} visible={props.displayProfil} className="profil-dialog-w" footer={renderFooter} onHide={onHide}>
                <div className="grid">
                    <Card className="w-full mt-2" style={{ background: "#FBF8F7" }}>
                        <div className="card">
                            <div className="grid text-left">
                                <div className="grid col-12">
                                    <div className="col-6">
                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="tckn" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">T.C. Kimlik No</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <InputText id="tckn" value={tckn} disabled className="w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="ad" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Adı</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <InputText id="ad" disabled value={ad} className="w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="soyad" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Soyadı</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <InputText id="soyad" disabled value={soyad} className="w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="ulke" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Yaşadığı Ülke</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <Dropdown id="ulke" value={ulke} options={repoUlke} onChange={(e) => {
                                                        setUlke(e.value);
                                                    }} onBlur={onBlurCheck} placeholder="" className="w-full" />
                                                    {ulkeHata &&
                                                        <small><div className='p-error'> Lütfen ülke seçimi yapınız!</div> </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="sehir" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Yaşadığı Şehir</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <Dropdown id="sehir" value={sehir} options={repoSehir} onChange={(e) => {
                                                        setSehir(e.value);
                                                    }} onBlur={onBlurCheck} className="w-full" />
                                                    {sehirHata &&
                                                        <small><div className='p-error'> Lütfen şehir seçimi yapınız!</div> </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">

                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="eposta" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">e-Posta</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <InputText id="eposta" value={eposta} onChange={(e) => {
                                                        setEposta(e.target.value);
                                                    }} onBlur={onBlurCheck} className="w-full" keyfilter={/[a-z0-9_\.\-@]/i} />
                                                    {epostaHata &&
                                                        <small><div className='p-error'> Lütfen geçerli bir 'E-posta' adresi giriniz!</div> </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="meslek" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Meslek</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <Dropdown id="meslek" value={meslek} options={repoMeslek} onChange={(e) => {
                                                        setMeslek(e.value);
                                                        getSehirByUlkeId(e.value);
                                                    }} onBlur={onBlurCheck} placeholder="Seçiniz.." className="w-full" />
                                                    {meslekHata &&
                                                        <small><div className='p-error'> Lütfen meslek seçimi yapınız!</div> </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="cinsiyet" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Cinsiyet</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <Dropdown id="cinsiyet" value={cinsiyet} options={repoCinsiyet} onChange={(e) => {
                                                        setCinsiyet(e.value);
                                                    }} onBlur={onBlurCheck} placeholder="Seçiniz.." className="w-full" />
                                                    {cinsiyetHata &&
                                                        <small><div className='p-error'> Lütfen cinsiyet seçimi yapınız!</div> </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="field grid">
                                                <label htmlFor="telefon" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Telefon</label>
                                                <div className="col-12 md:col-10 text-left">
                                                    <InputMask id="telefon" mask="9 (999) 999-99-99" placeholder='x (5xx) xxx-xx-xx' value={telefon} onChange={(e) => {
                                                        setTelefon(e.target.value);
                                                    }} className="w-full" keyfilter={/^[0-9]*$/} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!props.profil && <div className="field grid">
                                <div className="col-12 md:col-8 text-left mt-2">
                                    <Checkbox inputId="kvkk" ref={checkboxRef} onChange={e => {
                                        if (e.checked) {
                                            setDisplayKvkkMetni(true);
                                            uyeService.getKvkkMetni().then((data) => {
                                                setKvkkMetni(data);
                                            })
                                        } else {
                                            setKvkkOnay(false);
                                        }
                                    }} checked={kvkk} onBlur={onBlurCheck}></Checkbox>
                                    <label htmlFor="kvkk" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">"KVKK Formu"nu okudum, onaylıyorum.</label>
                                </div>
                            </div>}
                        </div>
                    </Card>
                </div>
            </Dialog>
            <Dialog header={"KVKK Metni"} visible={displayKvkkMetni} footer={kvkkMetniFooter} style={{ width: '40vw' }} onHide={onHideKvkkMetni} >
                <div className="col-12">
                    <div dangerouslySetInnerHTML={{ __html: kvkkMetni }} />
                </div>
            </Dialog>
        </section>
    )

}
export default Profil