import axios from "axios";
import { saveAs } from "file-saver";

const controller = "/v1/bultenler";

export class BultenService {

  async getBultenler() {
    let url = process.env.REACT_APP_URL + controller + "/listBulten";
    let anket = axios.get(url)
      .then((d) => d.data);

    return anket;
  }

  async getBultenById(bultenId, bultenAdi, onizle) {
    let url = process.env.REACT_APP_URL + controller + "/bultenById?bultenId=" + bultenId;
    return axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: (s) => s <= 500,
      responseType: 'blob',
    }).then((res) => {
      if (res.data.size === 0) {
        const error = true;
        return error;
      } else {
        if (onizle) {
          return res.data;
        } else {
          const file = new Blob([res.data]);
          saveAs(file, bultenAdi + ".pdf");
        }
      }
    });
  }
}
