import React, { createContext, useContext, useMemo, useState } from "react";
import { UyeService } from "../services/UyeService";

const YonetimPaneliContext = createContext();

export const YonetimPaneliProvider = ({ children }) => {

    const uyeService = new UyeService();
    const [uyeId, setUyeId] = useState('');
    const [ad, setAd] = useState('');
    const [soyad, setSoyad] = useState('');
    const [tckn, setTckn] = useState('');
    const [eposta, setEposta] = useState('');
    const [telefon, setTelefon] = useState('');
    const [cinsiyet, setCinsiyet] = useState('');
    const [ulke, setUlke] = useState('');
    const [sehir, setSehir] = useState('');
    const [meslek, setMeslek] = useState('');


    function getUyeById(uyeId) {
        uyeService.getUyeById(uyeId).then((data) => {
            if (data) {
                setAd(data.ad);
                setSoyad(data.soyad);
                setTckn(data.tcKimlikNo);
                setTelefon(data.telefon.slice(-11));
                setEposta(data.eposta);
                setCinsiyet(data.cinsiyet);
                setUlke(data.ulke);
                setSehir(data.sehir);
                setMeslek(data.meslek);
            }
        });
    }
    
    function kullaniciKaydet(callback) {
        let params = {
            id: uyeId,
            telefon: telefon,
            meslek: meslek,
            eposta: eposta,
            cinsiyet: cinsiyet,
            sehir: sehir
        }
        uyeService.profilKaydet(params).then((data) => {
            if (data) {
                setUyeId(data.id);
                if (callback) {
                    callback(true);
                }
            }


        });
    }

    const values  = useMemo(() => ({
        ad, soyad, tckn, eposta, setEposta, telefon, setTelefon, cinsiyet, setCinsiyet, ulke, setUlke, sehir, setSehir, meslek, setMeslek, 
        getUyeById, uyeId, setUyeId, kullaniciKaydet
    }), [
        ad, soyad, tckn, eposta, setEposta, telefon, setTelefon, cinsiyet, setCinsiyet, ulke, setUlke, sehir, setSehir, meslek, setMeslek, 
        getUyeById, uyeId, setUyeId, kullaniciKaydet
    ]);


    return (
        <YonetimPaneliContext.Provider value={values}>
            {children}
        </YonetimPaneliContext.Provider>
    )
};

export const useYonetimPaneli = () => {
    const context = useContext(YonetimPaneliContext);

    if (context === undefined) {
        throw new Error("must be call inside YonetimPaneliContextProvider");
    }

    return context;
};