
import { useRef, useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';



const YimCalendar = ({ id, label, value, onChange, dateFormat, disabled, minDate, maxDate, viewDate }) => {
    const cal = useRef("dd.mm.yy");
    const [compLabel, setCompLabel] = useState("");
    const [compDateFormat, setCompDateFormat] = useState("");

    useEffect(() => {
        if (label === null || label === undefined) {
            setCompLabel("");
        } else {
            setCompLabel(label);
        }
        if (dateFormat === null || dateFormat === undefined) {
            setCompDateFormat("dd.mm.yy");
        } else {
            setCompDateFormat(dateFormat);
        }
    }, []);


    const onDateChanged = (e) => {
        if (e.originalEvent.srcElement !== undefined && Number.isInteger(parseInt(e.originalEvent.srcElement.value.substr(9)))) {
            e.value = new Date(e.originalEvent.srcElement.value.substr(3, 3) + e.originalEvent.srcElement.value.substr(0, 3) + e.originalEvent.srcElement.value.substr(6, 4));
            e.target.value = e.value;
            e.originalEvent.currentTarget.value = e.value;
            e.originalEvent.target.value = e.value;
        }
        if (e?.value) {
            e.value.setTime(e.value.getTime() + 3 * 60 * 60 * 1000);
            const newYear = e.value.getFullYear();
            const newDate = new Date(e.value);
            if (e.originalEvent.srcElement) {
                newDate.setFullYear(newYear);
            }
            onChange(newDate);
        }
    }

    const temizle = () => {
        onChange(null); 
    }

    return (
        <span className="p-float-label">
            <Calendar ref={cal}
                id={id} value={value}
                className="w-full"
                onChange={onDateChanged}
                yearRange="1900:2100"
                view="date"
                yearNavigator={true}
                monthNavigator={true}
                dateFormat={compDateFormat}
                onClearButtonClick={() => temizle()}
                onViewDateChange={(e) => {
                    onChange(e.value)
                }}
                disabled={disabled}
                mask={"99.99.9999"}
                showButtonBar
                minDate={minDate}
                maxDate={maxDate}
                showIcon
                viewDate={viewDate || new Date()}
                icon={"pi pi-calendar"} />
            <label htmlFor={id}>{compLabel}</label>
        </span>
    )
}

export default YimCalendar;