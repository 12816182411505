import axios from "axios";

const controller = "/hesap";

export class HesapService {
   async getAccessCodeForEDevlet(code, state) {
        const url = process.env.REACT_APP_URL + controller + "/getAccessTokenForEDevlet?code=" + code + "&state=" + state;
        return axios.get(url)
            .then((d) => d?.data);
    }

    async createEDevletLoginUrl() {
        const url = process.env.REACT_APP_URL + controller + "/createEDevletRedirectUrl";
        return axios.get(url)
            .then((d) => d?.data);
    }
}