import React, { createContext, useContext, useMemo, useRef, useState } from "react";
import { useVeriDoldurma } from "./VeriDoldurmaContext";
const DavaSucContext = createContext();

export const DavaSucProvider = ({ children }) => {
    const { repoMevzuatTuru, repoSucTuru, setRepoSucTuru, repoDavaTuru, setRepoDavaTuru, repoHamSucTuru, repoHamDavaTuru } = useVeriDoldurma();
    const [disabledHukuk, setDisabledHukuk] = useState(false);
    const [disabledCeza, setDisabledCeza] = useState(false);
    const [showDavaTuruDropdown, setShowDavaTuruDropdown] = useState(false);
    const sucTuruRef = useRef(null);
    const [sucTuru, setSucTuru] = useState([]);
    const davaTuruRef = useRef(null);
    const [prevDavaTuruSelection, setPrevDavaTuruSelection] = useState([]);
    const [showSucTuruDropdown, setShowSucTuruDropdown] = useState(false);
    const [prevSucTuruSelection, setPrevSucTuruSelection] = useState([]);
    const [sucTuruLabel, setSucTuruLabel] = useState([]);
    const [davaTuruLabel, setDavaTuruLabel] = useState([]);
    const [mevzuatTuruLabel, setMevzuatTuruLabel] = useState([]);
    const [sucTarihi, setSucTarihi] = useState(null);
    const [davaTuru, setDavaTuru] = useState([]);
    const [ilgiliKararArama, setIlgiliKararArama] = useState('');
    const [kanunMadde, setKanunMadde] = useState('');
    const [kanunFikra, setKanunFikra] = useState('');
    const [kanunBend, setKanunBend] = useState('');
    const [sucBasKararTarihi, setSucBasKararTarihi] = useState(null);
    const [sucBitKararTarihi, setSucBitKararTarihi] = useState(null);
    const kanunMaddeRef = useRef();
    const kanunFikraRef = useRef();
    const kanunBendRef = useRef();
    const ilgiliKararAramaRef = useRef();
    const today = new Date();
    const [mevzuatlar, setMevzuatlar] = useState([]);
    const initialKanunMaddesi = { turu: "", no: "", mevzuat_id: "", madde: "", fikra: "", bent: "", mevzuatarama: "", maddetur: "Madde" }
    const [kanunMaddesi, setKanunMaddesi] = useState(JSON.parse(JSON.stringify(initialKanunMaddesi)));
    const [turSecildiMi, setTurSecildiMi] = useState(false);
    const [selectedMevzuat, setSelectedMevzuat] = useState(null);
    const [inputStyles, setInputStyles] = useState({
        kanunMadde: {},
        kanunBend: {},
        kanunFikra: {},
        ilgiliKararArama: {},
    });

    
    const davaSucHandleInput = (e, field) => {
        const delay = 300;
    
        const refMap = {
            kanunMadde: kanunMaddeRef,
            kanunBend: kanunBendRef,
            kanunFikra: kanunFikraRef,
            ilgiliKararArama: ilgiliKararAramaRef,
        };
    
        const targetRef = refMap[field];
    
        if (targetRef) {
            clearTimeout(targetRef.current.typingTimeout);
            targetRef.current.typingTimeout = setTimeout(() => {
                const updateStyle = { top: '-0.3em' };
                setInputStyles(prevStyles => ({
                    ...prevStyles,
                    [field]: targetRef.current.value ? updateStyle : {}
                }));
    
                switch (field) {
                    case "kanunMadde":
                        setKanunMadde(e);
                        break;
                    case "kanunBend":
                        setKanunBend(e);
                        break;
                    case "kanunFikra":
                        setKanunFikra(e);
                        break;
                    case "ilgiliKararArama":
                        setIlgiliKararArama(e);
                        break;
                    default:
                        break;
                }
            }, delay);
        }
    };

    const sortedSucTuru = (e) => {
        if (e.value.length < prevSucTuruSelection.length) {
            setRepoSucTuru(repoHamSucTuru);
            getSucTuruLabel(e);
        }
        else {
            getSucTuruLabel(e);
        }
    }

    const getSucTuruLabel = (e) => {
        setPrevSucTuruSelection(e.value);
        setRepoSucTuru((prevOptions) => {
            const newOptions = [...prevOptions];
            e.value.forEach((value, index) => {
                const itemIndex = prevOptions.findIndex((item) => item.value === value);
                const [item] = newOptions.splice(itemIndex, 1);
                newOptions.splice(index, 0, item);
            });
            return newOptions;
        });
        let sucTuruLabel = [];
        sucTuruLabel = repoSucTuru.filter(x => e.value.includes(x.value));
        sucTuruLabel = sucTuruLabel.map(x => <> &ensp;* {x.label} <br /></>);
        setSucTuruLabel(sucTuruLabel);
    }

    const sortedDavaTuru = (e) => {
        if (e.value.length < prevDavaTuruSelection.length) {
            setRepoDavaTuru(repoHamDavaTuru)
            getDavaTuruLabel(e);
        }
        else {
            getDavaTuruLabel(e);
        }
    }

    const getDavaTuruLabel = (e) => {
        setPrevDavaTuruSelection(e.value);
        setRepoDavaTuru((prevOptions) => {
            const newOptions = [...prevOptions];
            e.value.forEach((value, index) => {
                const itemIndex = prevOptions.findIndex((item) => item.value === value);
                const [item] = newOptions.splice(itemIndex, 1);
                newOptions.splice(index, 0, item);
            });
            return newOptions;
        });
        let davaTuruLabel = [];
        davaTuruLabel = repoDavaTuru.filter(x => e.value.includes(x.value));
        davaTuruLabel = davaTuruLabel.map(x => <> &ensp;* {x.label} <br /></>);
        setDavaTuruLabel(davaTuruLabel);
    }

    const getMevzuatTuruLabel = (e) => {
        let mevzuatTuruLabel = [];
        mevzuatTuruLabel = repoMevzuatTuru.filter(x => e.value.includes(x.value));
        mevzuatTuruLabel = mevzuatTuruLabel.map(x => <> &ensp;* {x.label} <br /></>);
        setMevzuatTuruLabel(mevzuatTuruLabel);
    }

    const davaSucFiltreTemizle = () => {
        if (sucBasKararTarihi)
            setSucBasKararTarihi(today);
        if (sucBitKararTarihi)
            setSucBitKararTarihi(today);
        setMevzuatTuruLabel([]);
        setSelectedMevzuat([]);
        setSucTuruLabel([]);
        setDavaTuruLabel([]);
        setKanunBend('');
        setKanunMadde('');
        setKanunFikra('');
        setIlgiliKararArama('');
        setSucTuru([]);
        setDavaTuru([]);
        setKanunMaddesi([]);
        setTurSecildiMi(false);
        setDisabledCeza(false);
        setDisabledHukuk(false);
        setRepoSucTuru(repoHamSucTuru);
        setRepoDavaTuru(repoHamDavaTuru);
        setInputStyles({});
        if (kanunMaddeRef.current !== undefined)
            kanunMaddeRef.current.value = "";
        if (kanunFikraRef.current !== undefined)
            kanunFikraRef.current.value = "";
        if (kanunBendRef.current !== undefined)
            kanunBendRef.current.value = "";
        if (ilgiliKararAramaRef.current !== undefined)
            ilgiliKararAramaRef.current.value = "";
    }

    const values  = useMemo(() => ({
        kanunMadde, setKanunMadde, kanunFikra, setKanunFikra, kanunBend, setKanunBend, sucTuru, setSucTuru, sortedDavaTuru, sortedSucTuru, getMevzuatTuruLabel,
        sucBasKararTarihi, setSucBasKararTarihi, sucBitKararTarihi, setSucBitKararTarihi, ilgiliKararArama, setIlgiliKararArama, davaTuru, setDavaTuru,
        sucTuruLabel, setSucTuruLabel, davaTuruLabel, setDavaTuruLabel, mevzuatTuruLabel, setMevzuatTuruLabel, disabledHukuk, setDisabledHukuk,
        disabledCeza, setDisabledCeza, showDavaTuruDropdown, setShowDavaTuruDropdown, sucTuruRef, davaTuruRef, showSucTuruDropdown, setShowSucTuruDropdown,
        sucTarihi, setSucTarihi, kanunMaddeRef, kanunFikraRef, kanunBendRef, ilgiliKararAramaRef, davaSucFiltreTemizle, mevzuatlar, setMevzuatlar,
        kanunMaddesi, setKanunMaddesi, turSecildiMi, setTurSecildiMi, selectedMevzuat, setSelectedMevzuat, davaSucHandleInput,inputStyles
    }), [
        kanunMadde, setKanunMadde, kanunFikra, setKanunFikra, kanunBend, setKanunBend, sucTuru, setSucTuru, sortedDavaTuru, sortedSucTuru, getMevzuatTuruLabel,
        sucBasKararTarihi, setSucBasKararTarihi, sucBitKararTarihi, setSucBitKararTarihi, ilgiliKararArama, setIlgiliKararArama, davaTuru, setDavaTuru,
        sucTuruLabel, setSucTuruLabel, davaTuruLabel, setDavaTuruLabel, mevzuatTuruLabel, setMevzuatTuruLabel, disabledHukuk, setDisabledHukuk,
        disabledCeza, setDisabledCeza, showDavaTuruDropdown, setShowDavaTuruDropdown, sucTuruRef, davaTuruRef, showSucTuruDropdown, setShowSucTuruDropdown,
        sucTarihi, setSucTarihi, kanunMaddeRef, kanunFikraRef, kanunBendRef, ilgiliKararAramaRef, davaSucFiltreTemizle, mevzuatlar, setMevzuatlar,
        kanunMaddesi, setKanunMaddesi, turSecildiMi, setTurSecildiMi, selectedMevzuat, setSelectedMevzuat, davaSucHandleInput,inputStyles
    ]);

    return (
        <DavaSucContext.Provider value={values}>
            {children}
        </DavaSucContext.Provider>
    )

};

export const useDavaSuc = () => {
    const context = useContext(DavaSucContext);

    if (context === undefined) {
        throw new Error("must be call inside KararAramaContextProvider");
    }

    return context;
};