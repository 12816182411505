import React from "react";
import LoginForm from './../components/layout/loginForm';

const Home = () => {
  return (
    <div id="hero" className="hero route bg-image">
      <div className="overlay-itro"></div>
      <div className="hero-content display-table">
        <div className="table-cell">
          <div className="container">
            <div>
              <LoginForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
