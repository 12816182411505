import { Routes, Route } from "react-router-dom";
import '../../css/login-page.css';
import React, { useEffect } from "react";
import { useLoginToken } from "../../contexts/LoginTokenContext";
import Yardim from "../../pages/yardim";

const YardimRoute = () => {

    const { setKullanici, kullanici } = useLoginToken();
    useEffect(() => {
        setKullanici(localStorage.kullanici);
    }, [kullanici]);
    return kullanici == null && (
        <Routes>
            <React.Fragment>
                <Route
                    path={`${process.env.PUBLIC_URL}/yardimSayfasi`}
                    element={<Yardim />}
                />
            </React.Fragment>
        </Routes>
    );

};

export default YardimRoute;