import { Button } from "primereact/button";
import { useKararArama } from "../../../contexts/KararAramaContext";
import { KararAramaService } from "../../../services/KararAramaService";
import { VeriDoldurmaService } from "../../../services/VeriDoldurmaService";
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { Editor } from "primereact/editor";
import SorguPopup from "../../layout/sorguPopup";
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { addLocale } from "primereact/api";
import { Rating } from "primereact/rating";
import { PageTemplate } from "../../shared/components/PageTemplate";
import TextParser from "../../../modules/annotate/TextParser";
import { confirmDialog } from "primereact/confirmdialog";
import { hyphenate } from "hyphen/tr";
import KararDetay from "../dialog/KararDetay";
import { useSorgu } from "../../../contexts/SorguContext";


const KararAramaDataTable = () => {

    const veriDoldurmaService = new VeriDoldurmaService();
    const kararAramaService = new KararAramaService();
    const [kararDetayLoading, setKararDetayLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    addLocale('tr', {
        firstDayOfWeek: 0,
        dayNames: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
        dayNamesShort: ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa'],
        dayNamesMin: ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        monthNamesShort: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        today: 'Bugün',
        clear: 'Temizle'
    });

    const { logId, currentPage, setCurrentPage, dataTableParams, loading, setLoading, setKlasor, sorgulama, gridDetailDialogHeader, setGridDetailDialogHeader,
        displayGridDetailDialog, setDisplayGridDetailDialog, onClickHeader, setKararDetayOzet, setKararDetayIlgiliKarar, setKararDetayHukukiKavram,
        setKararDetayHukukiKonu, setKararDetayAnahtarKelime, setKararDetayIliskiliBelge, setKararDetayMevzuat, setKararDetayTematikNot, datatable, favoriList,
        setSortIslemi, setSortField, setSortType, setSort, setDisplayGridKararMetniDialog, onSort, son10Karar, setKararDetayYayinlanmaTarihi, selectedKarar,
        setSelectedKarar, secilenKararIndex, setSecilenKararIndex, kararIndirme, kararAramaDataTable, sorguVerileri, detay, setDetay, listKararAramaGridByFiltre,
        indirmeLoading, rows, sonucGizle } = useKararArama();
    const { favoriId, setSorguKararAdi, setDigerKlasor, setDisabledbtnFavori, displaySorguFavoriEkleDialog, setDisplaySorguFavoriEkleDialog,
        setRepoKlasor, sorguFavoriEkleDialogHeader, setSorguFavoriEkleDialogHeader, getFavoriById, } = useSorgu();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dialogRef = useRef(null);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        if (secilenKararIndex != null) {
            let param = {
                logId: logId,
                secilenKararIndex: secilenKararIndex,
                indexMi: true
            }
            updateLogSorgu(param);
        }
    }, [secilenKararIndex]);

    useEffect(() => {
        if (selectedKarar !== null && selectedKarar.length === 0) {
            setDisabledbtnFavori(true);
        }
    }, [selectedKarar]);

    useEffect(() => {
        if (kararIndirme) {
            let param = {
                logId: logId,
                kararIndirme: kararIndirme,
                indexMi: false
            }
            updateLogSorgu(param);
        }
    }, [kararIndirme]);

    function updateLogSorgu(param) {
        kararAramaService.updateLogSorgu(param);
    }

    useEffect(() => {
        if (sorgulama) {
            listKararAramaGridByFiltre();
        }
    }, [currentPage, son10Karar]);

    useEffect(() => {
        setSortIslemi(false);
        setSortField(null);
        setSortType(null);
        setSort(null);
        if (currentPage === 0 && sorgulama) {
            listKararAramaGridByFiltre();
        } else {
            setCurrentPage(0);
        }

    }, [rows]);


    const onHide = () => {
        setDisplayGridDetailDialog(false);
    }

    const onRowClick = (event) => {
        setSecilenKararIndex(event.index + 1);
        if (event.originalEvent.detail === 1 && !isChecked) {
            onRowClickSearch(event.data)
        }
    }

    useEffect(() => {
        const savedSelectedRows = JSON.parse(localStorage.getItem('selectedRows'));
        if (savedSelectedRows) {
            setSelectedRows(savedSelectedRows);
        }
    }, []);

    const actionBodyTemplate = (rowData, e) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-file-o" className="p-button-info" onClick={() => onRowClickSearch(rowData, e)} />
            </React.Fragment>
        );
    }

    const changeHighlightColor = (content) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        const spans = tempDiv.getElementsByTagName('span');
        for (const span of spans) {
            if (span.style.backgroundColor === 'yellow') {
                span.style.backgroundColor = 'darkgoldenrod';
            }
        }

        return tempDiv.innerHTML;
    };

    const ozetButton = (rowData) => {
        const ozetHtml = changeHighlightColor(formatDuzenle(rowData.ozet));

        return (
            <React.Fragment>
                <span className="tooltipSorguForm" id={`name-${rowData.id}`} style={{ position: 'relative' }}>
                    <Button icon="pi pi-search" className="p-button-info" />
                    <div className={"tooltiptextOzet"}>
                        <p id="tooltipOzet" dangerouslySetInnerHTML={{ __html: ozetHtml }} />
                    </div>
                </span>
            </React.Fragment>
        );
    }


    const ratingBodyTemplate = (rowData) => {
        if (!(indirmeLoading && selectedKarar.map(x => x.id).includes(rowData.id))) {
            return (
                <React.Fragment>
                    <Rating
                        value={rowData.onemDuzeyId}
                        readOnly
                        cancel={false}
                        stars={rowData.onemDuzeyId}
                    />
                </React.Fragment>
            );
        } else return (<div className='fa fa-spinner fa-spin'></div>)
    }



    const actionBodyFavori = (rowData) => {
        let favori = rowData.favoriId !== null;
        let icon = "pi pi-star";
        if (favori) {
            icon = "pi pi-star-fill";
        }
        let tooltip = favori ? "Kaldır" : "Ekle";
        return (
            <React.Fragment>
                <div className="tooltip">
                    <Button icon={icon} className="p-button-warning" onClick={() => onClickFavoridenKaldir(rowData)} />
                    <span className="tooltiptext">{tooltip}</span>
                </div>
            </React.Fragment>
        );
    }

    const actionBodyYapayZeka = (rowData) => {

        let image = "images/artificial-intelligence.svg";
        let tooltip = "YİM YZ - Daha iyi sonuçlar için öğrendikçe gelişeceğim...";

        if (!rowData.yapayZekaSonucu) {
            image = "images/full-text-search.svg";
            tooltip = "Metin arama sonucu";
        }

        return (
            <React.Fragment>
                <div className="tooltip">
                    <img src={image} className="h-2rem"></img>
                    <span className="tooltiptext">{tooltip}</span>
                </div>
            </React.Fragment>
        );
    }

    useEffect(() => {
        if (detay !== "") {
            setDisplayGridKararMetniDialog(true);
            setKararDetayLoading(false);
        }
    }, [detay])



    const onRowClickSearch = (rowData) => {
        setKararDetayLoading(true);
        const param = {
            emsalKararId: rowData.id,
            gorevFiltre: sorguVerileri
        }
        kararAramaService.getKararDetayByEmsalKararId(param).then((data) => {
            if (data) {
                const kelime = data.kararMetni;
                hyphenate(kelime).then(result => {
                    const html = TextParser.toHtml(result);
                    setDetay(html)
                });
                setGridDetailDialogHeader(data.ecli);
            }

            setKararDetayMevzuat(mevzuatSirala(data.maddeler));
            setKararDetayHukukiKavram(data.hukukiKavramlar);
            setKararDetayHukukiKonu(data.hukukiKonular);
            setKararDetayAnahtarKelime(data.anahtarKelimeler);
            setKararDetayOzet(formatDuzenle(rowData.ozet));
            setKararDetayYayinlanmaTarihi(data.yayinTarihi);
            setKararDetayIliskiliBelge(data.iliskiliBelgeler);
            setKararDetayIlgiliKarar(data.ilgiliKarar);
            let tematikNot = (data.tematikAmaci === null ? "" : "Amacı: " + data.tematikAmaci) + "</br></br> "
                + (data.tematikNot === null ? "" : data.tematikNot);
            setKararDetayTematikNot(tematikNot);

            const selectedId = rowData.id;
            const storedSelectedRows = JSON.parse(localStorage.getItem('selectedRows')) || [];

            storedSelectedRows.push(selectedId);

            if (storedSelectedRows.length > 10) {
                storedSelectedRows.shift();
            }
            setSelectedRows(storedSelectedRows);

            localStorage.setItem('selectedRows', JSON.stringify(storedSelectedRows));
        });
    }

    const compareItems = (a, b) => {
        const upperA = (str) => str === null ? "" : str.toUpperCase();
        
        if (upperA(a.turu) !== upperA(b.turu)) {
            return upperA(a.turu).localeCompare(upperA(b.turu));
        }
        if (a.numara !== b.numara) {
            return a.numara - b.numara;
        }
    
        if (a.madde !== b.madde) {
            return a.madde - b.madde;
        }
    
        if (a.fikra !== b.fikra) {
            return a.fikra - b.fikra;
        }
    
        return upperA(a.bent).localeCompare(upperA(b.bent));
    };
    
    const mevzuatSirala = (params) => {
        let kanuns = params.filter(x => x.turu === "Kanunlar")
            .sort(compareItems);
    
        let digers = params.filter(x => x.turu !== "Kanunlar")
            .sort(compareItems);
    
        return [...kanuns, ...digers];
    };


    const formatDuzenle = (ozet) => {
        ozet = ozet.replace("<p class=\"ql-align-justify\">", "<div style=\"text-align : justify\">")
            .replace("<p class=\"ql-align-center\">", "<div style=\"text-align : center\">")
            .replace("<p class=\"ql-align-right\">", "<div style=\"text-align : right\">")
            .replaceAll("style=\"color: rgba(0, 0, 0, 0.87);\"", "");
        return ozet;
    }

    const favorilereEkle = () => {
        veriDoldurmaService.getKlasor(true).then((data) => {
            setRepoKlasor(data);
            setLoading(false);
            setSorguFavoriEkleDialogHeader("Favorilere Ekle");
            setDisplaySorguFavoriEkleDialog(true);
            setSorguKararAdi('');
            setKlasor([]);
            setDigerKlasor('');
        });
    }


    const [isChecked, setIsChecked] = useState(false);

    const onSelectionChangeKarar = (event) => {
        if (event.type === "checkbox" || event.type === "all") {
            setIsChecked(true);
            setSelectedKarar(event.value);
            setDisabledbtnFavori(false);
        }
        else if (event.type === "row") {
            setIsChecked(false);
        }
    }


    const onClickFavoridenKaldir = (rowData) => {
        let favoriSelectedKarar = [];
        favoriSelectedKarar.push(rowData);
        setSelectedKarar(favoriSelectedKarar);
        if (rowData.favoriId !== null) {
            confirmDialog({
                message: 'Seçtiğiniz karar favorilerden kaldırılacaktır. Onaylıyor musunuz?',
                header: 'Uyarı',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Onayla',
                rejectLabel: 'Vazgeç',
                acceptIcon: 'pi pi-check',
                rejectIcon: 'pi pi-times',
                accept: () => onRowClickFavori(rowData),
                reject: () => {}
            });
        }
        else {
            favorilereEkle();
        }
    }

    const onRowClickFavori = (rowData) => {
        if (rowData.favoriId !== null) {
            let params = {
                kararId: rowData.id,
                favoriId: rowData.favoriId
            }
            setLoading(true);
            kararAramaService.favorilerdenKaldir(params).then(() => {
                setLoading(false);
                rowData.favoriId = null;
                if (favoriList) {
                    getFavoriById(favoriId);
                }

            });
        }
    }


    const nameBodyTemplate = (rowData, column) => {
        const fieldData = rowData[column.field];

        return (
            <React.Fragment>
                <span className="tooltipSorguForm" id={`name-${rowData.id}`} style={{ position: 'relative' }}>
                    {fieldData}
                    <div className={"tooltiptextKararTable"}>Kararı görüntülemek için tıklayınız. </div>
                </span>
            </React.Fragment>
        );
    };

    const onPage = (event) => {
        setCurrentPage(event.page);
    }


    return (
        <section>
            <div className='col-12'>
                <DataTable ref={datatable} dataKey="id" value={kararAramaDataTable} selection={selectedKarar} selectionMode={'multiple'}
                    rowClassName={(rowData) => (selectedRows.includes(rowData.id) ? 'selected-row' : '')} onPage={onPage}
                    totalRecords={dataTableParams.totalElement} pageLinkSize={dataTableParams.pageLinkSize} scrollable={false}
                    onSelectionChange={onSelectionChangeKarar} alignheader={"center"} multiple={true} responsiveLayout="scroll" removableSort
                    loading={loading} paginatorfirst={dataTableParams.first} rows={dataTableParams.rows} selectAll={true} paginator
                    scrollHeight="735px" paginatorTemplate={PageTemplate(kararAramaDataTable, dataTableParams, currentPage, setCurrentPage)}
                    stripedRows showGridlines paginatorClassName="justify-content-end" onRowClick={onRowClick}
                    loadingIcon="fa fa-spinner fa-spin loading-spinner" className="w-full karar-arama-height row-color" emptyMessage="Karar verisi bulunamadı.">

                    <Column
                        field="sira"
                        header="Sıra"
                        body={loading ? "" : (rowData, { rowIndex }) => (currentPage * rows) + (rowIndex + 1)}
                        style={{ flex: "0 0 4rem" }}
                    ></Column>
                    <Column selectionMode="multiple" select></Column>
                    <Column field="id" hidden></Column>
                    <Column field="onemDuzeyId" sortable sortFunction={son10Karar ? null : onSort} align={"center"} alignheader={"center"}
                        header="Önem Düzeyi" body={ratingBodyTemplate} style={{ width: "50px" }}></Column>
                    {windowWidth > 1100 && <Button field="favoriMi" body={actionBodyFavori} align={"center"} alignheader={"center"}
                        header="Favori"></Button>}
                    {windowWidth > 1100 && <Button field="ozetS" body={ozetButton} align={"center"} alignheader={"center"}
                        header="Özet"></Button>}
                    <Button field="kararS" body={actionBodyTemplate} align={"center"} alignheader={"center"}
                        header="Yargıtay Kararı - ECLI" ></Button>
                    {windowWidth > 1100 && <Column field="ozet" hidden ></Column>}
                    <Column field="daireAdi" sortable align={"center"} sortFunction={onSort} alignheader={"center"}
                        header="Daire" body={nameBodyTemplate}></Column>
                    <Column field="esasNo" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Yargıtay Esas No" body={nameBodyTemplate}></Column>
                    {windowWidth > 1100 && <Column field="kararNo" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Yargıtay Karar No" body={nameBodyTemplate}></Column>}
                    <Column field="yarKararTarih" sortable align={"center"} sortFunction={onSort} alignheader={"center"}
                        header="Yargıtay Karar Tarihi" body={nameBodyTemplate}></Column>
                    {windowWidth > 1100 && <Column field="yimKararTurAdi" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Karar Türü" body={nameBodyTemplate}></Column>}
                    {windowWidth > 1100 && <Column field="davaTurAdi" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Dava Konusu" body={nameBodyTemplate}></Column>}
                    {windowWidth > 1100 && <Column field="mahTurAdi" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Mahkeme Türü" body={nameBodyTemplate}></Column>}
                    {windowWidth > 1100 && <Column field="mahkemeAdi" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Mahkemesi" body={nameBodyTemplate}></Column>}
                    {windowWidth > 1100 && <Column field="sucTurAdi" sortable align={"center"} sortFunction={son10Karar ? null : onSort} alignheader={"center"}
                        header="Suç Türü" body={nameBodyTemplate}></Column>}
                    {!sonucGizle && <Button field="sonuc" body={(rowData) => actionBodyYapayZeka(rowData)} align={"center"} alignheader={"center"}
                        header="Sonuç" ></Button>}

                </DataTable>
            </div>

            <SorguPopup sorguFavoriEkleDialogHeader={sorguFavoriEkleDialogHeader} displaySorguFavoriEkleDialog={displaySorguFavoriEkleDialog} />
            <Dialog header={gridDetailDialogHeader} onClick={onClickHeader}
                visible={displayGridDetailDialog} className="ozet-detay-dialog"
                onHide={onHide} ref={dialogRef} id="ozetDialog">
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <Editor value={detay} readOnly showHeader={false} style={{ height: '36vh', overflowY: "auto" }} />
                        </div>
                    </div>
                </div>
            </Dialog>
            {
                kararDetayLoading ? <div className='ozet-spinner fa fa-spinner fa-spin'></div> :
                    <KararDetay />
            }
        </section >
    )
}

export default KararAramaDataTable