import axios from "axios";

const controller = "/v1/mevzuatlar";

export class MevzuatService {
    async filterMevzuatByTur(tur) {
      let url =
        process.env.REACT_APP_URL +
        controller +
        "/searchMevzuatByTur?mevzuattur=" +
        tur;
      return await axios.get(url).then((res) => {
        return res.data;
      });
    }
  }