import { Card } from "primereact/card";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState, useRef } from "react";
import YimCalendar from "../../../shared/components/YimCalendar";
import { useKararArama } from "../../../../contexts/KararAramaContext";
import { useVeriDoldurma } from "../../../../contexts/VeriDoldurmaContext";
import { useMahkemeBilgileri } from "../../../../contexts/MahkemeBilgileriContext";
import { Toast } from 'primereact/toast';

const MahkemeBilgileri = () => {

    const [windowWidth] = useState(window.innerWidth);
    const { showDropdown, showGeldigiMahkemeDropdown, setIddianameBasKararTarihi, setIddianameBitKararTarihi, iddianameBasKararTarihi,
        iddianameBitKararTarihi, mahkemeTuruRef, geldigiMahkemeRef, mahkemeTuru, setMahkemeTuru, mahkemeTuruLabel, setMahBasKararTarihi,
        mahBasKararTarihi, setMahBitKararTarihi, mahBitKararTarihi } = useMahkemeBilgileri();

    const { setDisabledbtnSorgu, updateField } = useKararArama();
    const { repoMahkemeTuru, geldigiMahkemeGuncelle, geldigiMahkemeData, geldigiMahkemeLabel, geldigiMahkeme, setGeldigiMahkeme } = useVeriDoldurma();
    const [sonDegisiklik, setSonDegisiklik] = useState("");
    const toast = useRef(null);


    useEffect(() => {
        if (mahBasKararTarihi && mahBitKararTarihi && mahBasKararTarihi > mahBitKararTarihi) {
            if (sonDegisiklik == "mahBasKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Başlangıç tarihi bitiş tarihinden büyük olamaz!', life: 5000 });
                setMahBasKararTarihi(null);
            }
            if (sonDegisiklik == "mahBitKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Bitiş tarihi başlangıç tarihinden küçük olamaz!', life: 5000 });
                setMahBitKararTarihi(null);
            }
        }
        if (iddianameBasKararTarihi && iddianameBitKararTarihi && iddianameBasKararTarihi > iddianameBitKararTarihi) {
            if (sonDegisiklik == "iddianameBasKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Başlangıç tarihi bitiş tarihinden büyük olamaz!', life: 5000 });
                setIddianameBasKararTarihi(null);
            }
            if (sonDegisiklik == "iddianameBitKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Bitiş tarihi başlangıç tarihinden küçük olamaz!', life: 5000 });
                setIddianameBitKararTarihi(null);
            }
        }
    }, [mahBasKararTarihi, mahBitKararTarihi, iddianameBasKararTarihi, iddianameBitKararTarihi]);


    return (
        <Card className='lg:col-4 md:col-4 sm:col-12 mahkeme-card-color' tabIndex="2">
            <Toast ref={toast} position="top-center" />
            <div className="font-bold text-white bg-blue-800 p-3" style={{ marginTop: "-6px" }}>
                MAHKEME BİLGİLERİ
            </div>
            <div className="col-12">
                <span className="p-float-label tooltipSorguForm">
                    <MultiSelect id='mahTur' value={mahkemeTuru}
                        options={repoMahkemeTuru} onChange={(e) => {
                            updateField(e, 'mahkemeTuru');
                            setMahkemeTuru(e.value);
                            setDisabledbtnSorgu(false);
                            geldigiMahkemeGuncelle(e.value);
                        }} filterLocale="tr" optionLabel="label" filter resetFilterOnHide="true"
                        virtualScrollerOptions={{ itemSize: 43 }}
                        selectedItemsLabel="{0} öğe seçildi"
                        emptyFilterMessage="Kullanılabilir seçenek yok"
                        className="w-full max-width" maxSelectedLabels="2" ref={mahkemeTuruRef} overlayVisible={showDropdown} />
                    <div className={(mahkemeTuru != null && mahkemeTuruLabel.length > 0) ? "tooltiptextSorguForm" : "tooltiptextSorguFormHidden"}>{mahkemeTuruLabel} </div>
                    <label htmlFor="mahTur">Mahkeme Türü</label>
                </span>
            </div>
            <div className="col-12">
                <span className="p-float-label tooltipSorguForm">
                    <MultiSelect id='geldMah' value={geldigiMahkeme}
                        options={geldigiMahkemeData} onChange={(e) => {
                            updateField(e, 'geldigiMahkeme');
                            setGeldigiMahkeme(e.value);
                            setDisabledbtnSorgu(false);
                        }} filterLocale="tr"
                        maxSelectedLabels={2}
                        virtualScrollerOptions={{ itemSize: 43 }}
                        selectedItemsLabel="{0} öğe seçildi"
                        emptyFilterMessage="Kullanılabilir seçenek yok"
                        optionLabel="label" filter ref={geldigiMahkemeRef} overlayVisible={showGeldigiMahkemeDropdown} resetFilterOnHide="true"
                        className="w-full max-width" />
                    <div className={(geldigiMahkeme != null && geldigiMahkeme.length > 0 && geldigiMahkemeLabel.length > 0) ? "tooltiptextSorguForm" : "tooltiptextSorguFormHidden"}>{geldigiMahkemeLabel} </div>
                    <label htmlFor="geldMah">Geldiği Mahkeme {mahkemeTuru.length === 0 ? "(Mahkeme Türü Seçiniz!)" : ""}</label>
                </span>
            </div>
            <div className="col-12">
                <div className="grid ">
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="geldigiMahkemeTarihiBas" value={mahBasKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setMahBasKararTarihi(e);
                                    setSonDegisiklik("mahBasKararTarihi");
                                }}
                                label={windowWidth > 1500 ? "Karar Başlangıç Tarihi" : "Karar Baş.Tarihi"}
                                maxDate={mahBitKararTarihi}
                                viewDate={mahBasKararTarihi || mahBitKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="geldigiMahkemeTarihiBit" value={mahBitKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setMahBitKararTarihi(e);
                                    setSonDegisiklik("mahBitKararTarihi");
                                }}
                                label={windowWidth > 1500 ? "Karar Bitiş Tarihi" : "Karar Bit.Tarihi"}
                                minDate={mahBasKararTarihi}
                                viewDate={mahBitKararTarihi || mahBasKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-12" style={{ marginTop: "-8px" }}>
                <div className="grid ">
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="iddianameTarihi" value={iddianameBasKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setIddianameBasKararTarihi(e);
                                    setSonDegisiklik("iddianameBasKararTarihi");
                                }}
                                label={windowWidth > 1500 ? "İddianame Başlangıç Tarihi" : "İddianame Baş.Tarihi"}
                                maxDate={iddianameBitKararTarihi}
                                viewDate={iddianameBasKararTarihi || iddianameBitKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="iddianameTarihi" value={iddianameBitKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setIddianameBitKararTarihi(e);
                                    setSonDegisiklik("iddianameBitKararTarihi");
                                }}
                                label={windowWidth > 1500 ? "İddianame Bitiş Tarihi" : "İddianame Bit.Tarihi"}
                                minDate={iddianameBasKararTarihi}
                                viewDate={iddianameBitKararTarihi || iddianameBasKararTarihi || new Date()}
                            />

                        </span>
                    </div>
                </div>
                <div className="font-bold text-white bg-blue-800 p-2 " style={{ marginTop: "156px" }}>
                </div>
            </div>
        </Card>
    )
}


export default MahkemeBilgileri;