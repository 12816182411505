import React, { useEffect } from "react";
import { HesapService } from "../services/HesapService";
import { BlockUI } from 'primereact/blockui';
import EDevletLogin from "./eDevletLogin";

const EDevletRedirect = () => {
    const hesapService = new HesapService();
    let url = "";

    useEffect(() => {
        hesapService.createEDevletLoginUrl().then((d) => {
            window.location = d;
            url = d;
        })
    }, []);

    return (
        <BlockUI >
            {url !== "" &&
                <div>
                    <EDevletLogin />
                </div>
            }
        </BlockUI>
    );
};
export default EDevletRedirect;