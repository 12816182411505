import axios from "axios";

const controller = "/v1/veri-doldurma";

export class VeriDoldurmaService {
  async getHukukiKavram() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/hukukiKavram")
      .then((d) => d.data);
    return sonuc;
  }

  async getHukukiKonu() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/hukukiKonu")
      .then((d) => d.data);
    return sonuc;
  }
  async getUnvanlar() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/unvan")
      .then((d) => d.data);
    return sonuc;
  }
  async getAnahtarKelime() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/anahtarKelime")
      .then((d) => d.data);
    return sonuc;
  }
  async getYimKararTuru() {
    return await axios
      .get(process.env.REACT_APP_URL + controller + "/yimKararTuru")
      .then((d) => d.data);
  }
  
  async getMahkemeAdlari() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/mahkemeAdi")
      .then((d) => d.data);
    return sonuc;
  }

  async getMahkemeTurleri() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/mahkemeTuru")
      .then((d) => d.data);
    return sonuc;
  }

  async getSucTurleri() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/sucTuru")
      .then((d) => d.data);
    return sonuc;
  }

  async getDavaTurleri() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/davaTuru")
      .then((d) => d.data);
    return sonuc;
  }


  async getDaireAdlari() {
    let sonuc = await axios(
      process.env.REACT_APP_URL + controller + "/daireAdi"
    ).then((d) => d.data);
    return sonuc;
  }

  async getKlasor(favoriMi) {
    let sonuc = await axios(
      process.env.REACT_APP_URL +
        controller +
        "/klasorByTckn?favoriMi=" +
        favoriMi
    ).then((d) => d.data);
    return sonuc;
  }
  async getTreeNodes() {
    let sonuc = await axios(
      process.env.REACT_APP_URL + "/v1/sorgulamalar" + "/getTreeNodes"
    ).then((d) => d.data);
    return sonuc;
  }

  async getMeslekler() {
    let sonuc = await axios(
      process.env.REACT_APP_URL + controller + "/meslek"
    ).then((d) => d.data);
    return sonuc;
  }
  async getUlkeler() {
    let sonuc = await axios(
      process.env.REACT_APP_URL + controller + "/ulke"
    ).then((d) => d.data);
    return sonuc;
  }
  async getSehirler() {
    let sonuc = await axios(
      process.env.REACT_APP_URL + controller + "/sehir"
    ).then((d) => d.data);
    return sonuc;
  }

  async getMevzuatTurleri() {
    let sonuc = await axios
      .get(process.env.REACT_APP_URL + controller + "/mevzuatTuru")
      .then((d) => d.data);
    return sonuc;
  }
}
