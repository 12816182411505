import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {setAuthorizationToken} from "./helpers/setAuthorizationToken";
import {Provider} from "react-redux";
import store from './helpers/store';
import BaseMessageManager from "./components/shared/BaseMessageManager";
import { ErrorInterceptor, loadResponseInterceptors } from './services/interceptors/ResponseInterceptor';
import './translate';

const jwtToken = localStorage.getItem("jwtToken");


loadResponseInterceptors();

if (jwtToken) {
    setAuthorizationToken(jwtToken);
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
            <App/>
            <ErrorInterceptor/>
            <BaseMessageManager/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
