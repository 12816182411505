import React, { createContext, useContext, useMemo, useState } from "react";


const LoginTokenContext = createContext();

export const LoginTokenProvider = ({ children }) => {

  const [token, setToken] = useState('');
  let [kullanici, setKullanici] = useState('');
  let [girisTipi, setGirisTipi] = useState('');
  const [onayli, setOnayli] = useState(false);
  const [hakimMi, setHakimMi] = useState(false);
  const [ilkGiris, setIlkGiris] = useState(true);
  const [rolIds, setRolIds] = useState([]);
  const [refSorguId, setRefSorguId] = useState(null);

  const values = useMemo(() => ({
    token, onayli, setOnayli, setToken, kullanici, setKullanici, rolIds, setRolIds, girisTipi, setGirisTipi, hakimMi, setHakimMi, ilkGiris, setIlkGiris, refSorguId, setRefSorguId
  }), [
    token, onayli, setOnayli, setToken, kullanici, setKullanici, rolIds, setRolIds, girisTipi, setGirisTipi, hakimMi, setHakimMi, ilkGiris, setIlkGiris, refSorguId, setRefSorguId
]);

  return (
    <LoginTokenContext.Provider value={values}>
      {children}
    </LoginTokenContext.Provider>
  )
};

export const useLoginToken = () => {
  const context = useContext(LoginTokenContext);

  if (context === undefined) {
    throw new Error("must be call inside LoginTokenContextProvider");
  }

  return context;
};
export const UserConsumer = LoginTokenContext.Consumer;
export default LoginTokenContext;