import { Column } from "jspdf-autotable";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from "react";
import { useKararArama } from "../../../contexts/KararAramaContext";
import { useLoginToken } from "../../../contexts/LoginTokenContext";

const KararDetay = () => {
    const { hakimMi } = useLoginToken();
    const { onClickHeader, gridDetailDialogHeader, setDisplayGridKararMetniDialog, displayGridKararMetniDialog, detay,
        kararDetayOzet, kararDetayIlgiliKarar, kararDetayHukukiKavram, kararDetayHukukiKonu, kararDetayAnahtarKelime, kararDetayIliskiliBelge,
        kararDetayMevzuat, kararDetayTematikNot, kararDetayYayinlanmaTarihi } = useKararArama();
    const [isMobil, setIsMobil] = useState(window.innerWidth < 1100);
    const [isZoom, setIsZoom] = useState(false);
    const [fontSize, setFontSize] = useState(13);

    useEffect(() => {
        const handleResize = () => {
            setIsMobil(window.innerWidth < 1100);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const mevzuatLinkiBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon={"pi pi-book"} onClick={() => mevzuatDetay(rowData)} />
            </React.Fragment>
        );
    }

    const mevzuatDetay = (rowData) => {
        if (rowData.url !== "" && rowData.url !== null && rowData.url !== undefined) {
            let url = rowData.url;
            if (!url.includes("http")) {
                url = "http://" + url;
            }
            window.open(url, '_blank', 'noreferrer');
        }
    };

    const linkTemplate = (rowData) => {
        let url = rowData.link.includes("http") ? rowData.link : "http://" + rowData.link;
        return <a href={url} target="_blank">{url}</a>;
    };

    const DialogHeader = ({ icon, title }) => {
        return (
            <React.Fragment>
            <div className={isMobil ? 'mobile-view' : 'header-grid'}>
                <div className={isMobil ? '' : 'title'}>
                    <div className="tooltip">
                        <Button icon={icon} className="p-dialog-title-icon p-button-rounded button-color" onClick={onClickHeader} />
                        <span className="tooltiptextButtons"> Kopyala </span>
                    </div>
                    <span className='header-title ml-2'>{title}</span>

                    {isMobil && (
                        <div className="mobile-publish-date ml-6">
                            Yayım Tarihi: {kararDetayYayinlanmaTarihi}
                        </div>
                    )}
                </div>
            </div>
            </React.Fragment>
        );
    };

    const CloseIconWithDate = ({ publishDate }) => {
        return (
            <div className={isMobil ? " " : "date-close-icon"}>
                {!isMobil && <span className="publish-date">Yayım Tarihi: {publishDate}</span>}
                <i className="pi pi-times" />
            </div>
        );
    };


    const increaseTextSize = () => {
        setFontSize(fontSize + 1);
    };

    const decreaseTextSize = () => {
        setFontSize(fontSize - 1);
    }

    return (
        <div>
            <Tooltip target=".knob" />
            <Dialog header={<div className="tooltip"><DialogHeader icon="pi pi-copy" title={gridDetailDialogHeader} /></div>} resizable={false}
                visible={displayGridKararMetniDialog} closeIcon={<CloseIconWithDate publishDate={kararDetayYayinlanmaTarihi} />} id="kararDetay" className={"karar-detay-dialog"}
                onHide={() => setDisplayGridKararMetniDialog(false)}>
                <div className="card">
                    <div className="col-12 text-left" style={{ marginLeft: "-1rem" }}>
                        <div className="tooltip">
                            <Button icon={isZoom ? 'pi pi-window-minimize' : 'pi pi-window-maximize'}
                                className="info-button p-component ml-2" onClick={() => setIsZoom(!isZoom)} />
                            <span className="tooltiptextButtons">{isZoom ? "Metni Daralt" : "Metni Genişlet"}</span>
                        </div>
                        <div className="tooltip">
                            <Button onClick={increaseTextSize} icon="pi pi-search-plus" className="ml-2" id="plus" />
                            <span className="tooltiptextButtons">Metni Büyült</span>
                        </div>
                        <div className="tooltip">
                            <Button className="ml-2" onClick={decreaseTextSize} icon="pi pi-search-minus" id="minus" />
                            <span className="tooltiptextButtons">Metni Küçült</span>
                        </div>
                    </div>
                    <div className="grid flex">
                        <div className={isZoom ? "lg:col-12 md:col-12 sm:col-12 ml-3" : "lg:col-6 md:col-6 sm:col-12"}>
                            <div className={"lg:col-12 md:col-12 sm:col-12 p-inputtextarea p-inputtext p-component p-filled p-inputtextarea-resizable karar-editor-style"} id="detay">
                                <div style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }}>{detay}</div>
                            </div>
                            <div className="lg:col-12 md:col-12 sm:col-12 p-component p-filled p-inputtextarea-resizable bilgilendirme">
                                {localStorage.uyariMetni}
                            </div>
                        </div>
                        <div className={isZoom ? "lg:col-12 md:col-12 sm:col-12" : "lg:col-6 md:col-6 sm:col-12"} style={!isMobil ? { marginTop: "-1rem" } : {}}>
                            <div className={isZoom ? "lg:col-12 lg:ml-0 md:ml-12 sm:ml-0 md:col-12 sm:col-12" : "lg:col-12 lg:ml-4 md:ml-12 sm:ml-0 md:col-12 sm:col-12"}>
                                <div className='col-12'>
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>Özet</span>
                                    <Editor style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} value={kararDetayOzet}
                                        readOnly showHeader={false} className="editor-height" />
                                </div>
                                {hakimMi &&
                                    <React.Fragment>
                                        <div className='col-12'>
                                            <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>Tematik Not</span>
                                            <Editor style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} value={kararDetayTematikNot} readOnly showHeader={false} className="tematik-editor-height" />
                                        </div>
                                    </React.Fragment>
                                }
                                <div className='col-12' id="kararDetay">
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>Mevzuat</span>
                                    <DataTable value={kararDetayMevzuat} id="kararDetay"
                                        selectionMode="single"
                                        responsiveLayout="scroll" scrollable scrollHeight="100px"
                                        onRowClick={(rowData) => mevzuatDetay(rowData.data)}
                                        className="w-full"
                                        paginatorClassName="justify-content-end"
                                        style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }}
                                        emptyMessage="Gösterilecek veri bulunmamaktadır.">
                                        <Column field="turu" header="Türü" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        <Column field="numara" header="No" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        <Column field="madde" header="Madde" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        <Column field="fikra" header="Fıkra" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        <Column field="bent" header="Bend" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        <Column body={(rowData) => mevzuatLinkiBodyTemplate(rowData)} header="Mevzuat"></Column>
                                    </DataTable>
                                </div>
                                <div className='col-12'>
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>Anahtar Kelime</span>
                                    <div className={kararDetayAnahtarKelime.length == 0 ? "mt-3" : ""}>
                                        {kararDetayAnahtarKelime.length > 0 && kararDetayAnahtarKelime.map((data, index) => (

                                            <Button className="customButton" key={index} label={data} style={{ margin: "5px", backgroundColor: "#F29999", fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        ))}
                                        {kararDetayAnahtarKelime.length == 0 && <span className="ml-4" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }}>Gösterilecek veri bulunmamaktadır.</span>}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>Hukuki Kavram</span>
                                    <div className={kararDetayHukukiKavram.length == 0 ? "mt-3" : ""}>
                                        {kararDetayHukukiKavram.length > 0 && kararDetayHukukiKavram.map((data, index) => (
                                            <Button key={index} className="customButton" label={data} style={{ margin: "5px", backgroundColor: "#93BFA3", cursor: "auto !important", fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        ))}
                                        {kararDetayHukukiKavram.length == 0 && <span className="ml-4" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }}>Gösterilecek veri bulunmamaktadır.</span>}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>Hukuki Konu</span>
                                    <div className={kararDetayHukukiKonu.length == 0 ? "mt-3" : ""}>
                                        {kararDetayHukukiKonu.length > 0 && kararDetayHukukiKonu.map((data, index) => (

                                            <Button className="customButton" key={index} label={data} style={{ margin: "5px", backgroundColor: "#F2B680", fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        ))}
                                        {kararDetayHukukiKonu.length == 0 && <span className="ml-4" style={{ marginTop: "5rem !important", fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }}>Gösterilecek veri bulunmamaktadır.</span>}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>İlgili Karar</span>
                                    <Editor style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} value={kararDetayIlgiliKarar} readOnly showHeader={false} className="ilgili-karar-height" />
                                </div>
                                <div className='col-12' id="kararDetay">
                                    <span className={isZoom ? "ml-0" : "ml-2"} style={{ fontSize: 15, fontWeight: "bold", color: "#757575" }}>İlişkili Belge</span>
                                    <DataTable value={kararDetayIliskiliBelge} id="kararDetay"
                                        selectionMode="single"
                                        responsiveLayout="scroll" scrollable scrollHeight="100px"
                                        className="w-full"
                                        paginatorClassName="justify-content-end"
                                        emptyMessage="Gösterilecek veri bulunmamaktadır."
                                        style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }}
                                    >
                                        <Column field="adi" header="Adı" style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                        <Column field="link" header="Link" body={linkTemplate} style={{ fontSize: isZoom ? `${fontSize + 5}px` : `${fontSize}px` }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}


export default KararDetay;