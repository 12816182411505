import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { VeriDoldurmaService } from "../services/VeriDoldurmaService";
import { useWorkListEntity } from "./WorkListContext";
import { useLoginToken } from "./LoginTokenContext";

const VeriDoldurmaContext = createContext();

export const VeriDoldurmaProvider = ({ children }) => {
    const { rolId } = useWorkListEntity();
    const { kullanici, ilkGiris } = useLoginToken();
    const veriDoldurmaService = new VeriDoldurmaService();
    const [repoDaire, setRepoDaire] = useState([]);
    const [repoMahkemeTuru, setRepoMahkemeTuru] = useState([]);
    const [repoSucTuru, setRepoSucTuru] = useState([]);
    const [repoDavaTuru, setRepoDavaTuru] = useState([]);
    const [repoHukukiKavram, setRepoHukukiKavram] = useState([]);
    const [repoHukukiKonu, setRepoHukukiKonu] = useState([]);
    const [repoAnahtarKelime, setRepoAnahtarKelime] = useState([]);
    const [repoGeldigiMahkeme, setRepoGeldigiMahkeme] = useState([]);
    const [geldigiMahkemeData, setGeldigiMahkemeData] = useState([]);
    const [repoYimKararTuru, setRepoYimKararTuru] = useState([]);
    const [repoHamDaire, setHamRepoDaire] = useState([]);
    const [repoHamMahkemeTuru, setHamRepoMahkemeTuru] = useState([]);
    const [repoHamSucTuru, setHamRepoSucTuru] = useState([]);
    const [repoHamDavaTuru, setHamRepoDavaTuru] = useState([]);
    const [repoHamYimKararTuru, setHamRepoYimKararTuru] = useState([]);
    const [repoMevzuatTuru, setRepoMevzuatTuru] = useState([]);
    const [selectedMahkemeTurs, setSelectedMahkemeTurs] = useState([]);
    const [geldigiMahkemeLabel, setGeldigiMahkemeLabel] = useState([]);
    const [geldigiMahkeme, setGeldigiMahkeme] = useState([]);
    const [prevMahkemeTurs, setPrevMahkemeTurs] = useState([]);
    const [repoSehir, setRepoSehir] = useState([]);
    const [repoUlke, setRepoUlke] = useState([]);
    const [repoCinsiyet, setRepoCinsiyet] = useState();
    const [repoMeslek, setRepoMeslek] = useState([]);

    useEffect(() => {
        if (ilkGiris && (kullanici !== '' && kullanici !== "" && kullanici != null)) {
            loadRepos();
        }
    }, [rolId]);

    function geldigiMahkemeGuncelle(mahkemeTurs, callback) {
        setSelectedMahkemeTurs(mahkemeTurs);
        try {
            let veri = [];
            let a = [];
            if (mahkemeTurs.length > 0) {
                if (mahkemeTurs.name == "") {
                    for (let i = 0; mahkemeTurs.length > i; i++) {
                        a = (repoGeldigiMahkeme.filter(x => x.iliskiliId == selectedMahkemeTurs[i]));
                        veri = [...veri, ...a];
                        a = [];
                    }
                }
                else {
                    for (let i = 0; mahkemeTurs.length > i; i++) {
                        a = (repoGeldigiMahkeme.filter(x => x.iliskiliId == mahkemeTurs[i]));
                        veri = [...veri, ...a];
                        a = [];
                        if (veri.length == 0) {
                            setGeldigiMahkemeLabel([]);
                            setGeldigiMahkeme([]);
                            setGeldigiMahkemeData(veri.sort((a, b) => a.label > b.label ? 1 : -1));
                        }
                        else {
                            setGeldigiMahkemeData(veri.sort((a, b) => a.label > b.label ? 1 : -1));
                            if (mahkemeTurs.length < prevMahkemeTurs.length || mahkemeTurs.filter(x => !prevMahkemeTurs.includes(x))) {
                                const notInGeldigiMahkeme = veri.filter(x => geldigiMahkeme.includes(x.value));
                                const valueArray = notInGeldigiMahkeme.map(item => item.value);
                                const valueLabel = notInGeldigiMahkeme.map(x => <> &ensp;* {x.label}<br /></>);
                                setGeldigiMahkeme(valueArray);
                                setGeldigiMahkemeLabel([]);
                                setGeldigiMahkemeLabel(valueLabel);
                            }
                            setPrevMahkemeTurs(mahkemeTurs);
                        }
                    }
                }
                if (callback) {
                    callback(true);
                }
            } else {
                setGeldigiMahkemeData(repoGeldigiMahkeme);
                if (callback) {
                    callback(true);
                }
            }
        } catch (e) {
            console.log(e.message);
        }
    }


    function loadRepos() {
        if (kullanici !== '' && kullanici !== "" && kullanici != null) {
            if (repoMahkemeTuru.length === 0) getMahkemeTurleri();
            if (repoDaire.length === 0) getDaireAdlari();
            if (repoSucTuru.length === 0) getSucTurleri();
            if (repoDavaTuru.length === 0) getDavaTurleri();
            if (repoHukukiKavram.length === 0) getHukukiKavram();
            if (repoAnahtarKelime.length === 0) getAnahtarKelime();
            if (repoHukukiKonu.length === 0) getHukukiKonu();
            if (repoGeldigiMahkeme.length === 0) getMahkemeAdlari();
            if (repoYimKararTuru.length === 0) getYimKararTurleri();
            if (repoMevzuatTuru.length === 0) getMevzuatTurleri();
        }
    }


    function loadProfilRepos() {
        if (kullanici !== '' && kullanici !== "" && kullanici != null) {
            if (repoMeslek.length === 0) getMeslekler();
            if (repoSehir.length === 0) getSehirler();
            if (repoUlke.length === 0) getUlkeler();
            setRepoCinsiyet([{ value: "e", label: "Erkek" }, { value: "k", label: "Kadın" }]);
        }
    }

    function getSehirByUlkeId(ulkeId) {
        veriDoldurmaService.getSehirler(ulkeId).then((data) => setRepoSehir(data));
    }

    function getMeslekler() {
        veriDoldurmaService.getMeslekler().then((data) => setRepoMeslek(data));
    }
    function getSehirler() {
        veriDoldurmaService.getSehirler().then((data) => setRepoSehir(data));
    }
    function getUlkeler() {
        veriDoldurmaService.getUlkeler().then((data) => setRepoUlke(data));
    }

    function getDaireAdlari(callback) {
        veriDoldurmaService.getDaireAdlari().then((data) => {
            setRepoDaire(data);
            setHamRepoDaire(data);
            if (callback) {
                callback(true);
            }
        });
    }
    function getMahkemeTurleri(callback) {
        veriDoldurmaService.getMahkemeTurleri().then((data) => {
            setRepoMahkemeTuru(data);
            setHamRepoMahkemeTuru(data);
            if (callback) {
                callback(true);
            }
        });
    }

    function getSucTurleri(callback) {
        veriDoldurmaService.getSucTurleri().then((data) => {
            setHamRepoSucTuru(data);
            setRepoSucTuru(data);
            if (callback) {
                callback(true);
            }
        });
    }
    function getDavaTurleri(callback) {
        veriDoldurmaService.getDavaTurleri().then((data) => {
            setRepoDavaTuru(data);
            setHamRepoDavaTuru(data);
            if (callback) {
                callback(true);
            }
        });
    }
    function getHukukiKavram(callback) {
        veriDoldurmaService.getHukukiKavram().then((data) => {
            setRepoHukukiKavram(data);
            if (callback) {
                callback(true);
            }
        });
    }

    function getHukukiKonu(callback) {
        veriDoldurmaService.getHukukiKonu().then((data) => {
            setRepoHukukiKonu(data);
            if (callback) {
                callback(true);
            }
        });
    }
    function getAnahtarKelime(callback) {
        veriDoldurmaService.getAnahtarKelime().then((data) => {
            setRepoAnahtarKelime(data);
            if (callback) {
                callback(true);
            }
        });
    }
    const getMahkemeAdlari = () => {
        veriDoldurmaService.getMahkemeAdlari().then((data) => {
            setRepoGeldigiMahkeme(data);
            setGeldigiMahkemeData(data);
        });
    }
    const getYimKararTurleri = () => {
        veriDoldurmaService.getYimKararTuru().then((data) => {
            setHamRepoYimKararTuru(data);
            setRepoYimKararTuru(data);
        });
    }

    const getMevzuatTurleri = () => {
        veriDoldurmaService.getMevzuatTurleri().then((data) => {
            setRepoMevzuatTuru(data);
        });
    }

    const values = useMemo(() => ({
        repoHukukiKavram, repoHukukiKonu, setRepoHukukiKavram, setRepoHukukiKonu, loadRepos, getHukukiKavram,
        getHukukiKonu, repoAnahtarKelime, getAnahtarKelime, setRepoAnahtarKelime, geldigiMahkemeLabel, repoSucTuru,
        repoDavaTuru, repoMevzuatTuru, repoMahkemeTuru, geldigiMahkemeGuncelle, geldigiMahkemeData, geldigiMahkeme,
        setGeldigiMahkeme, repoDaire, repoYimKararTuru, setRepoSucTuru, setRepoDavaTuru, repoHamSucTuru, repoHamDavaTuru,
        repoGeldigiMahkeme, setGeldigiMahkemeData, setGeldigiMahkemeLabel, setRepoMahkemeTuru, repoHamMahkemeTuru,
        setRepoDaire, repoHamDaire, setRepoYimKararTuru, repoHamYimKararTuru, loadProfilRepos, repoUlke, repoSehir,
        repoMeslek, repoCinsiyet, getSehirByUlkeId
    }), [
        repoHukukiKavram, repoHukukiKonu, setRepoHukukiKavram, setRepoHukukiKonu, loadRepos, getHukukiKavram,
        getHukukiKonu, repoAnahtarKelime, getAnahtarKelime, setRepoAnahtarKelime, geldigiMahkemeLabel, repoSucTuru,
        repoDavaTuru, repoMevzuatTuru, repoMahkemeTuru, geldigiMahkemeGuncelle, geldigiMahkemeData, geldigiMahkeme,
        setGeldigiMahkeme, repoDaire, repoYimKararTuru, setRepoSucTuru, setRepoDavaTuru, repoHamSucTuru, repoHamDavaTuru,
        repoGeldigiMahkeme, setGeldigiMahkemeData, setGeldigiMahkemeLabel, setRepoMahkemeTuru, repoHamMahkemeTuru,
        setRepoDaire, repoHamDaire, setRepoYimKararTuru, repoHamYimKararTuru, loadProfilRepos, repoUlke, repoSehir,
        repoMeslek, repoCinsiyet, getSehirByUlkeId
    ]);

    return (
        <VeriDoldurmaContext.Provider value={values}>
            {children}
        </VeriDoldurmaContext.Provider>
    )
};

export const useVeriDoldurma = () => {
    const context = useContext(VeriDoldurmaContext);

    if (context === undefined) {
        throw new Error("must be call inside VeriDoldurmaContextProvider");
    }

    return context;
};