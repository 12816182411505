import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from "react-redux";
import { login, logout, redirectEDevlet, redirectHakimEDevlet } from "../../actions/authAction";
import { BlockUI } from 'primereact/blockui';
import KararArama from '../../pages/kararArama';
import Yardim from '../../pages/yardim';

class LoginForm extends Component {

    componentDidMount() {
        this.props.dispatch(logout());
    }

    state = {
        username: '',
        password: '',
        isFetching: false,
        forceOnayliMi: false,
        profil: false,
        displayYardim: false
    }

    handleSubmit = e => {
        const { dispatch } = this.props;
        const { username, password } = this.state;
        dispatch(login(username, password));
    }

    onChangedUserInfo = (force) => {
        if (!force) {
            window.location.reload(false);
        } else {
            this.setState({ 'forceOnayliMi': true });
        }
    };

    handleCreateEDevletRedirectUrlSubmit = e => {
        const { dispatch } = this.props;
        dispatch(redirectEDevlet());
    }

    handleCreateHakimEDevletRedirectUrlSubmit = e => {
        const { dispatch } = this.props;
        dispatch(redirectHakimEDevlet());
    }

    toggleDisplayYardim = () => {
        this.setState((prevState) => ({ displayYardim: !prevState.displayYardim }));
    };

    render() {
        const { forceOnayliMi } = this.state;
        const { isAuthenticated, isOnayliMi, isFetching, kullanici, url } = this.props;
        const showLoadingSpinner = isFetching === true;
        if (url) {
            window.location = url;
        }

        const validate = () => {
            let data = this.state;
            let errors = {};
            if (!data.username) {
                errors.username = 'Sicil numarası zorunlu!';
            }
            if (!data.password) {
                errors.password = 'Şifre zorunlu!';
            }
            return errors;
        };

        if (isAuthenticated && (forceOnayliMi || isOnayliMi)) {
            this.props.history.push('/');
            return (<div><KararArama girisTipi={localStorage.girisTipi} kullanici={kullanici} /></div>)
        } else if (isAuthenticated && !isOnayliMi) {
            this.onChangedUserInfo(false);
        } else {
            return (<BlockUI blocked={showLoadingSpinner}>
                <div className='login-form-wrapper'>
                    <div className='login-top'></div>
                    <div className='login-center'>
                        <div className='left'>
                            <div className='yargitay-logo-img'>
                                <img src='images/logo_buyuk1.svg'></img>
                                <div className='yargitay-yonetim-binasi-text'>
                                    <img src='images/Vector.svg'></img>
                                    Yargıtay Başkanlığı Yönetim Binası</div>
                                <div className='yargitay-yonetim-binasi-adres'>İncek Şehit Savcı Mehmet Selim Kiraz Bulvarı No:6 A Blok Ahlatlıbel Çankaya / ANKARA</div>
                            </div>
                        </div>
                        <div className='right' style={{ overflowY: "auto" }}>
                            <div className='logo'></div>
                            <div className='logo-text'>
                                <div>Yargıtay</div>
                                <div>İçtihat Merkezi</div>
                            </div>
                            <Form onSubmit={this.handleSubmit} validate={validate}
                                render={({ handleSubmit }) => (
                                    <React.Fragment>
                                        <form onSubmit={handleSubmit} className="p-fluid">
                                            <div className='col-12 mt-2 ml-4'>
                                                <button type="submit" className="giris-button giris-button-label" onClick={this.handleCreateHakimEDevletRedirectUrlSubmit}>Hakim/Savcı Girişi </button>
                                                <button type="submit" className="giris-button giris-button-label lg:ml-4 md:ml-4 sm:ml-0" onClick={this.handleCreateEDevletRedirectUrlSubmit}>e-Devlet Kapısı </button>
                                                <button type="submit" className="giris-button giris-button-label" hidden={process.env.REACT_APP_PROFIL === "prod"} onClick={this.handleSubmit}>Kolay Giriş </button>
                                            </div>
                                            <div className='yim-aciklama'>Yargıtay İçtihat Merkezi uygulaması Yargıtay'ın güncel ve emsal kararlarına erişimi kolaylaştırmak amacıyla geliştirilmiştir.
                                                Sisteme e-Devlet Kapısı üzerinden giriş yapılmaktadır. Hâkim ve Cumhuriyet savcıları tarafından yapılacak girişler ise “Hakim/Savcı Giriş” alanından gerçekleştirilmektedir.
                                                <React.Fragment>
                                                    <br />
                                                    <br />
                                                </React.Fragment>
                                                Yargıtay İçtihat Merkezi’nde yararlanılan mevzuat, Cumhurbaşkanlığı Mevzuat Bilgi Sistemi’nden alınmaktadır.
                                            </div>
                                            <span className="clickable-text" onClick={this.toggleDisplayYardim}>
                                                Yardım Sayfası'na erişmek için tıklayınız.
                                            </span>
                                            <div className="grid">
                                                <div className="sm:col-6 md:col-6 lg:col-4">
                                                    <a href="https://apps.apple.com/tr/app/yi-m-yarg%C4%B1tay-i-%C3%A7tihat-merkezi/id6469104074?l=tr" target="_blank" rel="noopener noreferrer">
                                                        <img src="images/ios.PNG" alt="Mevcut Uygulama App Store" style={{ width: '135px', height: '58px' }} />
                                                    </a>
                                                </div>
                                                <div className="sm:col-6 md:col-6 lg:col-8">
                                                    <a href="https://play.google.com/store/apps/details?id=com.yargitay.coc_mobile" target="_blank" rel="noopener noreferrer">
                                                        <img src="images/android.PNG" alt="Android Uygulaması Google Play" style={{ width: '135px', height: '50px', marginTop: "1.5px" }} />
                                                    </a>
                                                </div>
                                            </div>
                                        </form>
                                        <Yardim displayYardim={this.state.displayYardim} setDisplayYardim={this.toggleDisplayYardim} />

                                    </React.Fragment>
                                )} />

                        </div>
                    </div>
                    <div className="login-bottom">
                        <img src="images/logo.png" alt="Yargıtay Logo" />
                        <span style={{marginLeft:"5px"}}> Yargıtay'ın resmi web sitesine erişmek için <a href="https://www.yargitay.gov.tr/" target="_blank" className='clickable-text'>tıklayınız.</a></span>
                    </div>
                </div>
            </BlockUI>
            )
        }
    }
}

const mapStateToProps = state => {
    const {
        isAuthenticated,
        error,
        errorMessage,
        user,
        history,
        isAdmin,
        isOnayliMi,
        isFetching,
        kullanici,
        url
    } = state.auth;
    return {
        isAuthenticated,
        error,
        errorMessage,
        user,
        history,
        isAdmin,
        isOnayliMi,
        isFetching,
        kullanici,
        url
    }
}

export default connect(mapStateToProps)(LoginForm);
