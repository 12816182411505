import axios from "axios";

const controller = "/v1/aramaInfo";

export class AramaInfoService {
  async getAramaInfo() {
    let url = process.env.REACT_APP_URL + controller + "/getAramaInfo/";
    const res = await axios.get(url);
    return res.data;
  }
}