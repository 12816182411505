import { Routes, Route } from "react-router-dom";
import Home from "../../pages/home"
import '../../css/login-page.css';
import React, { useEffect } from "react";
import { useLoginToken } from "../../contexts/LoginTokenContext";



const HomeRoute = () => {

    const { setKullanici, kullanici } = useLoginToken();
    useEffect(() => {
        setKullanici(localStorage.kullanici);
    }, [kullanici]);
    return  kullanici == null &&(
            <Routes>
                <React.Fragment>
                    <Route
                        path={`${process.env.PUBLIC_URL}`}
                        element={<Home />}
                    />
                </React.Fragment>
            </Routes>
        );
    
};


export default HomeRoute;