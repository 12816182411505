import authService from '../services/authService';

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const EDEVLET_CREATE_REDIRECT_URL_SUCCESS = "EDEVLET_CREATE_REDIRECT_URL_SUCCESS";


function loginRequest(user) {
    return {
        type: LOGIN_REQUEST,
        user
    }
}

const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        user
    };
};

const loginError = error => {
    return {
        type: LOGIN_ERROR,
        error
    };
};

const eDevletCreateRedirectUrlSuccess = url => {
    return {
        type: EDEVLET_CREATE_REDIRECT_URL_SUCCESS,
        url: url
    };
};

export const login = (username, password) => {
    return dispatch => {
        dispatch(loginRequest({username}));
        authService.login(username, password)
            .then(data => {
                data.message
                    ? dispatch(loginError(data.message))
                    : (dispatch(loginSuccess(data)))

            })
            .catch(error => {
                dispatch(loginError(error))
                let message = error.message;
                if (error.response != null && error.response.data != null && error.response.data.message != null) {
                    message = error.response.data.message;
                }
                console.log(message)
            });
    }
}

export const redirectEDevlet = () => {
    return dispatch => {
        authService.createEDevletLoginUrl(localStorage.getItem("sorguId") === undefined ? null : localStorage.getItem("sorguId"))
            .then(response => {
                const data = response.data;
                dispatch(eDevletCreateRedirectUrlSuccess(data));
            })
            .catch(error => {
                dispatch(loginError(error))
                let message = error.message;
                if (error.response != null && error.response.data != null && error.response.data.message != null) {
                    message = error.response.data.message;
                }
                console.log(message)
            });
    }
}

export const redirectHakimEDevlet = () => {
    return dispatch => {
        authService.createHakimEDevletLoginUrl()
            .then(response => {
                const data = response.data;
                dispatch(eDevletCreateRedirectUrlSuccess(data));
            })
            .catch(error => {
                dispatch(loginError(error))
                let message = error.message;
                if (error.response != null && error.response.data != null && error.response.data.message != null) {
                    message = error.response.data.message;
                }
                console.log(message)
            });
    }
}


export const logout = () => {
    authService.logout();
    return {
        type: LOGOUT
    };
}