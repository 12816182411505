import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { KararAramaService } from "../services/KararAramaService";
import { useVeriDoldurma } from "./VeriDoldurmaContext";
import { toast } from "react-toastify";
import useClipboard from "react-hook-clipboard";
import { useYargitayBilgileri } from "./YargitayBilgileriContext";
import { useMahkemeBilgileri } from "./MahkemeBilgileriContext";
import { useDavaSuc } from "./DavaSucContext";

const KararAramaContext = createContext();

export const KararAramaProvider = ({ children }) => {
    const { kararNoBasYili, kararNoBaslangic, kararNoBitYili, kararNoBitis, esasNoBasYili, esasNoBaslangic, esasNoBitYili, esasNoBitis, daire, yargitayFiltreTemizle,
        yimKararTuru, yargitayBitKararTarihi, setYargitayBitKararTarihi, yargitayBasKararTarihi, setYargitayBasKararTarihi, sortedDaire, setShowKararOrganlariDropdown,
        sortedKararTurleri, getKararTuruLabel } = useYargitayBilgileri();
    const { setShowDropdown, setShowGeldigiMahkemeDropdown, sortedGeldigiMahkeme, mahkemeFiltreTemizle, sortedMahkemeTurleri, mahkemeTuru, iddianameBasKararTarihi,
        iddianameBitKararTarihi, setIddianameBasKararTarihi, setIddianameBitKararTarihi, setMahBasKararTarihi, mahBasKararTarihi, setMahBitKararTarihi, mahBitKararTarihi } = useMahkemeBilgileri();
    const { kanunMadde, kanunFikra, kanunBend, sucTuru, davaTuru, ilgiliKararArama, sucBasKararTarihi, setSucBasKararTarihi, sucBitKararTarihi, setSucBitKararTarihi,
        sortedSucTuru, setShowDavaTuruDropdown, setShowSucTuruDropdown, sortedDavaTuru, getMevzuatTuruLabel, davaSucFiltreTemizle, kanunMaddesi } = useDavaSuc();
    const datatable = useRef(null);
    const { repoGeldigiMahkeme, loadRepos, setGeldigiMahkemeData, geldigiMahkeme } = useVeriDoldurma();
    const [copyToClipboard] = useClipboard();
    const kararAramaService = new KararAramaService();
    const [sortIslemi, setSortIslemi] = useState(false);
    const [controlField, setControlField] = useState('');
    const [hukukiKavram, setHukukiKavram] = useState([]);
    const [son10Karar, setSon10Karar] = useState(false);
    const [selectedKarar, setSelectedKarar] = useState(null);

    const initialDataTableParams = {
        rows: 100,
        pageLinkSize: 0,
        first: 0,
        last: 0,
        offset: 0,
        totalElement: 0,
        numberOfElement: 0,
        pageable: true,
    };
    const [dataTableParams, setDataTableParams] = useState(
        JSON.parse(JSON.stringify(initialDataTableParams))
    );
    const [secilenKararIndex, setSecilenKararIndex] = useState(null);
    const [kararIndirme, setKararIndirme] = useState(false);
    const [indirmeLoading, setIndirmeLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rows, setRows] = useState(50);
    const [logId, setLogId] = useState(null);
    const [gridDetailDialogHeader, setGridDetailDialogHeader] = useState("");
    const [displayGridDetailDialog, setDisplayGridDetailDialog] = useState(false);
    const [displayGridKararMetniDialog, setDisplayGridKararMetniDialog] = useState(false);
    const [kararDetayOzet, setKararDetayOzet] = useState([]);
    const [kararDetayIlgiliKarar, setKararDetayIlgiliKarar] = useState([]);
    const [kararDetayHukukiKavram, setKararDetayHukukiKavram] = useState([]);
    const [kararDetayHukukiKonu, setKararDetayHukukiKonu] = useState([]);
    const [kararDetayAnahtarKelime, setKararDetayAnahtarKelime] = useState([]);
    const [kararDetayIliskiliBelge, setKararDetayIliskiliBelge] = useState([]);
    const [kararDetayMevzuat, setKararDetayMevzuat] = useState([]);
    const [kararDetayTematikNot, setKararDetayTematikNot] = useState("");
    const [kararAramaDataTable, setKararAramaDataTable] = useState([]);
    const [kanunNo, setKanunNo] = useState('');
    const [turu, setTuru] = useState([]);
    const [onemDuzeyi, setOnemDuzeyi] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [sortType, setSortType] = useState(null);
    const [sort, setSort] = useState(null);
    const [detay, setDetay] = useState("");
    const [anahtarKelimeLabel, setAnahtarKelimeLabel] = useState([]);
    const [kararSayisiVisible, setKararSayisiVisible] = useState(false);
    const input = useRef();
    const detayliAramaBtnRef = useRef();
    const [araBtnDisable, setAraBtnDisable] = useState(false);
    const [hukukiKonu, setHukukiKonu] = useState([]);
    const [hukukiKavramLabel, setHukukiKavramLabel] = useState([]);
    const [hukukiKonularLabel, setHukukiKonularLabel] = useState([]);
    const [emsal, setEmsal] = useState(false);
    const [sorguKriteriDataTable, setSorguKriteriDataTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anahtarKelime, setAnahtarKelimeler] = useState([]);
    const [favoriList, setFavoriList] = useState(false);
    const [disabledbtnSorgu, setDisabledbtnSorgu] = useState(true);
    const [serbestAra, setSerbestAra] = useState("");
    const [sorguId, setSorguId] = useState();
    const [klasor, setKlasor] = useState([]);
    const [sorgulama, setSorgulama] = useState(false);
    const [kararDetayYayinlanmaTarihi, setKararDetayYayinlanmaTarihi] = useState(null);
    const [sonucGizle, setSonucGizle] = useState(false);
    const [araBtnDisableMi, setAraBtnDisableMi] = useState(false);

    const [sorguVerileri, setSorguVerileri] = useState({
        turu: turu.toString(),
        mahkemeTuru: mahkemeTuru,
        kanunNo: kanunNo,
        kanunMadde: kanunMadde,
        kanunFikra: kanunFikra,
        kanunBend: kanunBend,
        geldigiMah: geldigiMahkeme,
        hukukiKavram: hukukiKavram,
        hukukiKonu: hukukiKonu,
        sucTuru: sucTuru,
        davaTuru: davaTuru,
        daire: daire,
        anahtarKelimeler: anahtarKelime.map(x => x.value),
        serbestAra: anahtarKelime.map(x => x.label),
        ilgiliKararArama: ilgiliKararArama,
        yimKararTurleri: yimKararTuru,
        kararTarihi: [yargitayBasKararTarihi, yargitayBitKararTarihi],
        iddianameTarihi: [iddianameBasKararTarihi, iddianameBitKararTarihi],
        geldigiMahTarihi: [mahBasKararTarihi, mahBitKararTarihi],
        sucTarihi: [sucBasKararTarihi, sucBitKararTarihi],
        esasNoBasYili: esasNoBasYili,
        esasNoBaslangic: esasNoBaslangic,
        esasNoBitYili: esasNoBitYili,
        esasNoBitis: esasNoBitis,
        kararNoBasYili: kararNoBasYili,
        kararNoBaslangic: kararNoBaslangic,
        kararNoBitYili: kararNoBitYili,
        onemDuzeyi: onemDuzeyi,
        kararNoBitis: kararNoBitis,
        emsalMi: emsal !== undefined ? emsal : true,
        sorguKriteri: sorguKriteriDataTable,
        offset: currentPage,
        limit: rows,
    });

    const updateField = (e, field) => {
        setControlField(field);
        if (field == "mahkemeTuru") {
            setTimeout(() => {
                sortedMahkemeTurleri(e);
                setShowDropdown(true);
            }, 1);
        }
        else if (field == "geldigiMahkeme") {
            setTimeout(() => {
                sortedGeldigiMahkeme(e);
                setShowGeldigiMahkemeDropdown(true);
            }, 1);
        }
        else if (field == "daire") {
            setTimeout(() => {
                sortedDaire(e);
                setShowKararOrganlariDropdown(true);
            }, 1);
        }
        else if (field == "sucTuru") {
            setTimeout(() => {
                sortedSucTuru(e);
                setShowSucTuruDropdown(true);
            }, 1);
        }
        else if (field == "davaTuru") {
            setTimeout(() => {
                sortedDavaTuru(e);
                setShowDavaTuruDropdown(true);
            }, 1);
        }
        else if (field == "turu")
            getMevzuatTuruLabel(e);
        else if (field == "yimKararTuru")
            setTimeout(() => {
                sortedKararTurleri(e)
                getKararTuruLabel(e);
            }, 1);

    }

    function scrollToElement() {
        let elmnt = document.getElementById("ara");
        if (elmnt !== null)
            elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const kararIndir = () => {
        setIndirmeLoading(true);
        setKararIndirme(true);
        let kararIndirRequest = selectedKarar.map(x => ({ "kararId": x.id, "adi": x.daireAdi + " " + x.kararNo.replaceAll("/", "-") }));
        kararAramaService.gunlukIndirmeLimitKontrol().then((resp) => {
            if (resp - selectedKarar.length < 0 || resp === 0) {
                let message = "";
                setIndirmeLoading(false);
                if (resp !== 0) {
                    message = 'Yalnızca ' + resp + ' karar seçebilirsiniz.';
                }
                toast.error('Günlük karar indirme limiti aşıldı.' + message, { position: toast.POSITION.TOP_CENTER });

            } else {
                for (let i = 0; i < kararIndirRequest.length; i++) {
                    kararAramaService.kararIndir(kararIndirRequest[i]).then((d) => {
                        setIndirmeLoading(false);
                        if (d) {
                            toast.error('Günlük karar indirme limiti aşıldı.', { position: toast.POSITION.TOP_CENTER });
                        }
                    });
                }
            }
        })
    }

    const onSort = (e) => {
        e.data = [];
        setSortIslemi(true);
        let degisken = e.order == 1 ? true : false;
        if (sortField != e.field || degisken != sortType) {
            setCurrentPage(0);
            if (kararAramaDataTable.length == 0) {
                let newDataTableParams = JSON.parse(JSON.stringify(initialDataTableParams));
                setDataTableParams(newDataTableParams);
                setCurrentPage(0);
            }
            setSortField(e.field);
            setSortType(e.order == 1 ? true : false);
            setSort(degisken == sortType ? e.field : degisken);
        }
        return kararAramaDataTable;
    };

    useEffect(() => {
        if (sort !== null && sortIslemi) {
            listKararAramaGridByFiltre();
        }
    }, [sort]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setCurrentPage(0);
            detayliAramaBtnRef.current.focus();
        }
    }

    const tarihHatasi = () => {
        if ((yargitayBasKararTarihi != null && yargitayBitKararTarihi != null) && yargitayBasKararTarihi > yargitayBitKararTarihi) {
            toast.error('Yargıtay Karar Başlangıç Tarihi bitiş tarihinden büyük olamaz!', { position: toast.POSITION.TOP_CENTER });
            return true;
        }
        else if ((mahBasKararTarihi != null && mahBitKararTarihi != null) && mahBasKararTarihi > mahBitKararTarihi) {
            toast.error('Mahkeme Karar Başlangıç Tarihi bitiş tarihinden büyük olamaz!', { position: toast.POSITION.TOP_CENTER });
            return true;
        }
        else if ((iddianameBasKararTarihi != null && iddianameBitKararTarihi != null) && iddianameBasKararTarihi > iddianameBitKararTarihi) {
            toast.error('İddianame Başlangıç Tarihi bitiş tarihinden büyük olamaz!', { position: toast.POSITION.TOP_CENTER });
            return true;

        }
        else if ((sucBasKararTarihi != null && sucBitKararTarihi != null) && sucBasKararTarihi > sucBitKararTarihi) {
            toast.error('Suç Başlangıç Tarihi bitiş tarihinden büyük olamaz!', { position: toast.POSITION.TOP_CENTER });
            return true;
        }
        else {
            return false;
        }
    }

    function listKararAramaGridByFiltre() {
        tarihHatasi();
        if (!tarihHatasi()) {
            let serbestAraList = [];
            let karakterKontrol;
            let yildizSayisi = 0;
            if (input.current !== undefined && input.current.value !== "") {
                let val = input.current.value;
                let yasakliKarakterleriCikar = /[^\p{L}*'"+\d\s\-\u00AD]/u;
                yildizSayisi = (val.match(/\*/g) || []).length;
                karakterKontrol = yasakliKarakterleriCikar.test(val);
                if (yildizSayisi > 1) {
                    toast.error('* karakterini yalnızca bir kez girebilirsiniz!', { position: toast.POSITION.TOP_CENTER });
                    setAraBtnDisableMi(true);
                    setAraBtnDisable(false);
                }
                else if (karakterKontrol) {
                    toast.error('Arama alanına geçerli bir karakter girmelisiniz!', { position: toast.POSITION.TOP_CENTER });
                    setAraBtnDisable(false);
                    setAraBtnDisableMi(true);
                }
                else {
                    serbestAraList.push(val);
                }
            }
            const params = {
                mahkemeTuru: mahkemeTuru,
                turu: kanunMaddesi.turu,
                kanunNo: kanunMaddesi.mevzuat_id,
                kanunMadde: kanunMadde,
                kanunFikra: kanunFikra,
                kanunBend: kanunBend,
                geldigiMah: geldigiMahkeme,
                hukukiKavram: hukukiKavram,
                hukukiKonu: hukukiKonu,
                sucTuru: sucTuru,
                davaTuru: davaTuru,
                daire: daire,
                anahtarKelimeler: anahtarKelime,
                serbestAra: serbestAraList,
                ilgiliKararArama: ilgiliKararArama,
                yimKararTurleri: yimKararTuru,
                kararTarihi: [yargitayBasKararTarihi, yargitayBitKararTarihi],
                iddianameTarihi: [iddianameBasKararTarihi, iddianameBitKararTarihi],
                geldigiMahTarihi: [mahBasKararTarihi, mahBitKararTarihi],
                sucTarihi: [sucBasKararTarihi, sucBitKararTarihi],
                esasNoBasYili: esasNoBasYili,
                esasNoBaslangic: esasNoBaslangic,
                esasNoBitYili: esasNoBitYili,
                esasNoBitis: esasNoBitis,
                kararNoBasYili: kararNoBasYili,
                kararNoBaslangic: kararNoBaslangic,
                kararNoBitYili: kararNoBitYili,
                onemDuzeyi: onemDuzeyi,
                kararNoBitis: kararNoBitis,
                emsalMi: emsal !== undefined ? emsal : true,
                sorguKriteri: sorguKriteriDataTable,
                offset: currentPage,
                limit: rows,
                son10Karar: son10Karar,
                sortField: sortField,
                sortType: sortType
            };
            const filters = [params.mahkemeTuru, params.turu, params.kanunNo, params.kanunMadde, params.kanunFikra, params.kanunBend, params.geldigiMah, params.hukukiKavram, params.hukukiKonu, params.sucTuru, params.davaTuru, params.daire, params.anahtarKelimeler, params.serbestAra,
            params.ilgiliKararArama, params.yimKararTurleri, yargitayBasKararTarihi, iddianameBasKararTarihi, yargitayBitKararTarihi, iddianameBitKararTarihi, mahBasKararTarihi, mahBitKararTarihi, sucBasKararTarihi, sucBitKararTarihi, esasNoBasYili, esasNoBaslangic, esasNoBitYili,
                esasNoBitis, kararNoBasYili, kararNoBaslangic, kararNoBitYili, kararNoBitis];

            if (!filters.some(filter => filter != null && filter.length !== 0 && filter !== "") && !karakterKontrol && !son10Karar && yildizSayisi <= 1) {
                toast.error('Arama yapmak için en az bir filtre alanını doldurmanız gerekmektedir.', { position: toast.POSITION.TOP_CENTER });
                setAraBtnDisableMi(true);
                setAraBtnDisable(false);
            }
            else if ((filters.some(filter => filter != null && filter.length !== 0 && filter !== "") && !karakterKontrol) || son10Karar) {
                setSorguVerileri(params);
                listSorguSonucByFiltre(params);
            }
        }
    }

    function listSorguSonucByFiltre(params) {
        setLoading(true);
        setFavoriList(false);
        setSelectedKarar([]);
        kararAramaService.listSorguSonucByFiltre(params).then((data) => {
            if (data.kararQueryModels.content.length > 0) {
                setKararAramaDataTable(data.kararQueryModels.content);
                let newDataTableParams = JSON.parse(JSON.stringify(dataTableParams));
                newDataTableParams.pageLinkSize =
                    data.kararQueryModels.totalPages === 0
                        ? 0
                        : data.kararQueryModels.totalPages - 1;
                newDataTableParams.first = (data.kararQueryModels.number * data.kararQueryModels.size) + (data.kararQueryModels.content.length > 0 ? 1 : 0);
                newDataTableParams.offset = data.kararQueryModels.pageable.offset + (data.kararQueryModels.content.length > 0 ? 1 : 0);
                newDataTableParams.last = (data.kararQueryModels.pageable.offset + data.kararQueryModels.numberOfElements);
                newDataTableParams.totalElement = data.kararQueryModels.totalElements;
                newDataTableParams.numberOfElement = data.kararQueryModels.numberOfElements;
                newDataTableParams.pageable = data.kararQueryModels.pageable;
                newDataTableParams.rows = data.kararQueryModels.content.length;
                setDataTableParams(newDataTableParams);
                setLogId(data.logId);
                setKararIndirme(false);
                setSecilenKararIndex(null);
                scrollToElement();
                setKararSayisiVisible(true);
                setLoading(false);
                setAraBtnDisable(false);

            } else {
                let newDataTableParams = JSON.parse(JSON.stringify(dataTableParams));
                newDataTableParams.totalElement = 0;
                newDataTableParams.rows = 100;
                newDataTableParams.first = 0;
                newDataTableParams.offset = 0;
                newDataTableParams.last = 0;
                newDataTableParams.numberOfElement = 0;
                newDataTableParams.pageable = true;
                setDataTableParams(newDataTableParams);
                setKararAramaDataTable([]);
                setLoading(false);
                setAraBtnDisable(false);
                setKararSayisiVisible(true);
            }
        }).catch(() => setAraBtnDisable(false));
    }

    const filtreTemizle = () => {
        yargitayFiltreTemizle();
        mahkemeFiltreTemizle();
        davaSucFiltreTemizle();
        loadRepos();
        setDisabledbtnSorgu(true);
        setSerbestAra("");
        setSorguId(null);
        setKlasor(null);
        setFavoriList(false);
        setHukukiKavram([]);
        setHukukiKonu([]);
        setAnahtarKelimeler([]);
        setSorguKriteriDataTable([]);
        setGeldigiMahkemeData(repoGeldigiMahkeme);
        setKanunNo('');
        setTuru([]);
        setEmsal(false);
        setOnemDuzeyi([]);
        setHukukiKavramLabel([]);
        setHukukiKonularLabel([]);
        setAnahtarKelimeLabel([]);
        setSortIslemi(false);
        datatable.current.reset();
        setSortField(null);
        setSortType(null);
        setSort(null);
        setTimeout(() => {
            temizle();
        }, 1);
        if (input.current !== undefined)
            input.current.value = "";
    }

    const temizle = () => {
        setYargitayBasKararTarihi(null);
        setYargitayBitKararTarihi(null);
        setMahBasKararTarihi(null);
        setMahBitKararTarihi(null);
        setSucBasKararTarihi(null);
        setSucBitKararTarihi(null);
        setIddianameBasKararTarihi(null);
        setIddianameBitKararTarihi(null);
    }
    const onClickHeader = () => {
        if (gridDetailDialogHeader != "Önem Açıklama Detayı" && gridDetailDialogHeader != "Özet Detayı") {
            copyToClipboard(gridDetailDialogHeader);
        }
    }

    const values = useMemo(() => ({
        hukukiKavram, setSerbestAra, setAnahtarKelimeLabel, onClickHeader, kararDetayOzet, kararDetayMevzuat, logId, gridDetailDialogHeader,
        setKararDetayMevzuat, setDisplayGridKararMetniDialog, selectedKarar, listKararAramaGridByFiltre, favoriList, dataTableParams,
        setDisabledbtnSorgu, hukukiKonu, setFavoriList, setHukukiKavram, filtreTemizle, kararDetayIlgiliKarar, kararDetayTematikNot, currentPage,
        setGridDetailDialogHeader, setKararDetayOzet, setKararDetayTematikNot, onSort, setSelectedKarar, indirmeLoading, kararIndir, kararSayisiVisible,
        setSonucGizle, updateField, setHukukiKonu, setKlasor, setAraBtnDisable, input, kararDetayHukukiKavram, kararDetayYayinlanmaTarihi, setCurrentPage,
        displayGridDetailDialog, setKararDetayIlgiliKarar, datatable, son10Karar, secilenKararIndex, rows, setSon10Karar, setAraBtnDisableMi, emsal, sorguId,
        setDisplayGridDetailDialog, setKararDetayHukukiKavram, setKararDetayYayinlanmaTarihi, setSecilenKararIndex, sonucGizle, setIsLoading, controlField,
        araBtnDisableMi, setEmsal, serbestAra, detay, kararDetayAnahtarKelime, loading, setKararDetayHukukiKonu, setSortIslemi, kararIndirme, expanded,
        handleKeyPress, sorguKriteriDataTable, klasor, setSorgulama, araBtnDisable, displayGridKararMetniDialog, kararDetayHukukiKonu, hukukiKavramLabel,
        setExpanded, kararDetayIliskiliBelge, setLoading, setKararDetayAnahtarKelime, setSortField, kararAramaDataTable,
        detayliAramaBtnRef, setHukukiKavramLabel, anahtarKelime, setKararDetayIliskiliBelge, setSortType, sorguVerileri,
        disabledbtnSorgu, setRows, setSorguId, hukukiKonularLabel, setAnahtarKelimeler, sorgulama, setSort, setDataTableParams, setHukukiKonularLabel,
        anahtarKelimeLabel, setKararAramaDataTable, setDetay, isLoading
    }), [
        hukukiKavram, setSerbestAra, setAnahtarKelimeLabel, onClickHeader, kararDetayOzet, kararDetayMevzuat, logId, gridDetailDialogHeader,
        setKararDetayMevzuat, setDisplayGridKararMetniDialog, selectedKarar, listKararAramaGridByFiltre, favoriList, dataTableParams,
        setDisabledbtnSorgu, hukukiKonu, setFavoriList, setHukukiKavram, filtreTemizle, kararDetayIlgiliKarar, kararDetayTematikNot, currentPage,
        setGridDetailDialogHeader, setKararDetayOzet, setKararDetayTematikNot, onSort, setSelectedKarar, indirmeLoading, kararIndir, kararSayisiVisible,
        setSonucGizle, updateField, setHukukiKonu, setKlasor, setAraBtnDisable, input, kararDetayHukukiKavram, kararDetayYayinlanmaTarihi, setCurrentPage,
        displayGridDetailDialog, setKararDetayIlgiliKarar, datatable, son10Karar, secilenKararIndex, rows, setSon10Karar, setAraBtnDisableMi, emsal, sorguId,
        setDisplayGridDetailDialog, setKararDetayHukukiKavram, setKararDetayYayinlanmaTarihi, setSecilenKararIndex, sonucGizle, setIsLoading, controlField,
        araBtnDisableMi, setEmsal, serbestAra, detay, kararDetayAnahtarKelime, loading, setKararDetayHukukiKonu, setSortIslemi, kararIndirme, expanded,
        handleKeyPress, sorguKriteriDataTable, klasor, setSorgulama, araBtnDisable, displayGridKararMetniDialog, kararDetayHukukiKonu, hukukiKavramLabel,
        setExpanded, kararDetayIliskiliBelge, setLoading, setKararDetayAnahtarKelime, setSortField, kararAramaDataTable,
        detayliAramaBtnRef, setHukukiKavramLabel, anahtarKelime, setKararDetayIliskiliBelge, setSortType, sorguVerileri,
        disabledbtnSorgu, setRows, setSorguId, hukukiKonularLabel, setAnahtarKelimeler, sorgulama, setSort, setDataTableParams, setHukukiKonularLabel,
        anahtarKelimeLabel, setKararAramaDataTable, setDetay, isLoading
    ]);

    return (
        <KararAramaContext.Provider value={values}>
            {children}
        </KararAramaContext.Provider>
    )

};

export const useKararArama = () => {
    const context = useContext(KararAramaContext);

    if (context === undefined) {
        throw new Error("must be call inside KararAramaContextProvider");
    }

    return context;
};
