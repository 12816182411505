import { YardimService } from "../services/YardimService";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import "./../css/yardim.css";

const Yardim = ({ displayYardim, setDisplayYardim }) => {
  const service = new YardimService();

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const ref = useRef();

  useEffect(() => {
    if ((displayYardim && listData.length == 0) || window.location.pathname == "/yardimSayfasi") {
      getListData();
    }
  }, [displayYardim, window.location.pathname]);

  const getListData = () => {
    setLoading(true);
    service.getWebYardim().then((data) => {
      data.sort((a, b) =>
        a.gosterimSirasi > b.gosterimSirasi
          ? 1
          : b.gosterimSirasi > a.gosterimSirasi
            ? -1
            : 0
      );
      setListData(data);
      setLoading(false);
    });
  };

  const titleTemplate = (ref, title) => {
    return (
      <a href={"#yardim" + ref}>
        <h3>{title}</h3>
      </a>
    );
  };

  let currentMatch = 0;
  const focusSearched = () => {
    const element = document.getElementsByTagName("mark");
    if (element.length > currentMatch) {
      element[currentMatch].scrollIntoView({ behavior: "smooth" });
      currentMatch++;
    } else {
      currentMatch = 0;
    }
  };

  let htmlIcerik = "";
  function search(_value) {
    if (_value) {
      if (htmlIcerik == "") {
        htmlIcerik = ref.current.innerHTML;
      }

      let originalValue = _value;
      _value = _value.toLocaleLowerCase();
      if (_value.length > 2) {
        let value = htmlIcerik;
        let args = _value;

        if (!args) {
          htmlIcerik = value;
        }
        args = args.replace("i", "[İi]");
        let regex = new RegExp(args, "gi");
        let match = value.match(regex);
        if (match == null) {
          regex = new RegExp(originalValue, "gi");
          match = value.match(regex);
        }

        if (!match) {
          htmlIcerik = value;
        }

        if (match == null) {
          ref.current.innerHTML = htmlIcerik;
        }

        ref.current.innerHTML = value.replace(
          regex,
          `<mark>${originalValue}</mark>`
        );
      }
    } else {
      ref.current.innerHTML = htmlIcerik;
    }
  }

  const contentTemplate = (ref, title, content) => {
    return (
      <div className="p-grid p-fluid" id={"yardim" + ref}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <br />
      </div>
    );
  };

  return (
    <div>
      {window.location.pathname == "/yardimSayfasi" &&
        <div id="yardim-content"
        // visible={displayYardim}
        >
          <h6 className="ml-6">
            Bu bölümde uygulamanın kullanımı hakkında detaylı bilgi
            alabilirsiniz.
          </h6>
          <div style={{ display: "flex" }}>
            <div className="yardim-mobil-header">

              <span className="p-float-label p-input-icon-left block">
                <i className="pi pi-search" />
                <InputText
                  id="yardimSearchInput"
                  placeholder="Yardım İçeriği Ara... (En az 3 karakter giriniz)"
                  onChange={(e) => search(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      focusSearched();
                    }
                  }}
                  className="w-full"
                />

              </span>

            </div>

            <div className="left_side" style={{ marginLeft: "2rem", marginTop: "5rem" }}>
              {listData.map((yardim, i) => {
                return titleTemplate(yardim.gosterimSirasi, yardim.baslik);
              })}
            </div>

            <div className="yardim-content" style={{ marginLeft: "10rem", marginTop: "5rem", height: "150vw" }} >
              <div ref={ref} id="yardimHtmlText" >
                {listData.map((yardim, i) => {
                  return contentTemplate(
                    yardim.gosterimSirasi,
                    yardim.baslik,
                    yardim.metin
                  );
                })}
              </div>
            </div>
          </div>
        </div>}
      <Dialog id="yardim-content"
        header="Yardım"
        visible={displayYardim}
        position={"right"}
        modal
        style={{
          width: "70vw",
          height: "90vh"
        }}
        onHide={() => setDisplayYardim(false)}
      >
        <div style={{ display: "flex" }}>
          <div className="yardim-header yardim-margin" style={{ background: "#FBF8F7" }}>
            <h3>
              Bu bölümde uygulamanın kullanımı hakkında detaylı bilgi
              alabilirsiniz.
            </h3>
            <span className="p-float-label p-input-icon-left block">
              <i className="pi pi-search" />
              <InputText
                id="yardimSearchInput"
                placeholder="Yardım İçeriği Ara... (En az 3 karakter giriniz)"
                onChange={(e) => search(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    focusSearched();
                  }
                }}
                className="w-full"

              />
            </span>
          </div>

          <div className="left_side ">
            {listData.map((yardim, i) => {
              return titleTemplate(yardim.gosterimSirasi, yardim.baslik);
            })}
          </div>

          <div className="content">
            <div ref={ref} id="yardimHtmlText" className="content-height">
              {listData.map((yardim, i) => {
                return contentTemplate(
                  yardim.gosterimSirasi,
                  yardim.baslik,
                  yardim.metin
                );
              })}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Yardim;
