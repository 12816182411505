import React from "react";

class TextParser {
    static parser(text: string): any {
        const splitByT = text.split('');
        let splitted = [];
        let item = null;
        for (let i = 0; i < splitByT.length; i++) {
            if (splitByT[i] === '\n' || splitByT[i] === '\t' || splitByT[i] === ' ') {
                if (item != null) {
                    splitted.push(item);
                    item = null;
                }
                splitted.push(splitByT[i]);
            } else {
                if (item == null) {
                    item = splitByT[i];
                } else {
                    item = item + splitByT[i];
                }
            }
        }
        if (item != null) {
            splitted.push(item);
            item = null;
        }

        return splitted;
    }
    
    static toHtml(text: string): any {
        const parts = text.split(/(<mark>[\s\S]*?<\/mark>)/);
        let splitted = [];
    
        parts.forEach(part => {
            if (part.startsWith('<mark>') || part.endsWith('</mark>')) {
                splitted.push(<div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: part }} />);
            } else {
                part.split('').forEach(char => {
                    if (char === '\n') {
                        splitted.push(<br />);
                    } else if (char === '\t') {
                        splitted.push(<span style={{whiteSpace: 'pre'}}>&emsp;&emsp;</span>);
                    } else if (char === ' ') {
                        splitted.push(<span>&nbsp;</span>);
                    } else {
                        splitted.push(<span>{char}</span>);
                    }
                });
            }
        });
    
        return splitted;
    }
}

export default TextParser;