import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    EDEVLET_CREATE_REDIRECT_URL_SUCCESS
} from "../actions/authAction";

const initState = {
    user: '',
    isAuthenticated: false,
    error: false,
    errorMessage: '',
    isAdmin: false
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                isAuthenticated: true,
                error: false,
                errorMessage: '',
                history: [],
                isAdmin: action.user.yoneticiMi,
                isOnayliMi: action.user.onayliMi,
                isFetching: false,
                kullanici:action.user.kullanici
            };
        case LOGIN_ERROR:
            return {
                ...state,
                user: '',
                error: true,
                isAuthenticated: false,
                errorMessage: action.error,
                history: [],
                isFetching: false,
                kullanici:''
            };
        case EDEVLET_CREATE_REDIRECT_URL_SUCCESS:
            return {
                url: action.url,
                isFetching: false
            };
        case LOGOUT:
            return {
                user: '',
                isFetching: false
            };
        default:
            return state;
    }
}

export default authReducer;