import React, { createContext, useContext, useRef, useState, useEffect, useMemo } from "react";
import { KararAramaService } from "../services/KararAramaService";
import { VeriDoldurmaService } from "../services/VeriDoldurmaService";
import { useDavaSuc } from "./DavaSucContext";
import { useMahkemeBilgileri } from "./MahkemeBilgileriContext";
import { useVeriDoldurma } from "./VeriDoldurmaContext";
import { useYargitayBilgileri } from "./YargitayBilgileriContext";
import { toast } from "react-toastify";
import { useKararArama } from "./KararAramaContext";
import { MevzuatService } from "../services/MevzuatService";


const SorguContext = createContext();

export const SorguProvider = ({ children }) => {
    const { selectedMevzuat, kanunMadde, kanunFikra, kanunBend, kanunMaddesi, ilgiliKararArama, sucBasKararTarihi, sucBitKararTarihi, davaTuru, setSelectedMevzuat,
        sucTuru, setSucBasKararTarihi, setSucBitKararTarihi, setSucTuru, setDavaTuru, ilgiliKararAramaRef, kanunMaddeRef, kanunFikraRef, kanunBendRef, setTurSecildiMi,
        setMevzuatlar, mevzuatlar, davaSucHandleInput, setKanunMaddesi } = useDavaSuc();
    const { listKararAramaGridByFiltre, hukukiKavram, setKararAramaDataTable, input, setHukukiKavram, dataTableParams, setDataTableParams,
        hukukiKonu, setHukukiKonu, emsal, setEmsal, sorguKriteriDataTable, filtreTemizle, setLoading, selectedKarar, setSelectedKarar, anahtarKelime, setAnahtarKelimeler,
        setFavoriList, setKlasor, sorguId, serbestAra, klasor, setDisabledbtnSorgu, setSorguId, setExpanded, setSonucGizle } = useKararArama();
    const { yargitayBasKararTarihi, yargitayBitKararTarihi, esasNoBasYili, esasNoBaslangic, esasNoBitYili, esasNoBitis, kararNoBasYili, kararNoBaslangic, yargHandleInput,
        kararNoBitYili, kararNoBitis, daire, yimKararTuru, setYargitayBasKararTarihi, setYimKararTurleri, setDaire, pasifAlanlariBelirle, setYargitayBitKararTarihi,
        esasNoBasYiliRef, esasNoBitYiliRef, esasNoBaslangicRef, esasNoBitisRef, kararNoBasYiliRef, kararNoBaslangicRef, kararNoBitYiliRef, kararNoBitisRef } = useYargitayBilgileri();
    const { iddianameBasKararTarihi, iddianameBitKararTarihi, mahBasKararTarihi, mahBitKararTarihi, mahkemeTuru, setMahBasKararTarihi, setMahBitKararTarihi,
        setIddianameBasKararTarihi, setIddianameBitKararTarihi, setMahkemeTuru } = useMahkemeBilgileri();
    const { geldigiMahkeme, geldigiMahkemeGuncelle, setGeldigiMahkeme, } = useVeriDoldurma();
    const dummy = [{
        key: "0",
        label: "Klasörler",
        value: "Sorgu Klasörü",
    }];
    const [mevzuatId, setMevzuatId] = useState();
    const [sorguNodes, setSorguNodes] = useState(dummy);
    const veriDoldurmaService = new VeriDoldurmaService();
    const kararAramaService = new KararAramaService();
    const mevzuatService = new MevzuatService();
    const [favoriId, setFavoriId] = useState();
    const [secilenKlasor, setSecilenKlasor] = useState(null);
    const [sorguKararAdi, setSorguKararAdi] = useState("");
    const [yeniKararBilgi, setYeniKararBilgi] = useState(false);
    const [digerKlasor, setDigerKlasor] = useState("");
    const [disabledbtnFavori, setDisabledbtnFavori] = useState(true);
    const [displaySorguFavoriEkleDialog, setDisplaySorguFavoriEkleDialog] = useState(false);
    const [repoKlasor, setRepoKlasor] = useState([]);
    const [sorguFavoriEkleDialogHeader, setSorguFavoriEkleDialogHeader] = useState("");
    const [sorguMenu, setSorguMenu] = useState(false);
    const digerKlasorRef = useRef();
    const sorguAdiRef = useRef();

    useEffect(() => {
        if (kanunMaddesi.turu != "" && kanunMaddesi.turu != null && mevzuatlar.filter(x => kanunMaddesi.turu == x.mevzuatTurUuid).length == 0) {
            mevzuatService.filterMevzuatByTur(kanunMaddesi.turu).
                then((data) => {
                    if (mevzuatlar.length == 0) {
                        setMevzuatlar(data)
                    } else {
                        setMevzuatlar(mevzuatlar.concat(data));
                    }
                })
        }
    }, [kanunMaddesi.turu]);

    useEffect(() => {
        let madde = mevzuatlar.filter(x => x.id === mevzuatId)[0];
        setSelectedMevzuat(madde);
    }, [mevzuatlar]);


    function getKlasors() {
        setFavoriList(false);
        veriDoldurmaService.getTreeNodes().then((data) => {
            let children = [];
            let children1 = [];
            if (data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].favoriMi) {
                        children1.push(data[i].nodes)
                    } else {
                        children.push(data[i].nodes)
                    }

                }
            }

            const dummySorguNodes = [{
                key: "0",
                label: "Klasörler",
                value: "Sorgu Klasörü",
                children:
                    [
                        {
                            key: "0-0",
                            label: "SORGULAR",
                            value: "Sorgu Klasörü",
                            icon: "pi pi-search  mr-2 font-semibold",
                            children: children
                        },
                        {
                            "key": "0-1",
                            "label": "FAVORİLER",
                            "value": "Favori Klasörü",
                            "icon": "pi pi-star mr-2 font-semibold",
                            children: children1
                        }
                    ]
            }];
            setSorguNodes(dummySorguNodes);
        });
    }

    function updateKlasorAdi(params) {
        kararAramaService.updateKlasorAdi(params).then((data) => {
            getKlasors();
        });
    }

    function updateSorguFavoriAdi(params) {
        kararAramaService.updateSorguFavoriAdi(params).then((data) => {
            getKlasors();
        });
    }

    function yeniKlasorEkle(params, callback) {
        kararAramaService.yeniKlasorEkle(params).then(() => {
            getKlasors();
            if (callback) {
                callback(true);
            }
        });
    }

    useEffect(() => {
        if (sorguMenu) {
            filtreTemizle();
        }
    }, [sorguMenu]);

    function silComponent(params, callback) {
        kararAramaService.silComponent(params).then((data) => {
            if (callback) {
                getKlasors();
                callback(true);
            }
        });
    }

    const sorguEkle = () => {
        if (!sorguMenu) {
            setLoading(true);
            veriDoldurmaService.getKlasor(false).then((data) => {
                setRepoKlasor(data);
                setLoading(false);
                setSorguFavoriEkleDialogHeader("Sorgu Ekle");
                setYeniKararBilgi(false);
                setSorguKararAdi('');
                setDigerKlasor('');
                setKlasor([]);
            });
        }
        setDisplaySorguFavoriEkleDialog(true);
    }
    const yeniSorgu = () => {
        setSorguMenu(false);
        filtreTemizle();
    }

    const sorguFavoriKaydet = (isBaslikMi) => {
        if (isBaslikMi !== undefined) {
            let mevzuatId = (selectedMevzuat !== null && selectedMevzuat != undefined) ? selectedMevzuat.id : null;
            let sorgu = {
                id: sorguId,
                kanunNo: mevzuatId,
                kanunMadde: kanunMadde,
                kanunFikra: kanunFikra,
                kanunBend: kanunBend,
                turu: kanunMaddesi.turu,
                ilgiliKarar: ilgiliKararArama,
                kararTarihBaslangic: yargitayBasKararTarihi != null ? yargitayBasKararTarihi : null,
                kararTarihBitis: yargitayBitKararTarihi != null ? yargitayBitKararTarihi : null,
                geldigiMahTarihBaslangic: mahBasKararTarihi != null ? mahBasKararTarihi : null,
                geldigiMahTarihBitis: mahBitKararTarihi != null ? mahBitKararTarihi : null,
                sucTarihBaslangic: sucBasKararTarihi != null ? sucBasKararTarihi : null,
                sucTarihBitis: sucBitKararTarihi != null ? sucBitKararTarihi : null,
                iddianameTarihBaslangic: iddianameBasKararTarihi != null ? iddianameBasKararTarihi : null,
                iddianameTarihBitis: iddianameBitKararTarihi != null ? iddianameBitKararTarihi : null,
                serbestAra: serbestAra,
                esasNoBaslangicYil: esasNoBasYili,
                esasNoBaslangic: esasNoBaslangic,
                esasNoBitisYil: esasNoBitYili,
                esasNoBitis: esasNoBitis,
                kararNoBaslangicYil: kararNoBasYili,
                kararNoBaslangic: kararNoBaslangic,
                kararNoBitisYil: kararNoBitYili,
                kararNoBitis: kararNoBitis,
                emsalOlmayanMi: emsal !== undefined ? emsal : false,
            };

            let sorguKriteriList = sorguKriteriDataTable == undefined ? [] : sorguKriteriDataTable;
            let anahtarKelimes = anahtarKelime;
            let mahkemeTurs = mahkemeTuru;
            let daires = daire;
            let davaTurs = davaTuru;
            let sucTurs = sucTuru;
            let hukukiKavrams = hukukiKavram;
            let hukukiKonus = hukukiKonu;
            let mahkemes = geldigiMahkeme;
            let yimKararTurs = yimKararTuru;
            let klasorId = klasor;
            let bagliKlasorId = secilenKlasor !== null && secilenKlasor.label !== "Yeni Klasör" ? secilenKlasor.value : null;
            let baslikMi = isBaslikMi;
            let sorguAdi = sorguKararAdi;
            let bilgilendirme = yeniKararBilgi;
            let klasorAdi = digerKlasor;
            let params = {
                sorgu, sorguAdi, bilgilendirme, sorguKriteriList, baslikMi, anahtarKelimes, sucTurs, mahkemeTurs, daires, hukukiKavrams, hukukiKonus,
                davaTurs, mahkemes, yimKararTurs, klasorId, bagliKlasorId, klasorAdi, sorguId
            }
            setLoading(true);
            kararAramaService.sorguKaydet(params).then((data) => {
                setLoading(false);
                if (!data) {
                    toast.error('Girilen sorgu kriteri sistemde bulunmaktadır!', { position: toast.POSITION.TOP_CENTER });
                } else {
                    toast.success('Sorgu kaydedildi!');
                }
                setDisabledbtnSorgu(true);
                onHide();
            }).catch(onHide());

        } else {
            let bagliKlasorId = secilenKlasor !== null && secilenKlasor.label !== "Yeni Klasör" ? secilenKlasor.value : null;
            let kayitliFavoriler = selectedKarar.filter(x => x.favoriId !== null).map(x => x.esasNo);
            let yeniFavoriler = selectedKarar.filter(x => x.favoriId === null);
            if (yeniFavoriler.length > 0) {
                let sorgu = {
                    favoriAdi: sorguKararAdi,
                    klasorAdi: digerKlasor,
                    klasorId: klasor,
                    bagliKlasorId: bagliKlasorId,
                    kararIds: yeniFavoriler.map(x => x.id)
                };
                setLoading(true);
                kararAramaService.favoriKaydet(sorgu).then(() => {

                    if (kayitliFavoriler.length > 0) {
                        toast.info(kayitliFavoriler.join(", ") + ' Esas numaraları kararlar daha önce favoriye eklendiğinden favori ile ilişkilendirilemedi!', { position: toast.POSITION.TOP_CENTER }, 5000);
                    }
                    toast.success(yeniFavoriler.map(x => x.esasNo).join(", ") + ' Favori kayıtlar kaydedildi!', { position: toast.POSITION.TOP_CENTER }, 5000);
                    setDisabledbtnFavori(true);
                    getKlasors();
                    listKararAramaGridByFiltre();
                    onHide();
                    setLoading(false);
                }).catch(onHide());
            } else {
                toast.error('Favorilerinizde bulunan kararı/kararları tekrar ekleyemezsiniz!', { position: toast.POSITION.TOP_CENTER }, 5000);
            }
        }
    }

    function getSorguById(paramSorguId, callback) {
        setSonucGizle(false);
        setFavoriList(false);
        kararAramaService.getSorguById(paramSorguId).then((data) => {
            if (data) {
                kanunMaddeRef.current.value = data.sorgu.kanunMadde !== '' && data.sorgu.kanunMadde !== null ? data.sorgu.kanunMadde : '';
                davaSucHandleInput(kanunMaddeRef.current.value, "kanunMadde");
                kanunFikraRef.current.value = data.sorgu.kanunFikra !== '' && data.sorgu.kanunFikra !== null ? data.sorgu.kanunFikra : '';
                davaSucHandleInput(kanunFikraRef.current.value, "kanunFikra");
                kanunBendRef.current.value = data.sorgu.kanunBend !== '' && data.sorgu.kanunBend !== null ? data.sorgu.kanunBend : '';
                davaSucHandleInput(kanunBendRef.current.value, "kanunBend");
                if (data.sorgu.turu !== '' && data.sorgu.turu !== null) {
                    setTurSecildiMi(true);
                }
                let newKanunMaddesi = JSON.parse(JSON.stringify(kanunMaddesi));
                newKanunMaddesi["turu"] = data.sorgu.turu;
                setKanunMaddesi(newKanunMaddesi);
                input.current.value = data.sorgu.serbestAra !== '' && data.sorgu.serbestAra !== null ? data.sorgu.serbestAra : "";
                ilgiliKararAramaRef.current.value = data.sorgu.ilgiliKarar !== '' && data.sorgu.ilgiliKarar !== null ? data.sorgu.ilgiliKarar : '';
                davaSucHandleInput(ilgiliKararAramaRef.current.value, "ilgiliKararArama")
                const kararTarih0 = data.sorgu.kararTarihBaslangic !== '' && data.sorgu.kararTarihBaslangic !== null ? true : false;
                const kararTarih1 = data.sorgu.kararTarihBitis !== '' && data.sorgu.kararTarihBitis !== null ? true : false;
                setYargitayBasKararTarihi(kararTarih0 ? new Date(data.sorgu.kararTarihBaslangic) : '');
                setYargitayBitKararTarihi(kararTarih1 ? new Date(data.sorgu.kararTarihBitis) : '');
                const gelMahTarih0 = data.sorgu.geldigiMahTarihBaslangic !== '' && data.sorgu.geldigiMahTarihBaslangic !== null ? true : false;
                const gelMahTarih1 = data.sorgu.geldigiMahTarihBitis !== '' && data.sorgu.geldigiMahTarihBitis !== null ? true : false;
                setMahBasKararTarihi(gelMahTarih0 ? new Date(data.sorgu.geldigiMahTarihBaslangic) : '');
                setMahBitKararTarihi(gelMahTarih1 ? new Date(data.sorgu.geldigiMahTarihBitis) : '');
                const sucTarih0 = data.sorgu.sucTarihBaslangic !== '' && data.sorgu.sucTarihBaslangic !== null ? true : false;
                const sucTarih1 = data.sorgu.sucTarihBitis !== '' && data.sorgu.sucTarihBitis !== null ? true : false;
                setSucBasKararTarihi(sucTarih0 ? new Date(data.sorgu.sucTarihBaslangic) : '');
                setSucBitKararTarihi(sucTarih1 ? new Date(data.sorgu.sucTarihBitis) : '');
                const iddianameTarih0 = data.sorgu.iddianameTarihBaslangic !== '' && data.sorgu.iddianameTarihBaslangic !== null ? true : false;
                const iddianameTarih1 = data.sorgu.iddianameTarihBitis !== '' && data.sorgu.iddianameTarihBitis !== null ? true : false;
                setIddianameBasKararTarihi(iddianameTarih0 ? new Date(data.sorgu.iddianameTarihBaslangic) : '');
                setIddianameBitKararTarihi(iddianameTarih1 ? new Date(data.sorgu.iddianameTarihBitis) : '');
                esasNoBasYiliRef.current.value = data.sorgu.esasNoBaslangicYil !== '' && data.sorgu.esasNoBaslangicYil !== null ? data.sorgu.esasNoBaslangicYil : '';
                yargHandleInput(esasNoBasYiliRef.current.value, "esasNoBasYili");
                esasNoBitYiliRef.current.value = data.sorgu.esasNoBitisYil !== '' && data.sorgu.esasNoBitisYil !== null ? data.sorgu.esasNoBitisYil : '';
                yargHandleInput(esasNoBitYiliRef.current.value, "esasNoBitYili");
                esasNoBaslangicRef.current.value = data.sorgu.esasNoBaslangic !== '' && data.sorgu.esasNoBaslangic !== null ? (data.sorgu.esasNoBaslangic) : '';
                yargHandleInput(esasNoBaslangicRef.current.value, "esasNoBaslangic");
                esasNoBitisRef.current.value = data.sorgu.esasNoBitis !== '' && data.sorgu.esasNoBitis !== null ? (data.sorgu.esasNoBitis) : '';
                yargHandleInput(esasNoBitisRef.current.value, "esasNoBitis");
                kararNoBasYiliRef.current.value = data.sorgu.kararNoBaslangicYil !== '' && data.sorgu.kararNoBaslangicYil !== null ? (data.sorgu.kararNoBaslangicYil) : '';
                yargHandleInput(kararNoBasYiliRef.current.value, "kararNoBasYili");
                kararNoBitYiliRef.current.value = data.sorgu.kararNoBitisYil !== '' && data.sorgu.kararNoBitisYil !== null ? (data.sorgu.kararNoBitisYil) : '';
                yargHandleInput(kararNoBitYiliRef.current.value, "kararNoBitYili");
                kararNoBaslangicRef.current.value = data.sorgu.kararNoBaslangic !== '' && data.sorgu.kararNoBaslangic !== null ? (data.sorgu.kararNoBaslangic) : '';
                yargHandleInput(kararNoBaslangicRef.current.value, "kararNoBaslangic");
                kararNoBitisRef.current.value = data.sorgu.kararNoBitis !== '' && data.sorgu.kararNoBitis !== null ? data.sorgu.kararNoBitis : '';
                yargHandleInput(kararNoBitisRef.current.value, "kararNoBitis");
                setSorguKararAdi(data.sorguAdi);
                setKlasor(data.klasorId);
                setYeniKararBilgi(data.bilgilendirme);
                setEmsal(data.sorgu.emsalOlmayanMi);
                setSorguId(data.sorgu.id);
                data.anahtarKelimes.length !== 0 ? setAnahtarKelimeler(data.anahtarKelimes) : setAnahtarKelimeler([]);
                data.hukukiKavrams.length !== 0 ? setHukukiKavram(data.hukukiKavrams) : setHukukiKavram([]);
                data.hukukiKonus.length !== 0 ? setHukukiKonu(data.hukukiKonus) : setHukukiKonu([]);
                data.daires.length !== 0 ? setDaire(data.daires) : setDaire([]);
                data.sucTurs.length !== 0 ? setSucTuru(data.sucTurs) : setSucTuru([]);
                pasifAlanlariBelirle(data.daires);
                data.mahkemeTurs.length !== [] ? setMahkemeTuru(data.mahkemeTurs) : setMahkemeTuru([]);
                geldigiMahkemeGuncelle(data.mahkemeTurs, function (resp) {
                    if (resp) {
                        data.mahkemes.length !== [] ? setGeldigiMahkeme(data.mahkemes) : setGeldigiMahkeme([]);
                    }
                })
                data.davaTurs.length !== [] ? setDavaTuru(data.davaTurs) : setDavaTuru([]);
                data.yimKararTurs.length !== [] ? setYimKararTurleri(data.yimKararTurs) : setYimKararTurleri([]);
                let mevzuat = data.sorgu.kanunNo !== '' && data.sorgu.kanunNo !== null ? data.sorgu.kanunNo : '';
                if (mevzuat !== '') {
                    newKanunMaddesi["mevzuat_id"] = mevzuat;
                    setKanunMaddesi(newKanunMaddesi);
                    let madde = mevzuatlar.filter(x => x.id === mevzuat)[0];
                    setSelectedMevzuat(madde);
                    setMevzuatId(mevzuat);
                } else {
                    setSelectedMevzuat(null);
                }

                if (data.daires.length > 0 || data.davaTurs.length > 0 || data.geldigiMahTarihBaslangic !== null || data.geldigiMahTarihBitis !== null ||
                    data.iddianameTarihBaslangic !== null || data.iddianameTarihBitis !== null || data.kararTarihBaslangic !== null ||
                    data.kararTarihBitis !== null || data.mahkemeTurs.length > 0 || data.mahkemes.length > 0 || data.sucTurs.length > 0 ||
                    data.yimKararTurs.length > 0 || data.sucTarihBaslangic !== null || data.sucTarihBitis !== null || data.sorgu.esasNoBaslangicYil !== '' ||
                    data.sorgu.esasNoBitisYil !== '' || data.sorgu.esasNoBaslangic !== '' || data.sorgu.esasNoBitis !== '' || data.sorgu.kararNoBaslangicYil ||
                    data.sorgu.kararNoBitisYil !== '' || data.sorgu.kararNoBaslangic !== '' || data.sorgu.kararNoBitis !== '' || data.sorgu.kanunNo !== '' ||
                    data.sorgu.kanunMadde !== '' || data.sorgu.kanunFikra !== '' || data.sorgu.kanunBend !== '' || data.sorgu.turu !== '' || data.sorgu.ilgiliKarar !== '') {
                    setExpanded(true);

                }
                if (callback) {
                    callback(true);
                }

                setTimeout(() => {
                    sorguSonucGetir();
                }, 500);
            }
        });
    }


    function sorguSonucGetir() {
        const button = document.getElementById('ara-sorgu');
        if (button) {
            button.click();
        }
    }
    function getFavoriById(favoriId) {
        filtreTemizle();
        setSonucGizle(true);
        setFavoriList(true);
        setLoading(true);
        kararAramaService.getFavoriById(favoriId).then((data) => {
            if (data) {
                setFavoriId(favoriId);
                setKararAramaDataTable(data);
                let newDataTableParams = JSON.parse(JSON.stringify(dataTableParams));
                newDataTableParams.pageLinkSize = 0;
                newDataTableParams.first = 1;
                newDataTableParams.offset = 0;
                newDataTableParams.totalElement = data.length;
                newDataTableParams.numberOfElement = data.length;
                setDataTableParams(newDataTableParams);
                setLoading(false);
            } else {
                setKararAramaDataTable([]);
                setLoading(false);
            }
        });
    }
    const favorilereEkle = () => {
        veriDoldurmaService.getKlasor(true).then((data) => {
            setRepoKlasor(data);
            setLoading(false);
            setSorguFavoriEkleDialogHeader("Favorilere Ekle");
            setDisplaySorguFavoriEkleDialog(true);
            setSorguKararAdi('');
            setKlasor([]);
            setDigerKlasor('');
        });
    }

    const onHide = () => {
        if (sorguFavoriEkleDialogHeader.includes("Favori")) {
            setSelectedKarar([]);
        }
        setDisplaySorguFavoriEkleDialog(false);
        setLoading(false);
        getKlasors();
    }

    const values = useMemo(() => ({
        sorguNodes, setSorguNodes, favoriId, setFavoriId, secilenKlasor, setSecilenKlasor, digerKlasorRef, sorguAdiRef,
        sorguKararAdi, setSorguKararAdi, yeniKararBilgi, setYeniKararBilgi, digerKlasor, setDigerKlasor, disabledbtnFavori, setDisabledbtnFavori,
        displaySorguFavoriEkleDialog, setDisplaySorguFavoriEkleDialog, repoKlasor, setRepoKlasor, sorguFavoriEkleDialogHeader, setSorguFavoriEkleDialogHeader, sorguMenu, setSorguMenu,
        getKlasors, updateKlasorAdi, updateSorguFavoriAdi, yeniKlasorEkle, silComponent, sorguEkle, yeniSorgu, sorguFavoriKaydet, getSorguById, getFavoriById, favorilereEkle, onHide
    }), [
        sorguNodes, setSorguNodes, favoriId, setFavoriId, secilenKlasor, setSecilenKlasor, digerKlasorRef, sorguAdiRef,
        sorguKararAdi, setSorguKararAdi, yeniKararBilgi, setYeniKararBilgi, digerKlasor, setDigerKlasor, disabledbtnFavori, setDisabledbtnFavori,
        displaySorguFavoriEkleDialog, setDisplaySorguFavoriEkleDialog, repoKlasor, setRepoKlasor, sorguFavoriEkleDialogHeader, setSorguFavoriEkleDialogHeader, sorguMenu, setSorguMenu,
        getKlasors, updateKlasorAdi, updateSorguFavoriAdi, yeniKlasorEkle, silComponent, sorguEkle, yeniSorgu, sorguFavoriKaydet, getSorguById, getFavoriById, favorilereEkle, onHide
    ]);

    return (
        <SorguContext.Provider value={values}>
            {children}
        </SorguContext.Provider>
    )

}

export const useSorgu = () => {
    const context = useContext(SorguContext);

    if (context === undefined) {
        throw new Error("must be call inside KararAramaContextProvider");
    }

    return context;
};