import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useLoginToken } from "../../contexts/LoginTokenContext";
import { logout } from "../../actions/authAction";
import { connect } from "react-redux";
import Profil from '../../pages/kullanici/profil';
import { useYonetimPaneli } from '../../contexts/YonetimPaneliContext';
import OneriSikayet from '../../pages/oneriSikayet';
import '../../css/custom.css';
import Yardim from '../../pages/yardim';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
    const { kullanici, setKullanici, setRefSorguId } = useLoginToken();
    const { setUyeId } = useYonetimPaneli();
    const [displayProfil, setDisplayProfil] = useState(false);
    const [displayOneriSikayet, setDisplayOneriSikayet] = useState(false);
    const [profil, setProfil] = useState(false);
    const [displayYardim, setDisplayYardim] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        if (window.location.search.includes("sorguId") && window.location.search !== "/") {
            setRefSorguId(window.location.search.substring(9));
            localStorage.setItem("sorguId", window.location.search.substring(9));
        }
        if (localStorage.sorguId !== undefined) {
            setRefSorguId(localStorage.sorguId);
        }
        setKullanici(localStorage.kullanici);
        if (localStorage.onayliMi === "false") {
            setUyeId(localStorage.kullaniciId);
            setProfil(true);
        } else if (localStorage.kullanici !== undefined && localStorage.kullanici !== "") {
            navigate("../kararArama");
        }
    }, [kullanici]);

    const onLogout = useCallback(() => {
        props.dispatch(logout());
        window.location = '/';
    },[]);

    const setDisYardim = useCallback(() => {
        setDisplayYardim(true);
    },[]);

    const onOneriSikayet  = useCallback(() => {
        setProfil(true);
        setDisplayOneriSikayet(true);
    },[]);

    const onProfilDetay = useCallback(() => {
        setProfil(true);
        setUyeId(localStorage.kullaniciId);
        setDisplayProfil(true);
    },[]);

    return kullanici != null && (
        <header id="header" className="fixed-top bg-black header header-border-radius" style={{ background: "#FBF8F7" }}>

            <div className="grid header-height" >
                <div className="lg:col-4 md:col-6 sm:col-12 text-left p-3">
                    <span className="p-2 pl-3">
                        <Image src="images/header-logo.png" width='140' height='140' alt="Image Text" className='header-logo' />
                    </span>
                    <span
                        className="absolute p-3 pt-3 pl-1 text-size yargitay-logo-text">
                        <div className="text-cyan-700 w-15rem h-2rem font-bold m-2 flex align-items-center justify-content-center">Yargıtay</div>
                        <div className="text-cyan-700 w-15rem h-1rem font-bold m-2 flex align-items-center justify-content-center">İçtihat Merkezi</div> </span>
                </div>
                <div className="lg:col-8 md:col-6 sm:col-12 text-right pr-5 pt-5  header-button-height ">
                    {kullanici != null &&
                        <React.Fragment>
                            <label><b>{kullanici}</b></label>
                            &nbsp;
                            &nbsp;
                            <div className="tooltip">
                                <Button icon="pi pi-user" className="p-button-rounded p-button-primary button-color" aria-label="User"
                                    onClick={onProfilDetay} />
                                <span className="tooltiptext">Profil</span>
                            </div>
                            &nbsp;
                            <div className="tooltip">
                                <Button icon="pi pi-comments" className="p-button-rounded p-button-primary button-color"
                                    onClick={onOneriSikayet} />
                                <span className="tooltiptext">Öneri</span>
                            </div>
                            &nbsp;
                            <div className="tooltip">
                                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-primary button-color"
                                    onClick={setDisYardim} />
                                <span className="tooltiptext">Yardım</span>

                            </div>
                            &nbsp;
                            <div className="tooltip">
                                <Button icon="pi pi-sign-out" className="p-button-rounded p-button-primary button-color"
                                    onClick={onLogout} />
                                <span className="tooltiptext">Çıkış</span>
                            </div>
                            &nbsp;
                        </React.Fragment>
                    }
                </div>
            </div>
            <Profil profil={profil} displayProfil={displayProfil} setDisplayProfil={setDisplayProfil} />
            <OneriSikayet profil={profil} displayOneriSikayet={displayOneriSikayet} setDisplayOneriSikayet={setDisplayOneriSikayet} />
            <Yardim
                displayYardim={displayYardim}
                setDisplayYardim={setDisplayYardim}
            />
        </header>
    );
};

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(Header);