import axios from "axios";
import { logout } from "../../actions/authAction";
import { toast } from "react-toastify";

export function ErrorInterceptor(props) {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error != null && error.response != null) {
        if (error.response.status === 403) {
          window.location = "/";
          dispatchEvent(logout());
        } else if (error.response.status === 401) {
          let msj = error.response.data.message;
          toast.error(msj, { position: toast.POSITION.TOP_CENTER});
          setTimeout(()=>window.location = "/",(4000)) 
        } else {
          toast.error(error.response.data.message);
        }
      }
      else
        toast.error("Beklenmeyen bir hata oluştu.");
    },
    function onerror(event) {
      console.log(event);
    }
  );
}

export function loadResponseInterceptors() {
}
