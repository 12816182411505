import React, { createContext, useContext, useMemo, useState } from "react";
import { confirmDialog } from 'primereact/confirmdialog';

const PrimeContext = createContext();

export const PrimeProvider = ({ children }) => {

    const [infoMesaj, setInfoMesaj] = useState({});
    const [errorMesaj, setErrorMesaj] = useState({});
    const [warningMesaj, setWarningMesaj] = useState({});

    function publishInfoMesaj(text) {     
        let msg = {severity: 'success', summary: '', detail: text, life: 3000 };
        setInfoMesaj(msg);
    }
    function publishErrorMesaj(text) {    
        let msg = { severity: 'error', summary: '', detail: text, life: 3000 }
        setErrorMesaj(msg);
    }
    function publishWarningMesaj(text) {  
        let msg = { severity: 'info', summary: '', detail: text, life: 3000 }
        setWarningMesaj(msg);
    }   

    function showConfirmDialog(acceptFunction) { 
        return confirmDialog({
                    message: 'Girdiğiniz bilgiler kaydedilecektir. Onaylıyor musunuz?',
                    header: 'Uyarı',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Onayla',
                    rejectLabel: 'Vazgeç',
                    acceptIcon: 'pi pi-check',
                    rejectIcon: 'pi pi-times',
                    accept: acceptFunction,
                    reject: () => {  }
                });
    }       


    const values  = useMemo(() => ({
        infoMesaj,
        errorMesaj,
        warningMesaj,
        publishInfoMesaj,
        publishErrorMesaj,
        publishWarningMesaj,
        showConfirmDialog
    }), [
        infoMesaj,
        errorMesaj,
        warningMesaj,
        publishInfoMesaj,
        publishErrorMesaj,
        publishWarningMesaj,
        showConfirmDialog
    ]);

    return (
        <PrimeContext.Provider value={values}>
            {children}
        </PrimeContext.Provider>
    )

};

export const usePrime = () => {
    const context = useContext(PrimeContext);

    if (context === undefined) {
        throw new Error("must be call inside PrimeProvider");
    }

    return context;
};