import React from "react";
import { Dialog } from 'primereact/dialog';

const PdfViewer = ({ file, displayPdfViewer, setDisplayPdfViewer }) => {

    const onHide = () => {
        setDisplayPdfViewer(false);
    }

    const pdfUrl = file ? URL.createObjectURL(file) : null;

    return (
        <div>
            <Dialog header="Görüntüle" visible={displayPdfViewer} style={{ width: '80vw', height: '100vh' }} onHide={() => onHide()}>
                <div className="grid">
                    <div className="col-12 pr-4">
                        <iframe
                            title="PDF Viewer"
                            src={pdfUrl}
                            width="100%"
                            className="h-iframe"
                            style={{ border: 'none' }}
                        />
                    </div>
                </div >
            </Dialog>
        </div >
    );
};

export default PdfViewer;


