import { Card } from "primereact/card";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useRef, useState } from "react";
import YimCalendar from "../../../shared/components/YimCalendar";
import { useKararArama } from "../../../../contexts/KararAramaContext";
import { useVeriDoldurma } from "../../../../contexts/VeriDoldurmaContext";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useDavaSuc } from "../../../../contexts/DavaSucContext";
import { Toast } from 'primereact/toast';


const DavaSucBilgileri = () => {

    const [windowWidth] = useState(window.innerWidth);
    const { sucBitKararTarihi, disabledHukuk, disabledCeza, showDavaTuruDropdown, sucTuru, setSucTuru, davaTuru, setDavaTuru,
        sucTuruLabel, davaTuruLabel, kanunMaddesi, setKanunMaddesi, setSucBasKararTarihi, setSucBitKararTarihi, sucBasKararTarihi, sucTuruRef,
        davaTuruRef, ilgiliKararAramaRef, kanunMaddeRef, kanunFikraRef, kanunBendRef, showSucTuruDropdown, mevzuatlar, turSecildiMi, setTurSecildiMi,
        selectedMevzuat, setSelectedMevzuat, davaSucHandleInput, inputStyles
    } = useDavaSuc();
    const { setDisabledbtnSorgu, handleKeyPress, isLoading, setIsLoading, updateField } = useKararArama();
    const { repoSucTuru, repoDavaTuru, repoMevzuatTuru } = useVeriDoldurma();
    const [sonDegisiklik, setSonDegisiklik] = useState("");
    const toast = useRef(null);

    useEffect(() => {
        if (sucBasKararTarihi && sucBitKararTarihi && sucBasKararTarihi > sucBitKararTarihi) {
            if (sonDegisiklik == "sucBasKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Başlangıç tarihi bitiş tarihinden büyük olamaz!', life: 5000 });
                setSucBasKararTarihi(null);
            }
            if (sonDegisiklik == "sucBitKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Bitiş tarihi başlangıç tarihinden küçük olamaz!', life: 5000 });
                setSucBitKararTarihi(null);
            }
        }
    }, [sucBasKararTarihi, sucBitKararTarihi]);


    function updateKanunMaddesiByField(field, val) {
        if (val != null) {
            setTurSecildiMi(true);
        }
        let newKanunMaddesi = JSON.parse(JSON.stringify(kanunMaddesi));
        newKanunMaddesi[field] = val;
        setKanunMaddesi(newKanunMaddesi);
    }

    const selectedMevzuatTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{turkishToUpper(option.mevzuatArama)}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };


    function turkishToUpper(word) {
        let string = word;
        let letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
        string = string.replace(/(([iışğüçö]))/g, function (letter) { return letters[letter]; })
        return string.toUpperCase();
    }

    const mevzuatOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{turkishToUpper(option.mevzuatArama)}</div>
            </div>
        );
    };

    return (
        <Card className='lg:col-4 md:col-4 sm:col-12 mahkeme-card-color' tabIndex="3">
            <Toast ref={toast} position="top-center" />
            <div className="font-bold text-white bg-bluegray-500 p-3" style={{ marginTop: "-6px" }}>
                DAVA KONUSU VE SUÇ TÜRÜ BİLGİLERİ
            </div>
            <div className='col-12'>
                <span className="p-float-label tooltipSorguForm">
                    <MultiSelect id='sucTuru' value={sucTuru} disabled={disabledHukuk}
                        options={repoSucTuru} onChange={(e) => {
                            updateField(e, 'sucTuru');
                            setSucTuru(e.value);
                            setDisabledbtnSorgu(false);
                        }} optionLabel="label" filter ref={sucTuruRef} overlayVisible={showSucTuruDropdown} resetFilterOnHide="true"
                        maxSelectedLabels={2} filterLocale="tr" virtualScrollerOptions={{ itemSize: 43 }}
                        emptyFilterMessage="Kullanılabilir seçenek yok"
                        selectedItemsLabel="{0} öğe seçildi"
                        className="w-full max-width" />
                    <div className={(sucTuru != null && sucTuruLabel.length > 0) ? "tooltiptextSorguForm" : "tooltiptextSorguFormHidden"}>{sucTuruLabel} </div>
                    <label htmlFor="sucTuru">Suç Türü</label>
                </span>
            </div>
            <div className='col-12'>
                <span className="p-float-label tooltipSorguForm">
                    <MultiSelect id='davaTur' value={davaTuru} disabled={disabledCeza}
                        options={repoDavaTuru} onChange={(e) => {
                            updateField(e, 'davaTuru');
                            setDavaTuru(e.value);
                            setDisabledbtnSorgu(false);
                        }} filterLocale="tr" optionLabel="label" filter ref={davaTuruRef}
                        selectedItemsLabel="{0} öğe seçildi"
                        emptyFilterMessage="Kullanılabilir seçenek yok" virtualScrollerOptions={{ itemSize: 43 }}
                        overlayVisible={showDavaTuruDropdown} resetFilterOnHide="true"
                        className="w-full max-width" maxSelectedLabels={2} />
                    <div className={(davaTuru != null && davaTuruLabel.length > 0) ? "tooltiptextSorguForm" : "tooltiptextSorguFormHidden"}>{davaTuruLabel} </div>
                    <label htmlFor="davaTur">Dava Konusu</label>
                </span>
            </div>
            <div className='col-12'>
                <span className="p-float-label tooltipSorguForm">
                    <Dropdown id="kanunTuru"
                        value={kanunMaddesi.turu}
                        options={repoMevzuatTuru} placeholder="Mevzuat Türü Seçiniz..." className="w-full max-width"
                        onChange={(e) => {
                            setIsLoading(true);
                            updateKanunMaddesiByField('turu', e.value);
                            setDisabledbtnSorgu(false);
                        }} />
                    <label htmlFor="turu">Mevzuat Türü Seçiniz</label>
                </span>
            </div>
            <div className='col-12'>
                <span className="p-float-label">
                    {isLoading ? <div className='yapay-zeka-spinner fa fa-spinner fa-spin'></div> :
                        <Dropdown value={selectedMevzuat}
                            disabled={!turSecildiMi}
                            onChange={(e) => {
                                kanunMaddesi.no = e.value.mevzuatNo;
                                kanunMaddesi.mevzuatarama = e.value.mevzuatArama;
                                kanunMaddesi.mevzuat_id = e.value.id;
                                let newKanunMaddesi = kanunMaddesi;
                                setKanunMaddesi(newKanunMaddesi);
                                setSelectedMevzuat(e.value);
                                setDisabledbtnSorgu(false);
                            }} virtualScrollerOptions={{ itemSize: 43 }}
                            emptyFilterMessage="Kullanılabilir seçenek yok"
                            options={mevzuatlar.filter(x => x.mevzuatTurUuid == kanunMaddesi.turu)} optionLabel={"mevzuatArama"} placeholder="Mevzuat Seçin"
                            filter filterLocale='tr' valueTemplate={selectedMevzuatTemplate} itemTemplate={mevzuatOptionTemplate} className="w-full max-width" />}
                    {!isLoading && <label htmlFor="turu" style={{ color: !turSecildiMi ? "lightgrey" : "grey" }}>Mevzuat Adı</label>}
                </span>
            </div>
            <div className='col-12'>
                <div className='grid'>
                    <div className='col-4'>
                        <span className="p-float-label">
                            <InputText id="kanunMadde" ref={kanunMaddeRef}
                                onInput={() => {
                                    if (kanunMaddeRef.current !== undefined) {
                                        davaSucHandleInput(kanunMaddeRef.current.value, "kanunMadde");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} onKeyPress={handleKeyPress} className="w-full" />
                            <label htmlFor="kanunMadde" style={inputStyles.kanunMadde}>Madde</label>
                        </span>
                    </div>
                    <div className="filter-transform-line"></div>
                    <div className='col-4'>
                        <span className="p-float-label">
                            <InputText id="kanunFikra" ref={kanunFikraRef}
                                onInput={() => {
                                    if (kanunFikraRef.current !== undefined) {
                                        davaSucHandleInput(kanunFikraRef.current.value, "kanunFikra");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} onKeyPress={handleKeyPress} className="w-full" />
                            <label htmlFor="kanunFikra" style={inputStyles.kanunFikra}>Fıkra</label>
                        </span>
                    </div>
                    <div className='col-3' style={{ width: "28%" }}>
                        <span className="p-float-label">
                            <InputText id="kanunBend" ref={kanunBendRef}
                                onInput={() => {
                                    if (kanunBendRef.current !== undefined) {
                                        davaSucHandleInput(kanunBendRef.current.value, "kanunBend");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} onKeyPress={handleKeyPress} className="w-full" />
                            <label htmlFor="kanunBend" style={inputStyles.kanunBend}>Bent</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-12" style={{ marginTop: "-8px" }}>
                <div className="grid">
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="sucTarihiBas" value={sucBasKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setSucBasKararTarihi(e);
                                    setSonDegisiklik("sucBasKararTarihi");
                                }}
                                label={windowWidth > 1500 ? "Suç Başlangıç Tarihi" : "Suç Baş.Tarihi"}
                                maxDate={sucBitKararTarihi}
                                viewDate={sucBasKararTarihi || sucBitKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="sucTarihiBit" value={sucBitKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setSucBitKararTarihi(e);
                                    setSonDegisiklik("sucBitKararTarihi");
                                }}
                                label={windowWidth > 1500 ? "Suç Bitiş Tarihi" : "Suç Bit.Tarihi"}
                                minDate={sucBasKararTarihi}
                                viewDate={sucBitKararTarihi || sucBasKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <span className="p-float-label p-input-icon-left block">
                    <i className="pi pi-search" />
                    <InputText id="kanunMevzuatInput" ref={ilgiliKararAramaRef} onInput={() => {
                        setDisabledbtnSorgu(false);
                        if (ilgiliKararAramaRef.current !== undefined)
                            davaSucHandleInput(ilgiliKararAramaRef.current.value, "ilgiliKararArama")
                    }}
                        className="w-full" onKeyPress={handleKeyPress} />
                    <label htmlFor="kanunMevzuatInput" style={inputStyles.ilgiliKararArama}>İlgili Karar Arama</label>
                </span>
            </div>
            <div className="font-bold text-white bg-bluegray-500  p-2 " style={{ marginTop: "0px" }}>
            </div>
        </Card>
    )
}

export default DavaSucBilgileri;