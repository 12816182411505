export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const logLevel = {
    success: 'success',
    info: 'info',
    error: 'error',
    warn: 'warn'
};

const showError = (text) => {
    return {
        type: SHOW_ALERT,
        level: logLevel.error,
        title: 'kadir',
        text
    }
};

const toast = {
    error: (text) => {
        return {
            type: SHOW_TOAST,
            level: logLevel.error,
            text
        }
    },
    info: (text) => {
        return {
            type: SHOW_TOAST,
            level: logLevel.info,
            text
        }
    },
    success: (text) => {
        return {
            type: SHOW_TOAST,
            level: logLevel.success,
            text
        }
    },
    warn: (text) => {
        return {
            type: SHOW_TOAST,
            level: logLevel.warn,
            text
        }
    }
};


export const systemAlert = {
    showError,
    toast
};  