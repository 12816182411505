import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../index.css";
import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { useNavigate } from "react-router-dom";
import { Tree } from 'primereact/tree';
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from 'primereact/toast';
import { useKararArama } from "../../contexts/KararAramaContext";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import SorguPopup from "./sorguPopup";
import { VeriDoldurmaService } from "../../services/VeriDoldurmaService";
import { MultiSelect } from "primereact/multiselect";
import { useVeriDoldurma } from "../../contexts/VeriDoldurmaContext";
import { useLoginToken } from "../../contexts/LoginTokenContext";
import { AramaInfoService } from "../../services/AramaInfoService";
import { useSorgu } from "../../contexts/SorguContext";


const Sorgu = () => {
  useEffect(() => {
    loadRepos();
  }, [])

  const veriDoldurmaService = new VeriDoldurmaService();
  const aramaInfoService = new AramaInfoService();
  const [selectedKey, setSelectedKeys] = useState();
  const [dialogHeader, setDialogHeader] = useState();
  const [value, setValue] = useState();
  const [text, setText] = useState();
  const [bagliKlasorId, setBagliKlasorId] = useState('');
  const [type, setType] = useState();
  const [displayKlasorSorgu, setDisplayKlasorSorgu] = useState();
  const [aramaInfoVisible, setAramaInfoVisible] = useState(false);
  const [klasorMenu, setKlasorMenu] = useState(false);
  const [yeni, setYeni] = useState(false);
  const { ilkGiris, setIlkGiris } = useLoginToken();
  const { sorguNodes, setDisplaySorguFavoriEkleDialog, setRepoKlasor, sorguFavoriEkleDialogHeader, setSorguFavoriEkleDialogHeader, setSorguMenu, getKlasors,
    updateKlasorAdi, updateSorguFavoriAdi, yeniKlasorEkle, silComponent, getSorguById, getFavoriById, } = useSorgu();
  const { hukukiKavram, setHukukiKavram, hukukiKonu, setSorgulama, setHukukiKonu, hukukiKavramLabel, setHukukiKavramLabel, hukukiKonularLabel, setHukukiKonularLabel,
    setSerbestAra, setDisabledbtnSorgu, setAraBtnDisable, araBtnDisable, listKararAramaGridByFiltre, setExpanded, anahtarKelime, setAnahtarKelimeler, anahtarKelimeLabel,
    setAnahtarKelimeLabel, filtreTemizle, input, setCurrentPage, currentPage, setSon10Karar, son10Karar, setSonucGizle, datatable } = useKararArama();
  const { repoHukukiKavram, repoHukukiKonu, setRepoHukukiKavram, setRepoHukukiKonu, loadRepos, getHukukiKavram, getHukukiKonu, repoAnahtarKelime, getAnahtarKelime,
    setRepoAnahtarKelime } = useVeriDoldurma();
  const toast = useRef(null);
  const cm = useRef(null);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [menu, setMenu] = useState(null);
  const hukukiKavramRef = useRef(null);
  const [showHukukiKavramDropdown, setShowHukukiKavramDropdown] = useState(false);
  const [controlField, setControlField] = useState('');
  const hukukiKonuRef = useRef(null);
  const [showHukukiKonuDropdown, setShowHukukiKonuDropdown] = useState(false);
  const [prevHukukiKavramSelection, setPrevHukukiKavramSelection] = useState([]);
  const [prevHukukiKonuSelection, setPrevHukukiKonuSelection] = useState([]);
  const anahtarKelimeRef = useRef(null);
  const [showAnahtarKelimeDropdown, setShowAnahtarKelimeDropdown] = useState(false);
  const [prevAnahtarKelimeSelection, setPrevAnahtarKelimeSelection] = useState([]);
  let adi = "";
  let valueId = "";
  let nodeAdi = "";
  let typeSil = "";
  let dosyaTip = "";

  let navigate = useNavigate();
  const buttonRef = useRef();
  const [aramaInfoMetni, setAramaInfoMetni] = useState("");
  const [expandedKeys, setExpandedKeys] = useState({});

  useEffect(() => {
    let timer;
    if (controlField != undefined && controlField == "hukukiKavram") {
      if (showHukukiKavramDropdown) {
        timer = setTimeout(() => {
          setShowHukukiKavramDropdown(false);
        }, 1);
      }
      return () => {
        clearTimeout(timer);
      };
    }

    if (controlField != undefined && controlField == "hukukiKonu") {
      if (showHukukiKonuDropdown) {
        timer = setTimeout(() => {
          setShowHukukiKonuDropdown(false);
        }, 1);
      }
      return () => {
        clearTimeout(timer);
      };
    }
    if (controlField != undefined && controlField == "anahtarKelime") {
      if (showAnahtarKelimeDropdown) {
        timer = setTimeout(() => {
          setShowAnahtarKelimeDropdown(false);
        }, 1);
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showHukukiKavramDropdown, showHukukiKonuDropdown, showAnahtarKelimeDropdown]);


  useEffect(() => {
    if (ilkGiris) {
      navigate("../kararArama");
      getKlasors();
      setIlkGiris(false);
    }
  }, [ilkGiris]);


  useEffect(() => {
    if (controlField != undefined && controlField == "hukukiKavram") {
      if (hukukiKavramLabel.length > 0) {
        hukukiKavramRef.current.props.overlayVisible = true;
      }
    }

    if (controlField != undefined && controlField == "hukukiKonu") {
      if (hukukiKonularLabel.length > 0) {
        hukukiKonuRef.current.props.overlayVisible = true;
      }
    }

    if (controlField != undefined && controlField == "anahtarKelime") {
      if (anahtarKelimeLabel.length > 0) {
        anahtarKelimeRef.current.props.overlayVisible = true;
      }
    }
  }, [showHukukiKavramDropdown, showHukukiKonuDropdown, showAnahtarKelimeDropdown]);

  const sortedHukukiKavram = (e) => {
    if (e.value.length < prevHukukiKavramSelection.length) {
      getHukukiKavram(function (rest) {
        if (rest) {
          getHukukiKavramLabel(e);
        }
      })
    }
    else {
      getHukukiKavramLabel(e);
    }
  }

  const sortedHukukiKonu = (e) => {
    if (e.value.length < prevHukukiKonuSelection.length) {
      getHukukiKonu(function (rest) {
        if (rest) {
          getHukukiKonularLabel(e);
        }
      })
    }
    else {
      getHukukiKonularLabel(e);
    }
  }

  const sortedAnahtarKelime = (e) => {
    if (e.value.length < prevAnahtarKelimeSelection.length) {
      getAnahtarKelime(function (rest) {
        if (rest) {
          getAnahtarKelimeLabel(e);
        }
      })
    }
    else {
      getAnahtarKelimeLabel(e);
    }
  }

  const getHukukiKavramLabel = (e) => {
    setPrevHukukiKavramSelection(e.value);
    setRepoHukukiKavram((prevOptions) => {
      const newOptions = [...prevOptions];
      e.value.forEach((value, index) => {
        const itemIndex = prevOptions.findIndex((item) => item.value === value);
        const [item] = newOptions.splice(itemIndex, 1);
        newOptions.splice(index, 0, item);
      });
      return newOptions;
    });
    let hukukiKavramLabel = [];
    hukukiKavramLabel = repoHukukiKavram.filter(x => e.value.includes(x.value));
    hukukiKavramLabel = hukukiKavramLabel.map(x => <> &ensp;* {x.label}<br /></>);
    setHukukiKavramLabel(hukukiKavramLabel);
  }

  const getHukukiKonularLabel = (e) => {
    setPrevHukukiKonuSelection(e.value);
    setRepoHukukiKonu((prevOptions) => {
      const newOptions = [...prevOptions];
      e.value.forEach((value, index) => {
        const itemIndex = prevOptions.findIndex((item) => item.value === value);
        const [item] = newOptions.splice(itemIndex, 1);
        newOptions.splice(index, 0, item);
      });
      return newOptions;
    });
    let hukukiKonularLabel = [];
    hukukiKonularLabel = repoHukukiKonu.filter(x => e.value.includes(x.value));
    hukukiKonularLabel = hukukiKonularLabel.map(x => <> &ensp;* {x.label}<br /></>);
    setHukukiKonularLabel(hukukiKonularLabel);
  }


  const getAnahtarKelimeLabel = (e) => {
    setPrevAnahtarKelimeSelection(e.value);
    setRepoAnahtarKelime((prevOptions) => {
      const newOptions = [...prevOptions];
      e.value.forEach((value, index) => {
        const itemIndex = prevOptions.findIndex((item) => item.value === value);
        const [item] = newOptions.splice(itemIndex, 1);
        newOptions.splice(index, 0, item);
      });
      return newOptions;
    });
    let anahtarKelimeLabel = [];
    anahtarKelimeLabel = repoAnahtarKelime.filter(x => e.value.includes(x.value));
    anahtarKelimeLabel = anahtarKelimeLabel.map(x => <> &ensp;* {x.label}<br /></>);
    setAnahtarKelimeLabel(anahtarKelimeLabel);
  }


  const sorgula = (sonKarar10) => {
    if (datatable.current) {
      datatable.current.resetScroll();
    }
    if (input.current.value.length < 3 && input.current && input.current.value !== "") {
      toast.current.show({ severity: 'error', summary: '', detail: 'Arama alanına en az 3 karakter girerek arama yapınız.', life: 5000 });
      setAraBtnDisable(false);
    }
    else {
      setSorgulama(true);
      if (currentPage !== 0 || (sonKarar10 != son10Karar)) {
        setCurrentPage(0);
        setSon10Karar(sonKarar10);
      } else {
        listKararAramaGridByFiltre();
      }
    }
  };

  const updateField = (e, field) => {
    setControlField(field);
    if (field == "hukukiKavram") {
      setTimeout(() => {
        sortedHukukiKavram(e);
        setShowHukukiKavramDropdown(true);
      }, 1);
    }
    if (field == "hukukiKonu") {
      setTimeout(() => {
        sortedHukukiKonu(e);
        setShowHukukiKonuDropdown(true);
      }, 1);
    }
    if (field == "anahtarKelime") {
      setTimeout(() => {
        sortedAnahtarKelime(e);
        setShowAnahtarKelimeDropdown(true);
      }, 1);
    }
  }


  const onNodeSelect = (node) => {

    let _expandedKeys = { ...expandedKeys };
    if (_expandedKeys[node.node.key]) {
      delete _expandedKeys[node.node.key];
    } else {
      _expandedKeys[node.node.key] = true;
    }
    setExpandedKeys(_expandedKeys);

    if (node.node.label == "Klasörler") {
      navigate("../kararArama");
      getKlasors();
    }
    else if (node.node.key.length > 3) {
      navigate("../kararArama");
      if (node.node.key.substr(2, 1) == "0") {
        if (node.node.key.length > 5 && !node.node.icon.includes("folder")) {
          getSorguById(node.node.value);
          setSorguMenu(true);
          setExpanded(true);
        }
      } else if (node.node.key.substr(2, 1) == "1") {
        if (node.node.key.length > 5 && !node.node.icon.includes("folder")) {
          getFavoriById(node.node.value);
        }

        setSorguMenu(false);
      }

    }
  }

  function menuBelirle(nodeKey, folder, klasorId) {
    if (nodeKey.length == 5 || folder) {
      adi = "klasor";
      setKlasorMenu(true);
    } else {
      setKlasorMenu(false);
      adi = "";
    }
    if (nodeKey.length == 3) {
      setMenu(contextKlasor);
      setBagliKlasorId(null);
    } else if (nodeKey.length > 3) {
      folder && (nodeKey.length === 5 || nodeKey.length === 7) ? setMenu(contextDosya) : setMenu(context);
      if (folder) setBagliKlasorId(klasorId)
      else setBagliKlasorId(null);
      if (nodeKey.substr(2, 1) == "0") {
        dosyaTip = "sorgu";
      } else if (nodeKey.substr(2, 1) == "1") {
        dosyaTip = "favori";
      }
    }
  }

  function adiniDegistir() {
    let params = {
      id: value,
      adi: text,
      favoriMi: type == 'favori' ? true : false
    }

    if (klasorMenu) {
      updateKlasorAdi(params);
      toast.current.show({ severity: 'success', summary: '', detail: 'Klasör İsmi Değiştirildi!', life: 5000 });
    } else {
      updateSorguFavoriAdi(params);
      toast.current.show({ severity: 'success', summary: '', detail: 'İsim Değiştirildi!', life: 5000 });
    }
    setDisplayKlasorSorgu(false);
  }

  function sil() {
    let params = {
      id: valueId,
      adi: adi === '' ? typeSil : "klasor",
      favoriMi: typeSil == 'favori' ? true : false
    }
    silComponent(params, function (resp) {
      if (resp) {
        toast.current.show({ severity: 'success', summary: '', detail: 'Silme İşlemi Başarılı!', life: 5000 });
      }

    });


  }

  const onClickSil = () => {
    let message = (typeSil === "sorgu" || typeSil === "favori") && adi === '' ? 'Seçtiğiniz ' + typeSil + ' kaydı silinecektir. Onaylıyor musunuz?' : 'Seçtiğiniz bilgilere ait alt bilgiler de silinecektir. Onaylıyor musunuz?';
    confirmDialog({
      message: message,
      header: 'Uyarı',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Onayla',
      rejectLabel: 'Vazgeç',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: () => sil(),
      reject: () => {}
    });
  }



  const duzenle = () => {
    let favori = dosyaTip == 'favori' ? true : false
    veriDoldurmaService.getKlasor(favori).then((data) => {
      if (data) {
        setRepoKlasor(data);
      }
      if (adi === "klasor") {
        setSorguMenu(false);
        setDisplayKlasorSorgu(true);
        setText(nodeAdi);
      }
      if (!favori) {
        setSorguMenu(true);
        getSorguById(valueId, function (resp) {
          if (resp) {
            setDisplaySorguFavoriEkleDialog(true);
          }
        });
      }
      else {
        setSorguMenu(false);
        setDisplayKlasorSorgu(true);
        setText(nodeAdi);
      }
    });
  }


  function kaydet() {
    if (yeni) {
      klasorEkle();
    } else {
      adiniDegistir()
    }

  }

  const contextKlasor = [
    {
      label: 'Yeni Klasör Ekle',
      icon: 'pi pi-plus',
      command: () => {
        setDialogHeader("Klasör Ekle");
        setYeni(true);
        setValue("");
        setText("");
        setDisplayKlasorSorgu(true);
      }
    }
  ];

  const contextDosya = [
    {
      label: 'Yeni Klasör Ekle',
      icon: 'pi pi-plus',
      command: () => {
        setDialogHeader("Klasör Ekle");
        setYeni(true);
        setValue("");
        setText("");
        setDisplayKlasorSorgu(true);
      }
    },
    {
      label: 'Düzenle',
      icon: 'pi pi-pencil',
      command: (e) => {
        setDialogHeader("Düzenle");
        setYeni(false);
        duzenle();
      }
    },
    {
      label: 'Sil',
      icon: 'pi pi-trash',
      command: (e) => {
        setYeni(false);
        onClickSil();
      }
    }
  ];

  const context = [
    {
      label: 'Düzenle',
      icon: 'pi pi-pencil',
      command: (e) => {
        setDialogHeader("Düzenle");
        setYeni(false);
        duzenle();
      }
    },
    {
      label: 'Sil',
      icon: 'pi pi-trash',
      command: () => {
        setYeni(false);
        onClickSil();
      }
    }
  ];

  function klasorEkle() {
    let params = {
      id: null,
      adi: text,
      favoriMi: type == 'favori' ? true : false,
      bagliKlasorId: bagliKlasorId
    }
    yeniKlasorEkle(params, function (resp) {
      if (resp) {
        toast.current.show({ severity: 'success', summary: '', detail: 'Yeni Klasör Eklendi!', life: 5000 });
        setDisplayKlasorSorgu(false);
      }
    });

  }

  const renderFooter = () => {
    return (<div>
      <Button label="Kaydet" className="button-color" icon="pi pi-save" onClick={kaydet} />
    </div>)
  }

  const inputChange = (e) => {
    const value = e;
    const delay = 300;
    if (input.current.typingTimeout) {
      clearTimeout(input.current.typingTimeout);
    }
    input.current.typingTimeout = setTimeout(() => {
      setSerbestAra(value);
    }, delay);
  };

  const handleEnter = (e) => {
    if (e.target.value !== "" && e.target.value.length < 3) {
      toast.current.show({ severity: 'error', summary: '', detail: 'Arama alanına en az 3 karakter girerek arama yapınız!', life: 5000 });
    }
    else {
      setSerbestAra(e.target.value)
      buttonRef.current.focus();
    }
  }

  const aramaInfo = () => {
    aramaInfoService.getAramaInfo().then((data) => {
      if (data) {
        setAramaInfoMetni(data[0].info);
        setAramaInfoVisible(true);
      }
    });
  }

  return (
    <div id="genelArama">
      <Card style={{ background: "#ebe7e6" }} title={<div className='grid flex col-12' id="aramaInfo">
        <div className="col-9 text-left">
          <span className='ml-2 mt-1 text-left'>Genel Arama</span>
        </div>
        <div className="col-3 text-right tooltip">
          <span className="tooltiptextInfo">{"Arama Kuralları"}</span>
          <Button className="ml-1" style={{ backgroundColor: "#e87f06" }} icon="pi pi-info-circle" iconPos="right" id="info-arama" onClick={() => aramaInfo()} />
        </div>

      </div>}>
        <Toast ref={toast} position="top-center" />
        <ContextMenu model={menu} ref={cm} />
        <ConfirmDialog />
        <div className="grid">
          <div className="col-12" id="sorgu">
            <div className="col-12">
              <InputText id="serbestAra" autoFocus={true} ref={input} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEnter(e);
                }
              }}
                onInput={() => {
                  if (input.current !== undefined) {
                    inputChange(input.current.value);
                  }
                  setDisabledbtnSorgu(false)
                }}
                placeholder="Ara(En az 3 karakter giriniz)"
                className="w-full" />
            </div>
            <div className="col-12">
              <span className="p-float-label tooltipSorguForm">
                <MultiSelect id='anahtarKelime' value={anahtarKelime}
                  options={repoAnahtarKelime}
                  virtualScrollerOptions={{ itemSize: 43 }}
                  emptyFilterMessage="Kullanılabilir seçenek yok"
                  maxSelectedLabels={3}
                  selectedItemsLabel="{0} öğe seçildi"
                  onChange={(e) => { updateField(e, 'anahtarKelime'); setAnahtarKelimeler(e.value); setDisabledbtnSorgu(false); }} optionLabel="label" ref={anahtarKelimeRef} overlayVisible={showAnahtarKelimeDropdown}
                  filter filterLocale="tr" className="w-full" resetFilterOnHide={true} />
                <div className={(anahtarKelime != null && anahtarKelimeLabel.length > 0) ? "tooltiptextSorgu" : "tooltiptextSorguHidden"}>{anahtarKelimeLabel} </div>
                <label htmlFor="anahtarKelime">Anahtar Kelimeler</label>
              </span>
            </div>
            <div className="col-12">
              <span className="p-float-label tooltipSorguForm">
                <MultiSelect id='hukukiKavram' value={hukukiKavram}
                  options={repoHukukiKavram}
                  virtualScrollerOptions={{ itemSize: 43 }}
                  maxSelectedLabels={3}
                  emptyFilterMessage="Kullanılabilir seçenek yok"
                  selectedItemsLabel="{0} öğe seçildi"
                  onChange={(e) => { updateField(e, 'hukukiKavram'); setHukukiKavram(e.value); setDisabledbtnSorgu(false); }} optionLabel="label" ref={hukukiKavramRef} overlayVisible={showHukukiKavramDropdown}
                  filter filterLocale="tr" className="w-full" resetFilterOnHide={true} />
                <div className={(hukukiKavram != null && hukukiKavramLabel.length > 0) ? "tooltiptextSorgu" : "tooltiptextSorguHidden"}>{hukukiKavramLabel} </div>
                <label htmlFor="hukuki">Hukuki Kavramlar</label>
              </span>
            </div>
            <div className="col-12">
              <span className="p-float-label tooltipSorguForm">
                <MultiSelect id='hukukiKonu' value={hukukiKonu}
                  options={repoHukukiKonu}
                  virtualScrollerOptions={{ itemSize: 43 }}
                  maxSelectedLabels={3}
                  emptyFilterMessage="Kullanılabilir seçenek yok"
                  selectedItemsLabel="{0} öğe seçildi"
                  onChange={(e) => { updateField(e, 'hukukiKonu'); setHukukiKonu(e.value); setDisabledbtnSorgu(false); }} optionLabel="label"
                  filter filterLocale="tr" className="w-full" resetFilterOnHide={true} ref={hukukiKonuRef} overlayVisible={showHukukiKonuDropdown} />
                <div className={(hukukiKonu != null && hukukiKonularLabel.length > 0) ? "tooltiptextSorgu" : "tooltiptextSorguHidden"}>{hukukiKonularLabel} </div>
                <label htmlFor="hukuki">Hukuki Konular</label>
              </span>
            </div>

            <div className="col-12 mt-4" >
              <div className="grid">
                <div className="text-left col-6">
                  <Button className="temizle-button" label="Temizle" icon="pi pi-trash" iconPos="right" onClick={() => { filtreTemizle(); setSorguMenu(false); }} />
                </div>
                <div className="text-right col-6">
                  <Button ref={buttonRef} className="ara-button" disabled={araBtnDisable} label="Ara" icon="pi pi-search" iconPos="right" id="ara-sorgu" onClick={() => {
                    setAraBtnDisable(true);
                    setSonucGizle(false);
                    sorgula(false);
                  }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card title="Klasörlerim" style={{ marginTop: "10px", background: "#ebe7e6" }}>
        <Toast ref={toast} position="top-center" />
        <ContextMenu model={menu} ref={cm} onHide={() => setSelectedNodeKey(null)} />
        <div className="grid">
          <div className="col-12">
            <Tree value={sorguNodes} selectionMode="single" filter filterMode="strict"
              selectionKeys={selectedKey}
              onSelectionChange={(e) => setSelectedKeys(e.value)}
              onContextMenuSelectionChange={event => setSelectedNodeKey(event.value)}
              onContextMenu={event => {
                if (event.node.key.length > 3) {
                  valueId = event.node.value;
                  setValue(event.node.value);
                  nodeAdi = event.node.label;
                }
                if (event.node.key.length > 1) {
                  if (event.node.key.substr(2, 1) == '0') {
                    setType("sorgu");
                    typeSil = "sorgu";
                    setSorguFavoriEkleDialogHeader("Sorguyu Düzenle");
                  } else if (event.node.key.substr(2, 1) == '1') {
                    setType("favori");
                    typeSil = "favori";
                    setSorguFavoriEkleDialogHeader("Favoriyi Düzenle");
                  }
                  cm.current.show(event.originalEvent);
                  menuBelirle(event.node.key, event.node.icon.includes("folder"), event.node.value);
                }
              }}
              onSelect={onNodeSelect} expandedKeys={expandedKeys}
              onToggle={e => setExpandedKeys(e.value)}></Tree>
          </div>
        </div>

        <Dialog header={dialogHeader} visible={displayKlasorSorgu} className="width-height" footer={renderFooter()} onHide={() => setDisplayKlasorSorgu(false)}>
          <div className="card">
            <div className="grid">
              <div className="col-12">
                <InputText id="value" value={text} onChange={(e) => setText(e.target.value)} className="w-full" />
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header={"Arama Kuralları"} className="arama-info" visible={aramaInfoVisible} onHide={() => setAramaInfoVisible(false)}>
          <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: aramaInfoMetni }} />
          </div>
        </Dialog>
        <SorguPopup sorguFavoriEkleDialogHeader={sorguFavoriEkleDialogHeader} setDisplaySorguFavoriEkleDialog={setDisplaySorguFavoriEkleDialog} />
      </Card>
    </div >

  );
};

export default Sorgu;
