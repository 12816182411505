import { Card } from "primereact/card";
import { useKararArama } from "../../../../contexts/KararAramaContext";
import { MultiSelect } from "primereact/multiselect";
import { useVeriDoldurma } from "../../../../contexts/VeriDoldurmaContext";
import { InputText } from "primereact/inputtext";
import YimCalendar from "../../../shared/components/YimCalendar";
import { useRef, useState, useEffect } from "react";
import { useYargitayBilgileri } from "../../../../contexts/YargitayBilgileriContext";
import { Toast } from 'primereact/toast';

const YargitayBilgileri = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { yargHandleInput, esasNoBasYiliRef, esasNoBitYiliRef, kararOrganlariRef, esasNoBaslangicRef, esasNoBitisRef, kararNoBasYiliRef,
        kararNoBaslangicRef, kararNoBitYiliRef, kararNoBitisRef, kararOrganlariLabel, setYargitayBasKararTarihi, setYargitayBitKararTarihi,
        yargitayBasKararTarihi, yargitayBitKararTarihi, kararTuruLabel, yimKararTuru, setYimKararTurleri, setDaire, autoTab, daire,
        showKararOrganlariDropdown, pasifAlanlariBelirle, inputStyles } = useYargitayBilgileri();
    const { setDisabledbtnSorgu, updateField, handleKeyPress } = useKararArama();
    const { repoDaire, repoYimKararTuru } = useVeriDoldurma();
    const [basYil, setBasYil] = useState(window.innerWidth > 1500 ? "Başlangıç Yılı" : "Baş.Yılı");
    const [bitYil, setBitYil] = useState(window.innerWidth > 1500 ? "Bitiş Yılı" : "Bit.Yılı");
    const [basNo, setBasNo] = useState(window.innerWidth > 1500 ? "Başlangıç No" : "Baş.No");
    const [bitNo, setBitNo] = useState(window.innerWidth > 1500 ? "Bitiş No" : "Bit.No");
    const [sonDegisiklik, setSonDegisiklik] = useState("");
    const toast = useRef(null);

    useEffect(() => {
        const handleWindowResize = () => {
            const basNo = window.innerWidth > 1500 ? "Başlangıç No" : "Baş.No";
            setBasNo(basNo);
            const bitNo = window.innerWidth > 1500 ? "Bitiş No" : "Bit.No";
            setBitNo(bitNo);
            const bitYil = window.innerWidth > 1500 ? "Bitiş Yılı" : "Bit.Yılı";
            setBitYil(bitYil);
            const basYil = window.innerWidth > 1500 ? "Başlangıç Yılı" : "Baş.Yılı";
            setBasYil(basYil);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    useEffect(() => {
        if (yargitayBitKararTarihi && yargitayBasKararTarihi && yargitayBasKararTarihi > yargitayBitKararTarihi) {
            if (sonDegisiklik == "yargitayBasKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Başlangıç tarihi bitiş tarihinden büyük olamaz!', life: 5000 });
                setYargitayBasKararTarihi(null);
            }
            if (sonDegisiklik == "yargitayBitKararTarihi") {
                toast.current.show({ severity: 'error', summary: '', detail: 'Bitiş tarihi başlangıç tarihinden küçük olamaz!', life: 5000 });
                setYargitayBitKararTarihi(null);
            }
        }
    }, [yargitayBasKararTarihi, yargitayBitKararTarihi]);

    return (
        <Card className='lg:col-4 md:col-4 sm:col-12 yargitay-card-color' tabIndex="1">
            <Toast ref={toast} position="top-center" />
            <div className="font-bold text-white bg-cyan-700 p-3 " style={{ marginTop: "-6px" }}>
                YARGITAY BİLGİLERİ
            </div>
            <div className='col-12'>
                <span className="p-float-label tooltipSorguForm">
                    <MultiSelect id='daire' value={daire}
                        options={repoDaire} onChange={(e) => {
                            updateField(e, 'daire');
                            setDaire(e.value);
                            pasifAlanlariBelirle(e.value);
                            setDisabledbtnSorgu(false);
                        }} optionLabel="label" filter ref={kararOrganlariRef} overlayVisible={showKararOrganlariDropdown}
                        filterLocale="tr"
                        emptyFilterMessage="Kullanılabilir seçenek yok"
                        selectedItemsLabel="{0} öğe seçildi"
                        className="w-full max-width" maxSelectedLabels="2" resetFilterOnHide="true" />
                    <div className={(daire != null && kararOrganlariLabel.length > 0) ? "tooltiptextSorguForm" : "tooltiptextSorguFormHidden"}>{kararOrganlariLabel} </div>
                    <label htmlFor="daire">Yargıtay Karar Organları</label>
                </span>
            </div>
            <div className='col-12'>
                <span className="p-float-label tooltipSorguForm">
                    <MultiSelect id='yimKararTur' value={yimKararTuru}
                        options={repoYimKararTuru} onChange={(e) => {
                            setDisabledbtnSorgu(false);
                            updateField(e, 'yimKararTuru');
                            setYimKararTurleri(e.value)
                        }} filterLocale="tr"
                        emptyFilterMessage="Kullanılabilir seçenek yok"
                        selectedItemsLabel="{0} öğe seçildi"
                        optionLabel="label" filter maxSelectedLabels="2" resetFilterOnHide="true"
                        className="w-full max-width" />
                    <div className={(yimKararTuru != null && kararTuruLabel.length > 0) ? "tooltiptextSorguForm" : "tooltiptextSorguFormHidden"}>{kararTuruLabel} </div>
                    <label htmlFor="yimKararTur">Karar Türü</label>
                </span>
            </div>
            <div className='col-12'>
                <p className="text-left filter-card-text-style mb-4 font-size">Yargıtay Esas No</p>
                <div className='grid'>
                    <div className='col-3'>
                        <span className="p-float-label ">
                            <InputText id="esasNoBasYili" ref={esasNoBasYiliRef}
                                onInput={() => {
                                    if (esasNoBasYiliRef.current !== undefined) {
                                        yargHandleInput(esasNoBasYiliRef.current.value, "esasNoBasYili");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                maxLength={4}
                                onKeyUp={(e) => autoTab(e)}
                                onKeyPress={handleKeyPress}
                                keyfilter={/^[0-9]*$/} />
                            <label htmlFor="esasNoBaslangic" style={inputStyles.esasNoBasYili}>{basYil}</label>
                        </span>
                    </div>
                    <div className='col-3'>
                        <span className="p-float-label">
                            <InputText id="esasNoBaslangic" ref={esasNoBaslangicRef}
                                onInput={() => {
                                    if (esasNoBaslangicRef.current !== undefined) {
                                        yargHandleInput(esasNoBaslangicRef.current.value, "esasNoBaslangic");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                onKeyPress={handleKeyPress}
                                keyfilter={/^[0-9]*$/} />
                            <label htmlFor="esasNoBaslangic" style={inputStyles.esasNoBaslangic}>{basNo}</label>
                        </span>
                    </div>
                    <div className='filter-line'></div>
                    <div className='col-3'>
                        <span className="p-float-label">
                            <InputText id="esasNoBitYili" ref={esasNoBitYiliRef}
                                onInput={() => {
                                    if (esasNoBitYiliRef.current !== undefined) {
                                        yargHandleInput(esasNoBitYiliRef.current.value, "esasNoBitYili");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                maxLength={4}
                                onKeyUp={(e) => autoTab(e)}
                                onKeyPress={handleKeyPress}
                                keyfilter={/^[0-9]*$/} />
                            <label htmlFor="esasNoBitis" style={inputStyles.esasNoBitYili}>{bitYil}</label>
                        </span>
                    </div>
                    <div className='col-2' style={{ width: "22.5%" }}>
                        <span className="p-float-label">
                            <InputText id="esasNoBitis" ref={esasNoBitisRef}
                                onInput={() => {
                                    if (esasNoBitisRef.current !== undefined) {
                                        yargHandleInput(esasNoBitisRef.current.value, "esasNoBitis");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                onKeyPress={handleKeyPress}
                                keyfilter={/^[0-9]*$/} />
                            <label htmlFor="esasNoBitis" style={inputStyles.esasNoBitis}>{bitNo}</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <p className="text-left filter-card-text-style mb-4 font-size">Yargıtay Karar No</p>
                <div className='grid'>
                    <div className="col-3">
                        <span className="p-float-label">
                            <InputText id="kararNoBasYili" ref={kararNoBasYiliRef}
                                onInput={() => {
                                    if (kararNoBasYiliRef.current !== undefined) {
                                        yargHandleInput(kararNoBasYiliRef.current.value, "kararNoBasYili");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                maxLength={4}
                                onKeyUp={(e) => autoTab(e)}
                                onKeyPress={handleKeyPress}
                                keyfilter={/^[0-9]*$/} />
                            <label htmlFor="kararNoBaslangic" style={inputStyles.kararNoBasYili}>{basYil}</label>
                        </span>
                    </div>
                    <div className="col-3">
                        <span className="p-float-label">
                            <InputText id="kararNoBaslangic"
                                ref={kararNoBaslangicRef} onInput={() => {
                                    if (kararNoBaslangicRef.current !== undefined) {
                                        yargHandleInput(kararNoBaslangicRef.current.value, "kararNoBaslangic");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full" keyfilter={/^[0-9]*$/} onKeyPress={handleKeyPress} />
                            <label htmlFor="kararNoBaslangic" style={inputStyles.kararNoBaslangic}>{basNo}</label>
                        </span>
                    </div>
                    <div className='filter-line'></div>
                    <div className="col-3 ">
                        <span className="p-float-label">
                            <InputText id="kararNoBitYili" ref={kararNoBitYiliRef}
                                onInput={() => {
                                    if (kararNoBitYiliRef.current !== undefined) {
                                        yargHandleInput(kararNoBitYiliRef.current.value, "kararNoBitYili");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                maxLength={4}
                                onKeyUp={(e) => autoTab(e)}
                                onKeyPress={handleKeyPress}
                                keyfilter={/^[0-9]*$/} />
                            <label htmlFor="kararNoBitis" style={inputStyles.kararNoBitYili}>{bitYil}</label>
                        </span>
                    </div>
                    <div className='col-2' style={{ width: "22.5%" }}>
                        <span className="p-float-label">
                            <InputText id="kararNoBitis" ref={kararNoBitisRef}
                                onInput={() => {
                                    if (kararNoBitisRef.current !== undefined) {
                                        yargHandleInput(kararNoBitisRef.current.value, "kararNoBitis");
                                    }
                                    setDisabledbtnSorgu(false);
                                }} className="w-full"
                                keyfilter={/^[0-9]*$/} onKeyPress={handleKeyPress} />
                            <label htmlFor="kararNoBitis" style={inputStyles.kararNoBitis}>{bitNo}</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className='grid'>
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar
                                id="kararTarihiBas"
                                label={windowWidth > 1500 ? "Karar Başlangıç Tarihi" : "Karar Baş.Tarihi"}
                                value={yargitayBasKararTarihi}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setYargitayBasKararTarihi(e);
                                    setSonDegisiklik("yargitayBasKararTarihi");
                                }}
                                maxDate={yargitayBitKararTarihi}
                                viewDate={yargitayBasKararTarihi || yargitayBitKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="p-float-label">
                            <YimCalendar id="kararTarihiBit"
                                value={yargitayBitKararTarihi}
                                label={windowWidth > 1500 ? "Karar Bitiş Tarihi" : "Karar Bit.Tarihi"}
                                onChange={(e) => {
                                    setDisabledbtnSorgu(false);
                                    setYargitayBitKararTarihi(e);
                                    setSonDegisiklik("yargitayBitKararTarihi");
                                }}
                                minDate={yargitayBasKararTarihi}
                                viewDate={yargitayBitKararTarihi || yargitayBasKararTarihi || new Date()}
                            />
                        </span>
                    </div>
                </div>
                <div className="font-bold text-white bg-cyan-700 p-2 " style={{ marginTop: "5px" }}>
                </div>
            </div>
        </Card>
    )
}

export default YargitayBilgileri;