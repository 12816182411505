import './App.css';
import Header from './components/layout/header';
import Content from './components/layout/content';
import { BrowserRouter as Router } from 'react-router-dom';
import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import { KararAramaProvider } from './contexts/KararAramaContext';
import { LoginTokenProvider } from './contexts/LoginTokenContext';
import { VeriDoldurmaProvider } from './contexts/VeriDoldurmaContext';
import { GenelTematikNotlarProvider } from "./contexts/GenelTematikNotlarContext";
import { WorkListEntityProvider } from "./contexts/WorkListContext";
import { YonetimPaneliProvider } from "./contexts/YonetimPaneliContext";
import HomeRoute from './components/layout/home-route';
import { PrimeProvider } from './contexts/PrimeContext';
import YardimRoute from './components/layout/yardim-route';
import { YargitayBilgileriProvider } from './contexts/YargitayBilgileriContext';
import { MahkemeBilgileriProvider } from './contexts/MahkemeBilgileriContext';
import { DavaSucProvider } from './contexts/DavaSucContext';
import { SorguProvider } from './contexts/SorguContext';


function App() {

    return (
        <Router>
            <PrimeProvider>
                <LoginTokenProvider>
                    <WorkListEntityProvider>
                        <VeriDoldurmaProvider>
                            <YonetimPaneliProvider>
                                <MahkemeBilgileriProvider>
                                    <DavaSucProvider>
                                        <YargitayBilgileriProvider>
                                            <KararAramaProvider>
                                                <SorguProvider>
                                                    <GenelTematikNotlarProvider>
                                                        <div className="App">
                                                            <div style={{ background: "whitesmoke" }}>
                                                                <Header />
                                                            </div>
                                                            <Content />
                                                            <HomeRoute />
                                                            <YardimRoute />
                                                        </div>
                                                    </GenelTematikNotlarProvider>
                                                </SorguProvider>
                                            </KararAramaProvider>
                                        </YargitayBilgileriProvider>
                                    </DavaSucProvider>
                                </MahkemeBilgileriProvider>
                            </YonetimPaneliProvider>
                        </VeriDoldurmaProvider>
                    </WorkListEntityProvider>
                </LoginTokenProvider>
            </PrimeProvider>
        </Router>
    );
}

export default App;


