import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { UyeService } from '../services/UyeService';

const OneriSikayet = (props) => {

    const uyeService = new UyeService();
    const toast = useRef(null);
    const [aciklama, setAciklama] = useState('');
    const maxCharText = 400;
    const [selectedData, setSelectedData] = useState(null);

    const data = [
        { label: 'Teknik Öneri', value: 'Teknik' },
        { label: 'Hukuki Öneri', value: 'Hukuki' }
    ];


    const onHide = () => {
        props.setDisplayOneriSikayet(false);
        setAciklama('');
        setSelectedData('');
    }
    const renderFooter = () => {
        return (
            <div className="col-12 text-right">
                <Button className='p-button-danger' label="Vazgeç" icon="pi pi-times" onClick={onHide} />
                <Button className='p-button-warning' label="Gönder" icon="pi pi-send" onClick={gonder} />
            </div>
        )
    }


    const gonder = () => {
        if (aciklama.trim() == "") {
            toast.current.show({ severity: 'error', summary: '', detail: 'Açıklama giriniz.', life: 3000 });
            return;
        }
        if (selectedData == null || selectedData == "") {
            toast.current.show({ severity: 'error', summary: '', detail: 'Öneri tipi seçiniz.', life: 3000 });
            return;
        }
        if (aciklama.length < 10) {
            toast.current.show({ severity: 'error', summary: '', detail: 'En az 10 karakter açıklama girmeniz gerekmektedir.', life: 3000 });
            return;
        }
        let params = {
            oneriSikayet: selectedData,
            aciklama: aciklama
        }
        uyeService.oneriSikayetKaydet(params).then(() => {
            toast.current.show({ severity: 'success', summary: '', detail: 'Öneriniz değerlendirilmek üzere kaydedilmiştir.', life: 3000 });
            onHide();
        });
    }

    function karakterSayiUpdate() {
        let max = 400;
        let textarea = document.getElementById("aciklama");
        let info = document.getElementById("info")

        //Init the count for the first time
        if (textarea !== null && (textarea.value !== '' || textarea.value !== null)) {
            info.textContent = max - textarea.value.length;

            textarea.addEventListener('input', function () {
                info.textContent = max - this.value.length;
            })
        }
        else if (info !== null) {
            info.textContent = max;
            textarea.addEventListener('input', function () {
                info.textContent = max;
            })
        }
    }


    return (
        <section>
            <Toast ref={toast} position="top-center" />
            <Dialog id='talep' header={'Öneri Talepleri'} className="oneri-dialog" visible={props.displayOneriSikayet} footer={() => renderFooter()} onHide={() => onHide()}>
                <div className="col-12 pt-2">
                    <div className="field grid">
                        <label htmlFor="aciklama"><b>Öneri Tipi</b></label>
                        <Dropdown
                            className='w-full'
                            value={selectedData}
                            options={data}
                            onChange={(e) => setSelectedData(e.value)}
                            placeholder="Öneri tipi seçiniz.."
                        />
                    </div>
                </div>
                <div className="col-12 pt-2">
                    <div className="field grid">
                        <label htmlFor="aciklama"><b>Açıklama</b></label>
                        <InputTextarea id='aciklama' value={aciklama} className="custom-scroll" cols={80} rows={5} maxLength="400" onChange={(e) => {
                            setAciklama(e.target.value);
                            karakterSayiUpdate();
                        }} autoResize="false" />
                        <div><span id="info" ></span> / {maxCharText}</div>
                    </div>
                </div>
            </Dialog>
        </section >
    )
}

export default OneriSikayet
