import axios from "axios";

const controller = "/v1/uyeler";

export class UyeService {

    async getUyeById(uyeId) {
        let url = process.env.REACT_APP_URL + controller + "/uyeById?uyeId=" + uyeId;
        return axios
            .get(url)
            .then((d) => d.data);
    }

    async profilKaydet(params) {
        const url = process.env.REACT_APP_URL + controller + "/profilKaydet";
        return axios
            .post(url, params)
            .then((d) => d.data);
    }

    async oneriSikayetKaydet(params) {
        const url = process.env.REACT_APP_URL + controller + "/oneriSikayet";
        return axios
            .post(url, params)
            .then((d) => d.data);
    }

    async getKvkkMetni() {
        let url = process.env.REACT_APP_URL + controller + "/kvkkMetni";
        return axios
            .get(url)
            .then((d) => d.data);
    }

}
