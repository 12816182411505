import axios from "axios";
import { saveAs } from "file-saver";

const controller = "/v1/sorgulamalar";
export class KararAramaService {

  async listSorguSonucByFiltre(params) {
    let url = process.env.REACT_APP_URL + controller + "/listEmsalKararByFiltre";
    return axios
      .post(url, params)
      .then((res) => res.data);
  }

  async sorguKaydet(params) {
    let url = process.env.REACT_APP_URL + controller + "/sorguKaydet";
    return axios
      .post(url, params)
      .then((res) => res.data);
  }

  async favoriKaydet(params) {
    let url = process.env.REACT_APP_URL + controller + "/favoriKaydet";
    return axios
      .post(url, params)
      .then((res) => { });
  }

  async getSorguById(sorguId) {
    let url = process.env.REACT_APP_URL + controller + "/getSorguById?sorguId=" + sorguId;
    return axios.get(url)
      .then((d) => d.data);
  }
  async getKararMaddeleriByIds(params) {
    let url = process.env.REACT_APP_URL + controller + "/kararMaddeleriByIds";
    return axios.post(url, params)
      .then((d) => d.data);
  }


  async kararIndir(param) {
    let url = process.env.REACT_APP_URL + controller + "/kararIndir?kararId=" + param.kararId;
    return axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: (s) => s <= 500,
      responseType: 'blob',
    }).then((res) => {
      if (res.data.size === 0) {
        const error = true;
        return error;
      } else {
        const file = new Blob([res.data]);
        saveAs(file, param.adi + ".pdf");
      }
    });
  }

  async gunlukIndirmeLimitKontrol() {
    let url = process.env.REACT_APP_URL + controller + "/gunlukIndirmeLimitKontrol";
    return axios.get(url).then((res) => res.data);
  }

  async getFavoriById(favoriId) {
    let url = process.env.REACT_APP_URL + controller + "/getFavoriById?favoriId=" + favoriId;
    return axios.get(url)
      .then((d) => d.data);
  }

  async updateKlasorAdi(params) {
    let url = process.env.REACT_APP_URL + controller + "/updateKlasorAdi";
    return axios
      .post(url, params)
      .then((res) => res);
  }

  async favorilerdenKaldir(params) {
    let url = process.env.REACT_APP_URL + controller + "/favorilerdenKaldir";
    return axios
      .post(url, params)
      .then();
  }

  async updateSorguFavoriAdi(params) {
    let url = process.env.REACT_APP_URL + controller + "/updateSorguFavoriAdi";
    return axios
      .post(url, params)
      .then((res) => res);
  }

  async yeniKlasorEkle(params) {
    let url = process.env.REACT_APP_URL + controller + "/yeniKlasor";
    return axios
      .post(url, params)
      .then();
  }

  async silComponent(params) {
    let url = process.env.REACT_APP_URL + controller + "/silComponent";
    return axios
      .post(url, params)
      .then();
  }

  async getKararDetayByEmsalKararId(param) {
    let url = process.env.REACT_APP_URL + controller + "/kararDetayByEmsalKararId";
    return axios.post(url, param)
      .then((d) => d.data);
  }
  async updateLogSorgu(params) {
    let url = process.env.REACT_APP_URL + controller + "/logSorgu";
    await axios.patch(url, params).then((d) => d.data);
  }

  async getKararSayisi() {
    let url = process.env.REACT_APP_URL + controller + "/getCount";
    return axios.get(url)
      .then((d) => d.data);
  }

}
