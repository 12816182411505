import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useGenelTematikNotlar } from "../../contexts/GenelTematikNotlarContext";
import { TematikNotService } from "../../services/TematikNotService";
import { Paginator } from 'primereact/paginator';
import { AnketService } from "../../services/AnketService";
import { useLoginToken } from "../../contexts/LoginTokenContext";
import { BultenService } from "../../services/BultenService";
import { Toast } from "primereact/toast";
import PdfViewer from "../shared/PdfViewer";


const Duyuru = () => {
    const [displayGenelTematikNotlar, setDisplayGenelTematikNotlar] = useState(false);
    const [isDuyuru, setIsDuyuru] = useState(false);
    const [duyuruData, setDuyuruData] = useState([]);
    const [bultenData, setBultenData] = useState([]);
    const [tematikNotData, setTematikNotData] = useState([]);
    const [anketData, setAnketData] = useState([]);
    const toast = useRef(null);
    const tematikService = new TematikNotService();
    const anketService = new AnketService();
    const bultenService = new BultenService();
    const [displayAnket, setDisplayAnket] = useState(false);
    const [anketKonusu, setAnketKonusu] = useState('');
    const [anketAciklama, setAnketAciklama] = useState('');
    const { hakimMi } = useLoginToken()
    const [displayPdfViewer, setDisplayPdfViewer] = useState(false);
    const [file, setFile] = useState('');
    const { setAmaciDiger, amaci, setAmaci, basTarih, setBasTarih, setBitTarih, konusu, setKonusu, tematikBilgiNotu, setTematikBilgiNotu, mevzuat, setMevzuat } = useGenelTematikNotlar();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const [startIndex, setStartIndex] = useState(1);
    const [currentTematikPage, setCurrentTematikPage] = useState(1);
    const itemsPerTematikPage = 3;
    const [startIndexTematik, setStartIndexTematik] = useState(1);
    const [currentAnketPage, setCurrentAnketPage] = useState(1);
    const itemsPerAnketPage = 3;
    const [startIndexAnket, setStartIndexAnket] = useState(1);
    const [currentBultenPage, setCurrentBultenPage] = useState(1);
    const itemsBultenPage = 3;
    const [startIndexBulten, setStartIndexBulten] = useState(1);

    useEffect(() => {
        listDuyurular();
        listTematikNot();
        listAnketler();
        listBultenler();
    }, []);

    const listDuyurular = () => {
        tematikService.getDuyurular().then((data) => {
            if (data) {
                setDuyuruData(data);
            }
        });
    }
    const listBultenler = () => {
        bultenService.getBultenler().then((data) => {
            if (data) {
                setBultenData(data);
            }
        });
    }
    const listTematikNot = () => {
        tematikService.getTematikNot().then((data) => {
            if (data) {
                setTematikNotData(data);
            }
        });
    }
    const listAnketler = () => {
        anketService.getAnket().then((data) => {
            if (data) {
                setAnketData(data);
            }
        });
    }

   

    const onRowClick = (event) => {
        tematikService.getDuyuruById(event.id).then((resp) => {
            setDisplayGenelTematikNotlar(true);
            setIsDuyuru(true);
            resp.tematikBilgiNotu != null ? setTematikBilgiNotu(resp.tematikBilgiNotu) : setTematikBilgiNotu('');
            resp.amaci != null ? setAmaci(resp.amaci) : setAmaci('');
            resp.konusu != null ? setKonusu(resp.konusu) : setKonusu('');
            resp.mevzuat != null ? setMevzuat(resp.mevzuat) : setMevzuat('');
            resp.basTarih != null ? setBasTarih(resp.basTarih) : setBasTarih('');
            resp.bitTarih != null ? setBitTarih(resp.bitTarih) : setBitTarih('');
            resp.amaciDiger != null ? setAmaciDiger(resp.amaciDiger) : setAmaciDiger('');
        });
    }
    const onRowTematikNotClick = (event) => {
        tematikService.getTematikNotById(event.id).then((resp) => {
            setDisplayGenelTematikNotlar(true);
            setIsDuyuru(false);
            resp.tematikBilgiNotu != null ? setTematikBilgiNotu(resp.tematikBilgiNotu) : setTematikBilgiNotu('');
            resp.amaci != null ? setAmaci(resp.amaci) : setAmaci('');
            resp.konusu != null ? setKonusu(resp.konusu) : setKonusu('');
            resp.mevzuat != null ? setMevzuat(resp.mevzuat) : setMevzuat('');
            resp.basTarih != null ? setBasTarih(resp.basTarih) : setBasTarih('');
            resp.bitTarih != null ? setBitTarih(resp.bitTarih) : setBitTarih('');
            resp.amaciDiger != null ? setAmaciDiger(resp.amaciDiger) : setAmaciDiger('');
        });
    }
    const onRowAnketClick = (event) => {
        setDisplayAnket(true);
        anketService.getAnketById(event.id).then((resp) => {
            resp.aciklama != null ? setAnketAciklama(resp.aciklama) : setAnketAciklama('');
            resp.konusu != null ? setAnketKonusu(resp.konusu) : setAnketKonusu('');
        });
    }
    const onRowBultenClick = (event) => {
        toast.current.show({ severity: 'success', summary: '', detail: "Önizleme açılıyor.", life: 1000 });
        bultenService.getBultenById(event.id, event.yayimAdi, true).then((data) => {
            setFile(data);
            setDisplayPdfViewer(true);
        });
    }




    const displayedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        setStartIndex(startIndex);
        const endIndex = startIndex + itemsPerPage;
        return duyuruData.slice(startIndex, endIndex);
    }, [currentPage, itemsPerPage, duyuruData]);

    const displayedBultenData = useMemo(() => {
        const startIndex = (currentBultenPage - 1) * itemsBultenPage;
        setStartIndexBulten(startIndex);
        const endIndex = startIndex + itemsBultenPage;
        return bultenData.slice(startIndex, endIndex);
    }, [currentBultenPage, itemsBultenPage, bultenData]);

    const displayedTematikData = useMemo(() => {
        const startIndex = (currentTematikPage - 1) * itemsPerTematikPage;
        setStartIndexTematik(startIndex);
        const endIndex = startIndex + itemsPerTematikPage;
        return tematikNotData.slice(startIndex, endIndex);
    }, [currentTematikPage, itemsPerTematikPage, tematikNotData]);

    const displayedAnketData = useMemo(() => {
        const startIndex = (currentAnketPage - 1) * itemsPerAnketPage;
        setStartIndexAnket(startIndex);
        const endIndex = startIndex + itemsPerAnketPage;
        return anketData.slice(startIndex, endIndex);
    }, [currentAnketPage, itemsPerAnketPage, anketData]);


    return (
        <React.Fragment>
            <Toast ref={toast} position="top-center" />
            {((duyuruData.length > 0) || (hakimMi && tematikNotData.length > 0) || (anketData.length > 0) || (bultenData.length > 0)) && <Card style={{ background: "#ebe7e6" }}>
                <div className="grid p-2">
                    <div className="card w-full">
                        <div className="w-full card-container" id="duyuru" >
                            {(duyuruData.length > 0) &&
                                <Card title="Duyurular" style={{ background: "#FBF8F7" }}>
                                    {displayedData.map((veri) => (
                                        <Card onClick={() => onRowClick(veri)} key={veri.id} style={{ marginTop: "8px", background: " #E8E8E8" }}>
                                            {veri.sabitMi && <i className="fa fa-thumb-tack mr-2" aria-hidden="true"
                                                style={{ color: "red" }}></i>}
                                            <p>{veri.konu} <span style={{ fontStyle: 'italic' }}>görüntülemek için tıklayınız...</span></p>
                                        </Card>
                                    ))}

                                    <Paginator
                                        first={startIndex}
                                        rows={itemsPerPage}
                                        totalRecords={duyuruData.length}
                                        template={{
                                            layout: 'PrevPageLink CurrentPageReport NextPageLink',
                                            'CurrentPageReport': (options) => {
                                                return `${options.currentPage} / ${options.totalPages}`;
                                            }
                                        }}
                                        onPageChange={(e) => setCurrentPage(e.page + 1)}
                                    />
                                </Card>}
                            {(hakimMi && tematikNotData.length > 0) &&
                                <Card title="Tematik Notlar" className="mt-2" style={{ cursor: "pointer !important", background: "#FBF8F7" }}>
                                    {displayedTematikData.map((veri) => (
                                        <Card onClick={() => onRowTematikNotClick(veri)} key={veri.id} style={{ marginTop: "8px", background: " #E8E8E8", cursor: "pointer !important" }}>
                                            {veri.sabitMi && <i className="fa fa-thumb-tack mr-2" aria-hidden="true"
                                                style={{ color: "red" }}></i>}
                                            <p>{veri.konu} <span style={{ fontStyle: 'italic' }}>görüntülemek için tıklayınız...</span></p>
                                        </Card>
                                    ))}
                                    <Paginator
                                        first={startIndexTematik}
                                        rows={itemsPerTematikPage}
                                        totalRecords={tematikNotData.length}
                                        template={{
                                            layout: 'PrevPageLink CurrentPageReport NextPageLink',
                                            'CurrentPageReport': (options) => {
                                                return `${options.currentPage} / ${options.totalPages}`;
                                            }
                                        }}
                                        onPageChange={(e) => setCurrentTematikPage(e.page + 1)}
                                    />
                                </Card>}
                            {(anketData.length > 0) &&
                                <Card title="Anketler" className="mt-2" style={{ cursor: "pointer !important", background: "#FBF8F7" }}>
                                    {displayedAnketData.map((veri) => (
                                        <Card onClick={() => onRowAnketClick(veri)} key={veri.id} style={{ marginTop: "8px", background: " #E8E8E8", cursor: "pointer !important" }}>
                                            <p>{veri.konusu} <span style={{ fontStyle: 'italic' }}>görüntülemek için tıklayınız...</span></p>
                                        </Card>
                                    ))}
                                    <Paginator
                                        first={startIndexAnket}
                                        rows={itemsPerAnketPage}
                                        totalRecords={anketData.length}
                                        template={{
                                            layout: 'PrevPageLink CurrentPageReport NextPageLink',
                                            'CurrentPageReport': (options) => {
                                                return `${options.currentPage} / ${options.totalPages}`;
                                            }
                                        }}
                                        onPageChange={(e) => setCurrentAnketPage(e.page + 1)}
                                    />
                                </Card>}
                            {(bultenData.length > 0) &&
                                <Card title="Bültenler" className="mt-2" style={{ background: "#FBF8F7" }}>
                                    {displayedBultenData.map((veri) => (
                                        <Card onClick={() => onRowBultenClick(veri)} key={veri.id} style={{ marginTop: "8px", background: " #E8E8E8" }}>
                                            <p>{veri.yayimAdi} <span style={{ fontStyle: 'italic' }}> sayısına ilişkin E-Bültene ulaşmak için tıklayınız...</span></p>
                                        </Card>
                                    ))}

                                    <Paginator
                                        first={startIndexBulten}
                                        rows={itemsBultenPage}
                                        totalRecords={bultenData.length}
                                        template={{
                                            layout: 'PrevPageLink CurrentPageReport NextPageLink',
                                            'CurrentPageReport': (options) => {
                                                return `${options.currentPage} / ${options.totalPages}`;
                                            }
                                        }}
                                        onPageChange={(e) => setCurrentBultenPage(e.page + 1)}
                                    />
                                </Card>}
                        </div>
                    </div>
                </div><Dialog id="duyurular" visible={displayGenelTematikNotlar} className="duyuru-dialog-wh" onHide={() => setDisplayGenelTematikNotlar(false)}>
                    <div className="col-12">
                        <div className="col-12">
                            <div className="date-container mr-4">
                                <div className="date bold">{basTarih}</div>
                            </div>
                        </div>
                        {!isDuyuru && <div className="col-12">
                            <div className="header-container">
                                <div className="header-dialog ml-4">Bu Tematik Bilgi Notu, Yargıtay İçtihat Merkezi tarafından bilgi amaçlı olarak hazırlanmış olup, hiçbir şekilde Yargıtay’ı bağlamaz, içtihatların yorumlanması veya nasıl anlaşılacağı konusunda taahhüt içermez. İçtihat metni esastır.</div>
                            </div>
                        </div>}
                    </div>
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 ">
                                <div className="field grid ml-5">
                                    <div className="col-12 md:col-10 text-left">
                                        <label htmlFor="amaci" className="font-semibold">Amacı:</label>
                                        <span className="ml-5">{amaci}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field grid ml-5">
                                    <div className="col-12 md:col-10 text-left">
                                        <label htmlFor="konusu" className="font-semibold">Konusu:</label>
                                        <span className="ml-4">{konusu}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field grid ml-5">
                                    <div className="col-12 md:col-10 text-left">
                                        <label htmlFor="konusu" className="font-semibold">Mevzuat:</label>
                                        <span className="ml-3">{mevzuat}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field grid ml-3" id="duyuruEditor">
                                    <div className="col-12 md:col-8 text-left">
                                        <Editor readOnly={true} id="editor" background="#F5F5F5" className="tematik-editor-style" value={tematikBilgiNotu} showHeader={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Anket Ekranı" id="duyurular" visible={displayAnket} className="anket-dialog-wh" onHide={() => setDisplayAnket(false)}>
                    <div className="card">
                        <div className="grid ">
                            <div className="col-12">
                                <div className="field grid">
                                    <label htmlFor="konusu" className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Konusu</label>
                                    <div className="col-12 md:col-10 text-left">
                                        <p className="ml-2">{anketKonusu}</p>
                                    </div>

                                </div>
                                <hr className="horizontal-line" />
                            </div>
                            <div className="col-12">
                                <div className="field grid" id="duyuruEditor">
                                    <label htmlFor="anket" className="col-2 font-semibold" style={{ marginTop: "14px" }}>Link</label>
                                    <div className="col-12 md:col-10 text-left mt-3">
                                        <a href={anketAciklama} target="_blank">{anketAciklama}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <PdfViewer file={file} displayPdfViewer={displayPdfViewer} setDisplayPdfViewer={setDisplayPdfViewer} />
            </Card>}
        </React.Fragment>
    );
};



export default Duyuru
