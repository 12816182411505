import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useLoginToken } from '../contexts/LoginTokenContext';
import KararAramaSorguForm from '../components/page/kararArama/KararAramaSorguForm';
import KararAramaDataTable from '../components/page/kararArama/KararAramaDataTable';
import { useSorgu } from '../contexts/SorguContext';

const KararArama = (props) => {
    const { setKullanici, setGirisTipi, refSorguId, setRefSorguId } = useLoginToken();
    const { getSorguById } = useSorgu();

    useEffect(() => {
        if (props != undefined && props.kullanici != undefined) {
            setGirisTipi(localStorage.girisTipi);
            setKullanici(localStorage.kullanici);
        }
        if (refSorguId !== undefined && refSorguId !== "" && refSorguId !== null) {
            getSorguById(refSorguId);
            setRefSorguId(null);
        }
    }, []);

    const toast = useRef(null);

    return (
        <section>
            <Toast ref={toast} position="top-center" />
            <KararAramaSorguForm />
            <div >
                <KararAramaDataTable />
            </div>
        </section>
    )
}

export default KararArama