import React, { createContext, useContext, useMemo, useRef, useState } from "react";
import { useVeriDoldurma } from "./VeriDoldurmaContext";
const MahkemeBilgileriContext = createContext();

export const MahkemeBilgileriProvider = ({ children }) => {
    const { geldigiMahkemeData, setGeldigiMahkemeData, setGeldigiMahkemeLabel, geldigiMahkemeGuncelle, setRepoMahkemeTuru, repoHamMahkemeTuru, repoMahkemeTuru,setGeldigiMahkeme } = useVeriDoldurma();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showGeldigiMahkemeDropdown, setShowGeldigiMahkemeDropdown] = useState(false);
    const [prevGeldigiMahSelection, setPrevGeldigiMahSelection] = useState([]);
    const [mahkemeTuruLabel, setMahkemeTuruLabel] = useState([]);
    const [mahkemeTuru, setMahkemeTuru] = useState([]);
    const [prevMahkemeTuruSelection, setPrevMahkemeTuruSelection] = useState([]);
    const [mahBasKararTarihi, setMahBasKararTarihi] = useState(null);
    const [mahBitKararTarihi, setMahBitKararTarihi] = useState(null);
    const [iddianameBasKararTarihi, setIddianameBasKararTarihi] = useState(null);
    const [iddianameBitKararTarihi, setIddianameBitKararTarihi] = useState(null);
    const mahkemeTuruRef = useRef(null);
    const geldigiMahkemeRef = useRef(null);
    const today = new Date();
    const [iddianameTarihi, setIddianameTarihi] = useState(null);

    const sortedMahkemeTurleri = (e) => {
        if (e.value.length < prevMahkemeTuruSelection.length) {
            setRepoMahkemeTuru(repoHamMahkemeTuru);
            getMahkemeTuruLabel(e);
        }
        else {
            getMahkemeTuruLabel(e);
        }
    }

    const getMahkemeTuruLabel = (e) => {
        setPrevMahkemeTuruSelection(e.value);
        setRepoMahkemeTuru((prevOptions) => {
            const newOptions = [...prevOptions];
            e.value.forEach((value, index) => {
                const itemIndex = prevOptions.findIndex((item) => item.value === value);
                const [item] = newOptions.splice(itemIndex, 1);
                newOptions.splice(index, 0, item);
            });
            return newOptions;
        });
        let mahkemeLabel = [];
        mahkemeLabel = repoMahkemeTuru.filter(x => e.value.includes(x.value));
        mahkemeLabel = mahkemeLabel.map(x => <> &ensp;* {x.label}<br /></>);
        setMahkemeTuruLabel(mahkemeLabel);
    }

    const sortedGeldigiMahkeme = (e) => {
        if (e.value.length < prevGeldigiMahSelection.length) {
            geldigiMahkemeGuncelle(function (rest) {
                if (rest) {
                    getGeldigiMahkemeLabel(e);
                }
            })
        }
        else {
            getGeldigiMahkemeLabel(e);
        }
    }

    const getGeldigiMahkemeLabel = (e) => {
        setPrevGeldigiMahSelection(e.value);
        setGeldigiMahkemeData((prevOptions) => {
            const newOptions = [...prevOptions];
            e.value.forEach((value, index) => {
                const itemIndex = prevOptions.findIndex((item) => item.value === value);
                const [item] = newOptions.splice(itemIndex, 1);
                newOptions.splice(index, 0, item);
            });
            return newOptions;
        });
        let geldigiMahkemeLabel = [];
        geldigiMahkemeLabel = geldigiMahkemeData.filter(x => e.value.includes(x.value));
        geldigiMahkemeLabel = geldigiMahkemeLabel.map(x => <> &ensp;* {x.label}<br /></>);
        setGeldigiMahkemeLabel(geldigiMahkemeLabel);
    }

    const mahkemeFiltreTemizle = () => {
        if (iddianameBasKararTarihi)
            setIddianameBasKararTarihi(today);
        if (iddianameBitKararTarihi)
            setIddianameBitKararTarihi(today);
        if (mahBasKararTarihi)
            setMahBasKararTarihi(today);
        if (mahBitKararTarihi)
            setMahBitKararTarihi(today);
        setIddianameTarihi(null);
        setGeldigiMahkeme([]);
        setMahkemeTuruLabel([]);
        setRepoMahkemeTuru(repoHamMahkemeTuru);
        setMahkemeTuru([]);
        geldigiMahkemeGuncelle([]);
        setGeldigiMahkemeLabel([]);

    }

    const values  = useMemo(() => ({
        showDropdown, setShowDropdown, showGeldigiMahkemeDropdown, setShowGeldigiMahkemeDropdown, sortedGeldigiMahkeme, sortedMahkemeTurleri, mahkemeTuruLabel, setMahkemeTuruLabel,
        mahkemeTuru, setMahkemeTuru, mahBasKararTarihi, setMahBasKararTarihi, mahBitKararTarihi, setMahBitKararTarihi, iddianameBasKararTarihi, setIddianameBasKararTarihi,
        iddianameBitKararTarihi, setIddianameBitKararTarihi, mahkemeTuruRef, geldigiMahkemeRef,iddianameTarihi, setIddianameTarihi,mahkemeFiltreTemizle

    }), [
        showDropdown, setShowDropdown, showGeldigiMahkemeDropdown, setShowGeldigiMahkemeDropdown, sortedGeldigiMahkeme, sortedMahkemeTurleri, mahkemeTuruLabel, setMahkemeTuruLabel,
        mahkemeTuru, setMahkemeTuru, mahBasKararTarihi, setMahBasKararTarihi, mahBitKararTarihi, setMahBitKararTarihi, iddianameBasKararTarihi, setIddianameBasKararTarihi,
        iddianameBitKararTarihi, setIddianameBitKararTarihi, mahkemeTuruRef, geldigiMahkemeRef,iddianameTarihi, setIddianameTarihi,mahkemeFiltreTemizle
    ]);

    return (
        <MahkemeBilgileriContext.Provider value={values}>
            {children}
        </MahkemeBilgileriContext.Provider>
    )

}

export const useMahkemeBilgileri = () => {
    const context = useContext(MahkemeBilgileriContext);

    if (context === undefined) {
        throw new Error("must be call inside KararAramaContextProvider");
    }

    return context;
};