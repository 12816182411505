import React, { createContext, useContext, useMemo, useState } from "react";

const GenelTematikNotlarContext = createContext();

export const GenelTematikNotlarProvider = ({ children }) => {

    const [amaci, setAmaci] = useState('');
    const [basTarih, setBasTarih] = useState('');
    const [bitTarih, setBitTarih] = useState('');
    const [halkaAcik, setHalkaAcik] = useState(false);
    const [konusu, setKonusu] = useState('');
    const [mevzuat, setMevzuat] = useState('');
    const [tematikBilgiNotu, setTematikBilgiNotu] = useState('<div></div>');
    const [amaciDiger, setAmaciDiger] = useState('');

    const values = useMemo(() => ({ halkaAcik, setHalkaAcik, basTarih, setBasTarih, bitTarih, setBitTarih, konusu, setKonusu, tematikBilgiNotu, setTematikBilgiNotu,
        amaciDiger, setAmaciDiger, amaci, setAmaci,mevzuat, setMevzuat
    }), [
        halkaAcik, setHalkaAcik, basTarih, setBasTarih, bitTarih, setBitTarih, konusu, setKonusu, tematikBilgiNotu, setTematikBilgiNotu,
        amaciDiger, setAmaciDiger, amaci, setAmaci,mevzuat, setMevzuat
    ]);

    return (
        <GenelTematikNotlarContext.Provider value={values}>
            {children}
        </GenelTematikNotlarContext.Provider>
    )

};

export const useGenelTematikNotlar = () => {
    const context = useContext(GenelTematikNotlarContext);

    if (context === undefined) {
        throw new Error("must be call inside GenelTematikNotlarContextProvider");
    }

    return context;
}; 