import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { TreeSelect } from 'primereact/treeselect';
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { useKararArama } from "../../contexts/KararAramaContext";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DigerConstant } from "../../constants/DigerConstant";
import { useSorgu } from "../../contexts/SorguContext";

const SorguPopup = (props) => {
    const { klasor, setKlasor, setSorguId, } = useKararArama();
    const { secilenKlasor, setSecilenKlasor, sorguKararAdi, setSorguKararAdi, yeniKararBilgi, setYeniKararBilgi, digerKlasor, setDigerKlasor, digerKlasorRef,
        sorguAdiRef, repoKlasor, sorguFavoriEkleDialogHeader, sorguMenu, setSorguMenu, sorguFavoriKaydet, onHide, } = useSorgu();
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [yeniKlasor, setYeniKlasor] = useState(false);
    const yeniSorguKaydet = () => {
        if (sorguKararAdi === "") {
            toast.error("Kaydetmek istediğiniz " + (!props.sorguFavoriEkleDialogHeader.includes("Favori") ? "sorgu için 'Sorgu Adı'" : "favori için 'Karar Adı'")
                + " zorunludur!", { position: toast.POSITION.TOP_CENTER });
        } else if (selectedNodeKey == null) {
            toast.error("Klasör seçimi zorunludur!", { position: toast.POSITION.TOP_CENTER });
        } else if (klasor === DigerConstant.DIGER && digerKlasor === "") {
            toast.error("Klasör Adı zorunludur!", { position: toast.POSITION.TOP_CENTER });
        } else {
            setSorguId(null);
            if (!props.sorguFavoriEkleDialogHeader.includes("Favori")) {
                sorguFavoriKaydet(false);
            } else {
                sorguFavoriKaydet();
            }
        }
    }

    useEffect(() => {
        setYeniKlasor(false);
        setSelectedNodeKey(null);
    }, [props.displaySorguFavoriEkleDialog]);

    const vazgec = () => {
        onHide();
        setSorguId(null);
        setSorguMenu(false);
    }

    const renderFooterSorguFavori = () => {
        return (
            <div>
                {sorguMenu &&
                    <React.Fragment>
                        <Button label="Güncelle" className="button-color" icon="pi pi-save" onClick={() => sorguFavoriKaydet(true)} />
                        <Button label="Vazgeç" className="p-button-danger" icon="pi pi-trash" onClick={vazgec} />
                    </React.Fragment>
                }{!sorguMenu &&
                    <Button label="Yeni Kaydet" icon="pi pi-save" className="button-color" onClick={yeniSorguKaydet} />
                }
            </div>
        );
    }

    const changedYeniKlasorCheckbox = (value) => {
        setYeniKlasor(value);
        if (value) {
            setKlasor(DigerConstant.DIGER);
        } else { setKlasor(secilenKlasor.value) }
    }

    const handleInput = (e, field) => {
        const delay = 300;

        const refMap = {
            sorguAdi: sorguAdiRef,
            digerKlasor: digerKlasorRef,
        };

        const targetRef = refMap[field];

        if (targetRef) {
            targetRef.current.typingTimeout = setTimeout(() => {
                switch (field) {
                    case "sorguAdi":
                        setSorguKararAdi(e);
                        break;
                    case "digerKlasor":
                        setDigerKlasor(e);
                        break;
                    default:
                        break;
                }
            }, delay);
        }
    };


    return (
        <React.Fragment>
            <Toast ref={toast} position="top-center" />
            <Dialog header={props.sorguFavoriEkleDialogHeader} visible={props.displaySorguFavoriEkleDialog}
                onHide={onHide} className="sorgu-w"
                footer={renderFooterSorguFavori()}>
                <div className="card">
                    <div className="field grid">
                        <label htmlFor="sorguKarar"
                            className="col-12 mb-2 md:col-4 md:mb-0 font-semibold">{sorguFavoriEkleDialogHeader.includes("Sorgu") ? 'Sorgu ' : "Karar "} Adı</label>
                        <div className="col-12 md:col-8">
                            <InputText id="sorguKararAdi" ref={sorguAdiRef}
                                onInput={() => {
                                    if (sorguAdiRef.current !== undefined) {
                                        handleInput(sorguAdiRef.current.value, "sorguAdi");
                                    }
                                }} className="w-full" />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="klasor" className="col-12 mb-2 md:col-4 md:mb-0 font-semibold">Klasör
                            Seçiniz</label>
                        <div className="col-12 md:col-8 text-left">
                            <TreeSelect value={selectedNodeKey} options={repoKlasor} onChange={(e) => {
                                setYeniKlasor(false);
                                if (e.value.replaceAll("-", "").length === e.value.length - 1) {
                                    setSecilenKlasor(repoKlasor.filter(x => x.key === e.value)[0]);
                                    setKlasor(repoKlasor.filter(x => x.key === e.value)[0].value);
                                } else if (e.value.replaceAll("-", "").length === e.value.length - 2) {
                                    setSecilenKlasor(repoKlasor.filter(x => x.key === e.value.substr(0, 3))[0].children.filter(x => x.key === e.value)[0]);
                                    setKlasor(repoKlasor.filter(x => x.key === e.value.substr(0, 3))[0].children.filter(x => x.key === e.value)[0].value);
                                } else {
                                    setSecilenKlasor(null);
                                    setKlasor(repoKlasor.filter(x => x.key === e.value.substr(0, 3))[0].children.filter(y => y.key === e.value.substr(0, 5))[0].children.filter(x => x.key === e.value)[0].value);
                                }

                                setSelectedNodeKey(e.value);
                            }} placeholder="Klasör Seçiniz!" className="w-full"></TreeSelect>
                        </div>
                    </div>
                    {(klasor === DigerConstant.DIGER || yeniKlasor) &&
                        <div className="field grid">
                            <label htmlFor="digerKlasor" className="col-12 mb-2 md:col-4 md:mb-0 font-semibold">Klasör Adı</label>
                            <div className="col-8 text-left">
                                <InputText id="digerKlasor" ref={digerKlasorRef}
                                    onInput={() => {
                                        if (digerKlasorRef.current !== undefined) {
                                            handleInput(digerKlasorRef.current.value, "digerKlasor");
                                        }
                                    }} className="w-full" />
                            </div>
                        </div>}
                    {selectedNodeKey != null && selectedNodeKey.length < 7 && (secilenKlasor != null && secilenKlasor.label !== "Yeni Klasör") &&
                        <div className="field-checkbox">
                            <div className="col-12 md:col-10 text-left pt-2">
                                <Checkbox id="yeniKlasor" inputId="cb1" onChange={e => {
                                    changedYeniKlasorCheckbox(e.checked)
                                }} checked={yeniKlasor} />
                                <label style={{ "fontSize": '1.1em' }} htmlFor="cb1" onClick={e => {
                                    changedYeniKlasorCheckbox(!yeniKlasor)
                                }}
                                    className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">{secilenKlasor.label} klasörüne alt klasör eklemek istiyorum.</label>
                            </div>
                        </div>}
                    {sorguFavoriEkleDialogHeader.includes("Sorgu") &&
                        <div className="field_checkbox">
                            <div className="col-12 md:col-10 text-left pt-2">
                                <Checkbox id="yeniKararBilgi" inputId="cb2" onChange={e => {
                                    setYeniKararBilgi(e.checked);
                                }} checked={yeniKararBilgi} />
                                <label style={{ "fontSize": '1.1em' }} htmlFor="cb2" onClick={e => {
                                    setYeniKararBilgi(!yeniKararBilgi);
                                }}
                                    className="col-12 mb-2 md:col-2 md:mb-0 font-semibold">Kayıtlı sorgu kriterimle eşleşen karar yayımlandığında e-posta ile bilgilendirilmek istiyorum.</label>
                            </div>
                        </div>}
                </div>
            </Dialog>
        </React.Fragment>
    )
};

export default SorguPopup;
