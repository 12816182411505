import { combineReducers } from 'redux';

import authReducer from './authReducer';
import {alertReducer} from "./AlertReducer";

let rootReducer = combineReducers({
  alertReducer,
  auth: authReducer 
});

export default rootReducer;